import Image from "next/image"

interface LoaderProps {
  width?: number
  height?: number
}

const Loader = ({ width = 24, height = 24 }: LoaderProps) => {
  return (
    <Image src="/icons/loader.gif" alt="..." width={width} height={height} />
  )
}

export default Loader
