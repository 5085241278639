import Image from "next/image"

import DynamicLink from "components/interactive/DynamicLink"

interface Props {
  // The href to link to; defaults to the home page.
  // Used in cases where access should be restricted
  // to users with an org, for example
  href?: string
}

const Logo = ({ href = "/" }: Props) => {
  return (
    <DynamicLink href={href} className="fw-500 flex-c dark-invert">
      <Image src="/brand/seam.svg" alt="Seam Logo" width={120} height={28} />
    </DynamicLink>
  )
}

export default Logo
