// This is mostly to save the zustand code; I'm pretty sure Auth0
// will handle session management for us (at least partly)
import { create } from "zustand"
import { persist } from "zustand/middleware"

import { FakeDatabase } from "./../lib/fake/get-seeded-db"

export interface SessionState {
  organization_id?: string
  user_id?: string
  seam_user_session_token?: string
  post_auth_redirect_url?: string
  removeOrganizationId: () => void
  setOrganizationId: (organizationId: string) => void
  removeSeamUserSessionToken: () => void
  setSeamUserSessionToken: (token: string) => void
  setPostAuthRedirectUrl: (url: string) => void
  consumePostAuthRedirectUrl: () => string | undefined
  setUserId: (user_id: string) => void
  clearSession: () => void
  fake_db: FakeDatabase | null
  invite_email?: string
  setInviteEmail: (invite_email: string) => void
  /**
   * Remove any state that should be cleaned up after a successful login
   */
  cleanUpState: () => void
}

const useSessionStore = create<SessionState>()(
  persist(
    (set, get) => ({
      organization_id: undefined,
      user_id: undefined,
      seam_user_session_token: undefined,
      post_auth_redirect_url: undefined,
      removeOrganizationId: () =>
        set((state) => ({ ...state, organization_id: undefined })),
      setOrganizationId: (organizationId) =>
        set((state) => ({ ...state, organization_id: organizationId })),
      removeSeamUserSessionToken: () =>
        set((state) => ({ ...state, seam_user_session_token: undefined })),
      setSeamUserSessionToken: (token) =>
        set((state) => ({ ...state, seam_user_session_token: token })),
      setPostAuthRedirectUrl: (url) =>
        set((state) => ({ ...state, post_auth_redirect_url: url })),
      consumePostAuthRedirectUrl: () => {
        const url = get().post_auth_redirect_url
        set((state) => ({ ...state, post_auth_redirect_url: undefined }))
        return url
      },
      setUserId: (user_id) => set((state) => ({ ...state, user_id })),
      clearSession: () => {
        set((state) => ({
          ...state,
          organization_id: undefined,
          user_id: undefined,
          seam_user_session_token: undefined,
          post_auth_redirect_url: undefined,
        }))
      },
      cleanUpState: () =>
        set((state) => ({
          ...state,
          post_auth_redirect_url: undefined,
          invite_email: undefined,
        })),
      setInviteEmail: (invite_email) =>
        set((state) => ({ ...state, invite_email })),
      fake_db: null,
    }),
    {
      name: "seam-session",
    }
  )
)

export default useSessionStore
