import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntercom } from "react-use-intercom"
import { useRouter } from "next/router"
import { Box, Button, Typography } from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { pick } from "lodash"

import { useSeamMutation } from "hooks/useSeamMutation"
import useSessionStore from "hooks/useSessionStore"

import TextInput from "components/form/TextInput"
import { setShowModal } from "components/modals/OrgSetupModal/OrgSetupModal"

import { useOrganizationInfoModalContext } from "../context"

import { handleString } from "lib/inputs"
import { colorDefaults } from "lib/mui"

const ORG_NAME_MAX_LENGTH = 40

const textSx = {
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: "132%",
  color: colorDefaults.text.textGray1,
}

interface Data {
  name: string
}

const OrgInfoScreen = ({}) => {
  const context = useOrganizationInfoModalContext()
  const intercom = useIntercom()

  const { push } = useRouter()
  const { setOrganizationId } = useSessionStore((s) =>
    pick(s, ["setOrganizationId"])
  )

  const { handleSubmit, control, watch, trigger, formState } = useForm<Data>({
    defaultValues: {
      name: context.state.name,
    },
  })

  const { name } = watch()
  // Re-validate on input. react-hook-form validate on submit by default.
  useEffect(() => {
    trigger()
  }, [name])

  const queryClient = useQueryClient()
  const { isLoading: isCreating, mutateAsync: createOrganization } =
    useSeamMutation(async (seam, data) => {
      const organization = await seam.organizations.create({
        name: data.name,
        is_sandbox:
          process.env.NEXT_PUBLIC_USE_SANDBOX_WORKSPACES === "true" ?? false,
      })

      intercom.trackEvent("Organization Created", {
        organization_id: organization.organization_id,
        name: organization.name,
      })
      intercom.update({
        company: {
          companyId: organization.organization_id,
          name: organization.name,
          createdAt: organization.created_at.toString(),
        },
      })
      setOrganizationId(organization.organization_id)
      queryClient.clear()
      setShowModal(organization.organization_id, true)
      push("/")
    }, {})

  const { isLoading: isUpdating, mutateAsync: updateOrganization } =
    useSeamMutation(async (seam, data) => {
      await seam.organizations.update({
        name: data.name,
      })
      queryClient.invalidateQueries(["organizations"])
    }, {})

  useEffect(() => {
    context.setShowBackButton(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const didChangeName = name !== context.state.name
  const canSaveEditedName = context.state.mode === "edit" ? didChangeName : true

  const canSave =
    !isCreating && !isUpdating && canSaveEditedName && formState.isValid

  const save = async (data: Data) => {
    context.onClose()
    if (context.state.mode === "create") {
      await createOrganization(data)
    } else if (context.state.mode === "edit") {
      await updateOrganization(data)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(save)}
      className="wfull hfull VStack align-c pt-8"
    >
      {isCreating || isUpdating ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            pt: 7,
          }}
        >
          <div className="spinner size-8"></div>
          <Typography
            sx={{
              ...textSx,
              mt: 2,
            }}
          >
            {isCreating ? "Creating" : "Updating"} {name}...
          </Typography>
        </Box>
      ) : (
        <>
          {context.state.mode === "create" && (
            <div className="wfull flex-c text-c mw-96">
              <Typography
                sx={{
                  ...textSx,
                  mb: 2,
                }}
              >
                You will be the organization&apos;s super admin. The
                organization name and your role can be changed later.
              </Typography>
            </div>
          )}

          <div className="wfull relative px-8">
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
                maxLength: ORG_NAME_MAX_LENGTH,
              }}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  value={value}
                  onChange={handleString(onChange)}
                  label="Organization name"
                  placeholder='e.g. "My Company"'
                  fullWidth
                  disabled={isCreating || isUpdating}
                />
              )}
            />

            {name.length > ORG_NAME_MAX_LENGTH && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 32,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    lineHeight: "132%",
                    color: "var(--danger)",
                  }}
                >
                  {ORG_NAME_MAX_LENGTH} character limit
                </Typography>
              </Box>
            )}
          </div>
        </>
      )}

      <div className="buttons">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => context.onClose()}
        >
          Cancel
        </Button>
        <Button variant="contained" disabled={!canSave} type="submit">
          {isCreating || isUpdating ? "Saving..." : "Save"}
        </Button>
      </div>
    </form>
  )
}

export default OrgInfoScreen
