// The default gray trigger button with a label and chevron icon.
// An additional icon on the left side is optional.

import { MouseEventHandler } from "react"
import classNames from "classnames"

import { icons } from "lib/constants"
import { generateMods } from "lib/utils"

interface Props {
  // Optional. Used to turn the chevron icon up or down.
  isOpen?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  icon?: React.ReactNode
  label: string
  className?: string
}

const MenuTrigger = ({ isOpen, onClick, icon, label, className }: Props) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.stopPropagation()

    if (typeof onClick === "function") onClick(ev)
  }

  return (
    <button
      className={classNames("menu-trigger", className)}
      onClick={handleClick}
      type="button"
    >
      <div className="SBStack">
        <div className="HStack">
          {icon && <div className="icon size-4 mr-2">{icon}</div>}
          <p>{label}</p>
        </div>
        <div className="ml-4">
          <div
            className={classNames(
              "icon size-6 chevron",
              generateMods({ isOpen })
            )}
          >
            {icons.memberChevron}
          </div>
        </div>
      </div>
    </button>
  )
}

export default MenuTrigger
