import { useEffect, useState } from "react"
import { Box, BoxProps } from "@mui/material"

import useDebounce, { SEARCH_DEBOUNCE_DELAY_MS } from "hooks/useDebounce"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"

import FormHelperText from "components/form/FormHelperText"
import Input from "components/form/Input"
import InputLabel from "components/form/InputLabel"
import Icon from "components/media/Icon"
import PortalMenu from "components/menus/PortalMenu"

import ExclamationCircleIcon from "assets/images/exclamation-circle.svg"
import MarkerIcon from "assets/images/marker.svg"

interface Props {
  label: string
  defaultValue?: google.maps.places.PlaceResult | null
  onChange?: (location: google.maps.places.PlaceResult | null) => void
  placeholder?: string
  helperText?: string
  containerProps?: BoxProps
}

const LocationInput = ({
  label,
  defaultValue,
  onChange,
  placeholder,
  helperText,
  containerProps,
}: Props) => {
  // Requests are debounced 500ms
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? "",
  })

  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState<string>(() => {
    return defaultValue?.formatted_address ?? ""
  })

  const debouncedTerm = useDebounce(value, SEARCH_DEBOUNCE_DELAY_MS)

  const [location, setLocation] =
    useState<google.maps.places.PlaceResult | null>(defaultValue || null)

  useEffect(() => {
    if (defaultValue) {
      setLocation(defaultValue)
      setValue(defaultValue?.formatted_address ?? "")
    }
  }, [defaultValue])

  useEffect(() => {
    if (debouncedTerm && debouncedTerm.trim().length > 0) {
      getPlacePredictions({
        input: debouncedTerm,
      })
    }
  }, [debouncedTerm])

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(location)
    }
  }, [location])

  return (
    <Box
      sx={{
        position: "relative",
        ...containerProps?.sx,
      }}
      className="location-input-wrap"
      {...containerProps}
    >
      <InputLabel altClassName="mb-0">{label}</InputLabel>
      {helperText && (
        <FormHelperText
          sx={{
            mb: 1,
            mt: 0,
          }}
        >
          {helperText}
        </FormHelperText>
      )}

      <div className="immediate-input-wrap">
        <Input
          value={value}
          onChange={(ev) => {
            setAnchorEl(ev.target)
            setValue(ev.target.value)
            setIsOpen(true)
          }}
          onClick={(ev) => {
            setAnchorEl(ev.target)
            setIsOpen(true)
          }}
          onFocus={(ev) => {
            setAnchorEl(ev.target)
            setIsOpen(true)
          }}
          placeholder={placeholder}
          fullWidth
          nativeFocus
        />

        {isPlacePredictionsLoading && (
          <div className="spinner-wrap">
            <div className="spinner sm"></div>
          </div>
        )}

        <PortalMenu
          anchorEl={anchorEl as Element}
          isOpen={
            isOpen &&
            debouncedTerm.trim().length > 0 &&
            isPlacePredictionsLoading === false
          }
          onClose={() => setIsOpen(false)}
          verticalOffset={40}
          menuProps={{
            className: "location-menu",
          }}
        >
          {placePredictions.map((item) => (
            <button
              key={item.place_id}
              onClick={() => {
                placesService?.getDetails(
                  {
                    placeId: item.place_id,
                  },
                  (place, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                      setLocation(place)
                      const previousValue = debouncedTerm
                      setValue(
                        place?.formatted_address ??
                          place?.adr_address ??
                          previousValue
                      )
                      setIsOpen(false)
                    }
                  }
                )
              }}
            >
              <Icon>
                <MarkerIcon />
              </Icon>
              <p>{item.description}</p>
            </button>
          ))}

          {placePredictions.length === 0 && (
            <div className="wfull HStack px-4">
              <ExclamationCircleIcon />
              <p className="fs--1 text-secondary m-0 ml-2">
                No results found. Please check spelling.
              </p>
            </div>
          )}
        </PortalMenu>
      </div>
    </Box>
  )
}

LocationInput.defaultProps = {
  label: "Building location",
  placeholder: "",
  helperText:
    "Try entering the building's name to find its location. Enter address if no results.",
}

export default LocationInput
