// Later this will be expanded to fetch images automatically
// from Google Maps (probably).

import BackgroundImage from "components/media/BackgroundImage"

interface BuildingImageProps {
  src: string
}

const BuildingImage = ({ src }: BuildingImageProps) => {
  return (
    <BackgroundImage
      src={src}
      style={{
        height: "160px",
        borderRadius: "0.75rem",
        backgroundPosition: "center",
      }}
    />
  )
}

export default BuildingImage
