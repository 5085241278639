import { useEffect, useState } from "react"

import { useLoginPortal } from "hooks/api/useLoginPortal"
import { useSeamQuery } from "hooks/useSeamQuery"

import { LinkedAccount } from "types"

export const useLinkedAccount = (params: {
  login_portal_id?: string
  enabled?: boolean
}) => {
  const { login_portal_id, enabled = true } = params
  const [value, setValue] = useState<LinkedAccount | null>(null)

  const { loginPortal } = useLoginPortal(login_portal_id, {
    refetchInterval: 1_000,
    refetchIntervalInBackground: true,
    enabled: Boolean(login_portal_id) && !value,
  })

  const linked_account_id = loginPortal ? loginPortal.linked_account_id : null

  const { data: linkedAccount } = useSeamQuery(
    ["linked_accounts", "get", { linked_account_id }],
    (seam) =>
      seam.linked_accounts.get({
        linked_account_id: linked_account_id as string,
      }),
    {
      enabled: enabled && Boolean(linked_account_id),
      refetchInterval: value ? undefined : 1_000,
      refetchIntervalInBackground: true,
    }
  )

  useEffect(() => {
    if (value) {
      return
    }

    if (!linkedAccount) {
      return
    }

    // Only want a linked account that has completed sync
    if (linkedAccount.did_complete_first_sync === false) {
      return
    }

    setValue(linkedAccount)
  }, [linkedAccount])

  return value
}
