import { PropsWithChildren } from "react"
import { Typography } from "@mui/material"

import { colorDefaults } from "lib/mui"

interface Props {}

const ShareBlockLabel = ({ children }: PropsWithChildren<Props>) => {
  return (
    <Typography
      sx={{
        fontSize: "0.875rem",
        color: colorDefaults.text.textDefaultColor,
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </Typography>
  )
}

export default ShareBlockLabel
