import { useSeamQueryWithoutOrg } from "hooks/useSeamQueryWithoutOrg"
import useSessionStore from "hooks/useSessionStore"

import { getInitials } from "lib/utils"

type BaseUser =
  | {
      first_name: string | null
      last_name: string | null
    }
  | undefined

/**
 * Returns original user object with full_name and initials
 */
const formatUser = <T extends BaseUser>(user: T) => ({
  ...user,
  full_name: user ? `${user.first_name} ${user.last_name}` : "...",
  initials: user ? getInitials(`${user.first_name} ${user.last_name}`) : "",
})

export const useUser = (userId?: string) => {
  const { data: user, ...query } = useSeamQueryWithoutOrg(
    ["users", { user_id: userId }, "get"],
    (seam) => seam.users.get({ user_id: userId! }),
    { enabled: Boolean(userId) }
  )

  return {
    ...query,
    user: formatUser(user),
  }
}

/**
 * Get current logged in user
 */
export const useOwnUser = () => {
  const seam_user_session_token = useSessionStore(
    (s) => s.seam_user_session_token
  )

  const { data, ...query } = useSeamQueryWithoutOrg(
    ["users", "get_me"],
    (seam) => seam.axios.get("/internal/users/get_me"),
    {
      enabled: Boolean(seam_user_session_token),
    }
  )

  const user = data?.data?.user

  return {
    ...query,
    user: formatUser(user),
  }
}
