import { StaticImageData } from "next/image"

import { UNKNOWN_LOCK_IMAGE } from "./misc"

export type SaltoLockModel =
  | "cylinder"
  | "escutcheon"
  | "escutcheon_pin"
  | "locker_lock"
  | "wall_reader"
  | "wall_reader_pin"
  | "unknown"

import WallReaderImg from "../../public/devices/salto/salto_design-xs-european-wall-reader_white_front.png"
import CylinderImg from "../../public/devices/salto/salto_neo-cylinder_black_front.png"
import LockerLockImg from "../../public/devices/salto/salto_xs4-locker-lock_white_front.png"
import EscutcheonImg from "../../public/devices/salto/salto_xs4-original-euro_satin-stainless-steel_front.png"
import EscutcheonPinImg from "../../public/devices/salto/salto_xs4-original-keypad-ansi_satin-stainless-steel_front.png"

export const SALTO_DEVICE_MODEL_IMAGES: {
  [key in SaltoLockModel]: StaticImageData
} = {
  cylinder: CylinderImg,
  escutcheon: EscutcheonImg,
  escutcheon_pin: EscutcheonPinImg,
  locker_lock: LockerLockImg,
  wall_reader: WallReaderImg,
  // todo: fix image
  wall_reader_pin: WallReaderImg,
  unknown: UNKNOWN_LOCK_IMAGE,
}
