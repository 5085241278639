import { Box } from "@mui/system"

import type { Children } from "types"

interface Props extends Children {}

const Sidebar = ({ children }: Props) => {
  return (
    <Box sx={{}} className="sidebar">
      <Box
        sx={{
          width: "100%",
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default Sidebar
