import { Checkbox } from "@mui/material"
import classNames from "classnames"

import Image from "components/media/Image"

import { fetchBrandImageDetails } from "lib/brands"
import { generateMods } from "lib/utils"
import { getDeviceProductModel } from "lib/utils/devices"

import { Device } from "types"

interface Props {
  device: Device
  index: number
  onToggle: () => void
  isSelected: boolean
}

const DeviceRow = ({
  device,
  index,
  onToggle: toggleSelected,
  isSelected,
}: Props) => {
  const model = getDeviceProductModel(device)
  const img = fetchBrandImageDetails(device.device_type, model)

  return (
    <div
      key={`${device.name}${index}`}
      className={classNames("device-row", generateMods({ isSelected }))}
      onClick={toggleSelected}
    >
      <div className="HStack wfull hfull">
        <div className="mr-2">
          <Checkbox
            onChange={toggleSelected}
            checked={isSelected}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>

        <Image src={img.src} alt={img.alt} width={48} height={48} />

        <div className="VStack ml-2">
          <p className="fw-600 lh-1 m-0">{device.name}</p>
          <p className="fs--1 opacity-06 lh-1 m-0">{model}</p>
        </div>
      </div>
    </div>
  )
}

export default DeviceRow
