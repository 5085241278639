import React, { useEffect, useState } from "react"
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock"

const useScrollLock = (ref: React.RefObject<HTMLElement>) => {
  const [scrollLock, setScrollLock] = useState(false)

  useEffect(() => {
    if (!ref.current) return

    if (scrollLock) {
      disableBodyScroll(ref.current, {
        reserveScrollBarGap: true,
      })
    } else {
      enableBodyScroll(ref.current)
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [scrollLock])

  const lock = () => {
    setScrollLock(true)
  }

  const unlock = () => {
    setScrollLock(false)
  }

  const toggle = () => {
    setScrollLock((isLocked) => !isLocked)
  }

  return {
    isLocked: scrollLock,
    lock,
    unlock,
    toggle,
  }
}

export default useScrollLock
