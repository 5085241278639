import { useRouter } from "next/router"
import classNames from "classnames"

import useMyOrganization from "hooks/auth/useMyOrganization"
import { useSeamQueryWithoutOrg } from "hooks/useSeamQueryWithoutOrg"
import useSessionStore from "hooks/useSessionStore"
import useToggle from "hooks/useToggle"

import { usePanels } from "providers/PanelsProvider"

import MenuSeparator from "components/menus/MenuSeparator"
import MenuLink from "components/shell/Navigation/menus/MenuLink"

import BuildingIcon from "assets/images/building.svg"
import CheckBlackIcon from "assets/images/check-black.svg"
import ChevronLeft from "assets/images/chevron-left.svg"
import ChevronRight from "assets/images/chevron-right.svg"
import LinkedCloudIcon from "assets/images/linked-cloud.svg"
import MultipleUserIcon from "assets/images/multiple-user.svg"
import OrganizationIcon from "assets/images/organization.svg"
import PlusIcon from "assets/images/plus.svg"
import StarsGrayIcon from "assets/images/stars-gray.svg"

import { Organization } from "types"

interface ContentProps {
  onClose: () => void
}

const Content = ({ onClose }: ContentProps) => {
  const organization = useMyOrganization()
  const panels = usePanels()
  const [showingOrgSelector, toggleOrgSelector] = useToggle()

  const { data: organizations } = useSeamQueryWithoutOrg(
    ["organizations", "list"],
    (seam) => seam.organizations.list({})
  )
  const router = useRouter()

  const setOrganizationId = useSessionStore(
    ({ setOrganizationId }) => setOrganizationId
  )

  if (!organization || !organizations) {
    return null
  }

  const hasMultipleOrganizations = organizations.length > 1
  const showOrgSelector = () => {
    if (hasMultipleOrganizations === false) {
      return
    }

    toggleOrgSelector()
  }

  const isSelected = (target: Organization) =>
    target.organization_id === organization.organization_id

  const selectOrganization = (target: Organization) => {
    // if we've selected the same organization...no-op
    if (target.organization_id === organization.organization_id) {
      return
    }

    setOrganizationId(target.organization_id)

    // Re-load window after setting the organization
    router.reload()
  }

  if (showingOrgSelector) {
    return (
      <>
        <div onClick={toggleOrgSelector} className="back-item menu-action">
          <ChevronLeft />
          <span>Back</span>
        </div>
        {organizations.map((organization) => (
          <div
            className="org-item menu-action"
            key={organization.organization_id}
            onClick={() => selectOrganization(organization)}
          >
            <span>{organization.name}</span>
            {isSelected(organization) && <CheckBlackIcon />}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <div
        className={classNames("org-info", {
          clickable: hasMultipleOrganizations,
        })}
        onClick={showOrgSelector}
      >
        <div className="left">
          <span className="label">Organization</span>
          <span className="name">{organization.name}</span>
        </div>
        {ChevronRight && <ChevronRight />}
      </div>
      <MenuSeparator />
      <MenuLink href="/" onClick={onClose}>
        <BuildingIcon />
        Buildings
      </MenuLink>
      <MenuLink href="/linked-accounts" onClick={onClose}>
        <LinkedCloudIcon />
        Linked Accounts
      </MenuLink>
      <MenuLink href="/members" onClick={onClose}>
        <MultipleUserIcon />
        Members
      </MenuLink>
      <MenuSeparator />
      <MenuLink
        onClick={() => {
          onClose()
          panels.open("orgSetupModal")
        }}
      >
        <StarsGrayIcon />
        Set up your organization
      </MenuLink>
      <MenuLink
        href={`/organizations/${organization.organization_id}`}
        onClick={onClose}
      >
        <OrganizationIcon />
        Organization info
      </MenuLink>
      <MenuSeparator />
      <MenuLink
        onClick={() => {
          panels.open("createOrganizationModal")
          onClose()
        }}
      >
        <PlusIcon />
        Add new organization
      </MenuLink>
    </>
  )
}

export default Content
