import { useEffect } from "react"
import { IntercomProvider } from "react-use-intercom"
import type { NextComponentType, NextPageContext } from "next"
import type { AppProps } from "next/app"
import { useRouter } from "next/router"
import * as FullStory from "@fullstory/browser"
import { HighlightInit } from "@highlight-run/next/highlight-init"
import { ThemeProvider } from "@mui/material"
import { LicenseInfo } from "@mui/x-license-pro"
import { LoadScriptNext } from "@react-google-maps/api"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import { AuthProvider } from "providers/AuthProvider"
import { OrgSetupProvider } from "providers/OrgSetupProvider"
import { PanelsProvider } from "providers/PanelsProvider"
import {
  FakeSeamClientProvider,
  SeamClientProvider as LiveSeamClientProvider,
} from "providers/SeamClientProvider"

import Private from "templates/Private"

import HandleOrgInvite from "components/auth/HandleOrgInvite"
import { SnackbarProvider } from "components/presentational/Snackbar"
import RegisterUserWithIntercom from "components/RegisterUserWithIntercom"

import "../styles/seam.css" // Required for custom components
import "../styles/main.scss"

import { theme } from "lib/mui"

import type { Children } from "types"

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE_KEY ?? "")

const queryClient = new QueryClient()

const googleMapLibraries = ["places"]

interface Props extends AppProps {
  Component: NextComponentType<NextPageContext, any, any> & {
    // Components can declare a template for themselves. The template property
    // is static which means that it is not calculated during the rendering
    // of the component itself. This is one way to preserve state between
    // route changes.
    //
    // The default component template is the RootTemplate component.
    template?: ({ children }: Children) => JSX.Element
  }
}

const shouldUseFakeApi = () => {
  if (!process.env.NEXT_PUBLIC_USE_FAKE_API) return true
  if (process.env.NEXT_PUBLIC_USE_FAKE_API === "false") return false
  if (process.env.NEXT_PUBLIC_USE_FAKE_API === "true") return true
  if (process.env.NODE_ENV === "test") return true
  return typeof window === "undefined"
    ? false
    : window.location.hostname.includes("demo")
}

const Content = ({ Component, pageProps }: Props) => {
  const router = useRouter()

  const Template = Component.template || Private

  const auth0_routes = ["/auth/callback", "/login", "/logout"]

  if (auth0_routes.includes(router.pathname)) {
    return (
      <Template>
        <Component {...pageProps} />
      </Template>
    )
  }

  return (
    <>
      <RegisterUserWithIntercom />
      <OrgSetupProvider>
        <PanelsProvider>
          {!router.pathname.includes("/access") && (
            <div className="mobile-warning-banner hide md:show bg-amber-100 flex-c text-c p-4">
              <p className="m-0">
                Seam Space isn&apos;t mobile friendly just yet. For the best
                experience, please use a desktop browser.
              </p>
            </div>
          )}
          <LoadScriptNext
            loadingElement={<div></div>}
            googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ""}
            // Ignoring this because it's wanting a union[], but we're
            // declaring it manually, so it's fine.
            // @ts-ignore
            libraries={googleMapLibraries}
          >
            <Template>
              <Component {...pageProps} />
            </Template>
          </LoadScriptNext>
          <HandleOrgInvite />
        </PanelsProvider>
      </OrgSetupProvider>
    </>
  )
}

const App = ({ Component, pageProps, ...props }: Props) => {
  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) return
    FullStory.init({
      orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID!,
      devMode: process.env.NODE_ENV !== "production",
    })
  }, [])

  const SeamClientProvider = shouldUseFakeApi()
    ? FakeSeamClientProvider
    : LiveSeamClientProvider

  return (
    <>
      <HighlightInit
        projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
        tracingOrigins
        networkRecording={{
          enabled: process.env.NODE_ENV == "production",
          recordHeadersAndBody: process.env.NODE_ENV == "production",
          urlBlocklist: ["http://localhost/*"],
        }}
        integrations={{
          intercom: {
            disabled: process.env.NODE_ENV !== "production",
          },
        }}
      />
      <QueryClientProvider client={queryClient}>
        <SeamClientProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <AuthProvider>
                <IntercomProvider
                  appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID!}
                >
                  <Content
                    Component={Component}
                    pageProps={pageProps}
                    {...props}
                  />
                </IntercomProvider>
              </AuthProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </SeamClientProvider>
      </QueryClientProvider>
    </>
  )
}

export default App
