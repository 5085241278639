import DynamicLink from "components/interactive/DynamicLink"

import { Children } from "types"

interface Props extends Children {
  href?: string
  onClick?: () => void
}

const MenuLink = ({ href = "", onClick, children }: Props) => {
  if (!href) {
    return (
      <span className="menu-action" onClick={onClick}>
        {children}
      </span>
    )
  }
  return (
    <DynamicLink href={href} className="menu-action" onClick={onClick}>
      {children}
    </DynamicLink>
  )
}

export default MenuLink
