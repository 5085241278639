import { Context } from "components/modals/StackModal/types"

export const contextDefaults: Context = {
  currentScreen: "",
  direction: "forwards",
  navigate: () => {},
  next: () => {},
  previous: () => {},
  reset: () => {},
  onClose: () => {},
  headerLabel: "",
  setHeaderLabel: () => {},
  onBackButtonClickGoTo: "",
  setOnBackButtonClickGoTo: () => {},
  showBackButton: false,
  setShowBackButton: () => {},
}
