import { DEVICE_TYPES, LinkedAccountType } from "lib/constants"

import { Device, LinkedAccount } from "types"

type AugustProperties = {
  august_metadata: {
    _skuNumber: string
  }
}

type SaltoProperties = {
  salto_metadata: {
    lock_type: string
  }
}

type SmartThingsProperties = {
  manufacturer: string
}

/**
 * Fetches the device product model from a device object
 */
export const getDeviceProductModel = (device: Device | null) => {
  switch (device?.device_type) {
    case DEVICE_TYPES.AUGUST_LOCK:
      return (device?.properties as AugustProperties)?.august_metadata
        ?._skuNumber
    case DEVICE_TYPES.SALTO_LOCK:
      return (device?.properties as SaltoProperties)?.salto_metadata?.lock_type
    case DEVICE_TYPES.SMARTTHINGS_LOCK:
      return (device?.properties as SmartThingsProperties)?.manufacturer
    default:
      return undefined
  }
}

export const getAccountUserIdentifier = (account: LinkedAccount | null) => {
  switch (account?.account_type) {
    case LinkedAccountType.Akuvox:
      return account?.user_identifier?.api_url
    case LinkedAccountType.Seam:
      return "Seam"
    default:
      return account?.user_identifier?.email || account?.user_identifier?.phone
  }
}

export const getDeviceLockedState = (
  device: Device
): "locked" | "unlocked" | "unknown" => {
  const isSalto = device.device_type === DEVICE_TYPES.SALTO_LOCK

  if (isSalto) {
    // Ignoring this as the depth of the properties object is unknown
    // @ts-ignore
    const saltoLockedState = device.properties?.salto_metadata?.locked_state

    if (saltoLockedState) {
      return saltoLockedState === "locked" ||
        saltoLockedState === "privacy_mode"
        ? "locked"
        : "unlocked"
    } else {
      return "unknown"
    }
  }

  return device.properties.locked ? "locked" : "unlocked"
}

export const getSaltoLockedStateLabel = (
  state: "locked" | "unlocked" | "privacy_mode" | "office_mode" | "unknown"
) => {
  switch (state) {
    case "locked":
      return "Locked"
    case "unlocked":
      return "Unlocked"
    case "privacy_mode":
      return "Privacy Mode"
    case "office_mode":
      return "Office Mode"
    default:
      return "Unknown"
  }
}

export const getDeviceType = (device: Device) => {
  return device?.device_type ?? device?.properties?.device_type ?? "unknown"
}

export const getDevicePropertyWithFallback = <T>(
  device: Device,
  property: keyof Device["properties"],
  fallback: T
) => {
  return (device?.properties?.[property] as T) ?? fallback
}
