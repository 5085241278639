import {
  BsBuildingFillLock,
  BsEyeSlashFill,
  BsQuestionCircleFill,
} from "react-icons/bs"
import { SvgIcon } from "@mui/material"

import Tile from "components/cards/Tile"

import { getSaltoLockedStateLabel } from "lib/utils/devices"
import PadlockLocked from "assets/images/padlock-locked.svg"
import PadlockUnlocked from "assets/images/padlock-unlocked.svg"

import { SaltoLockedState } from "types/devices"

export interface Props {
  locked: boolean
  salto_locked_state?: SaltoLockedState
}

const iconStyle = {
  width: "36px",
  height: "36px",
}

export const LockedTile = ({ locked, salto_locked_state }: Props) => {
  if (locked === undefined && !salto_locked_state) {
    return <></>
  }

  const isLocked =
    locked ||
    salto_locked_state === "locked" ||
    salto_locked_state === "privacy_mode"

  if (
    salto_locked_state &&
    salto_locked_state !== "locked" &&
    salto_locked_state !== "unlocked"
  ) {
    const getIcon = () => {
      switch (salto_locked_state) {
        case "privacy_mode":
          return <BsEyeSlashFill size={30} />
        case "office_mode":
          return <BsBuildingFillLock size={28} />
        case "unknown":
          return <BsQuestionCircleFill size={28} />
        default:
          return PadlockLocked
      }
    }

    return (
      <Tile
        value={getIcon()}
        label={getSaltoLockedStateLabel(salto_locked_state)}
        sx={{
          height: 86,
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      />
    )
  }

  if (isLocked) {
    return (
      <Tile
        value={<SvgIcon component={PadlockLocked} sx={iconStyle} />}
        label="Locked"
      />
    )
  }

  return (
    <Tile
      value={<SvgIcon component={PadlockUnlocked} sx={iconStyle} />}
      label="Unlocked"
    />
  )
}

export default LockedTile
