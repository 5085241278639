/**
 * Returns str in lower case and stripped of whitespace
 * @param {string}
 */
export const removeWhitespaceAndLowerCase = (str: string) => {
  return str.toLowerCase().replace(/\s/g, "")
}

/**
 * Converts a string to kebab-case.
 * @example kebabize("aCamelCaseString") => "a-camel-case-string"
 * @note Not suitable for strings with spaces; only camelCase
 */
export const kebabize = (str: string) => {
  return str
    .split("")
    .map((letter, index) => {
      if (letter === "-") {
        return "-"
      }

      return letter.toUpperCase() === letter
        ? `${index !== 0 ? "-" : ""}${letter.toLowerCase()}`
        : letter
    })
    .join("")
}

/**
 * Converts a string to a url-safe kebab.
 * @example urlify("adrift in the void") => "adrift-in-the-void"
 * @note Not suitable for camelCase strings
 */
export const urlify = (str: string) => {
  return str.replaceAll(" ", "-").toLowerCase()
}

/**
 * Capitalized a word.
 * @example capitalize("word") => "Word"
 */
export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

/**
 * Generates a two-character string from a person's name.
 * @example getInitials("John Doe") => "JD"
 * @example getInitials("John") => "J"
 */
export const getInitials = (name: string) => {
  const [firstName, lastName] = name.split(" ")
  return `${firstName.charAt(0)}${
    lastName ? lastName.charAt(0) : ""
  }`.toUpperCase()
}

/**
 * Converts snake_case to Title Case
 * @example convertSnakeToTitleCase('my_data') => "My Data"
 * @example convertSnakeToTitleCase('__my_data') => "My Data"
 */
export const convertSnakeToTitleCase = (s: string) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  )
