import { useEffect } from "react"
import { useIntercom } from "react-use-intercom"

import { useOwnUser } from "hooks/api/useUser"
import useMyOrganization from "hooks/auth/useMyOrganization"

export const RegisterUserWithIntercom = () => {
  const { user } = useOwnUser()
  const organization = useMyOrganization()
  const intercom = useIntercom()

  useEffect(() => {
    if (!user.email) return

    const userInfo = {
      email: user.email,
      name: user.full_name,
      userId: user.user_id,
      customAttributes: {
        "Space User": true,
      },
    }

    if (organization) {
      intercom.boot({
        ...userInfo,
        company: {
          companyId: organization.organization_id,
          name: organization.name,
          createdAt: organization.created_at.toString(),
        },
      })
      return
    }

    intercom.boot(userInfo)
  }, [user.email, organization])

  return null
}

export default RegisterUserWithIntercom
