import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntercom } from "react-use-intercom"
import { useRouter } from "next/router"
import Button from "@mui/material/Button"
import { useQueryClient } from "@tanstack/react-query"
import emailValidator from "email-validator"
import pluralize from "pluralize"

import { useOwnUser } from "hooks/api/useUser"
import { useSeamMutation } from "hooks/useSeamMutation"
import { useSeamQuery } from "hooks/useSeamQuery"

import ChipInput from "components/form/ChipInput"
import InternalLink from "components/interactive/InternalLink"
import RoleInviteSelect from "components/menus/RoleInviteSelect/RoleInviteSelect"
import { RoleID } from "components/menus/RoleMenu/RoleMenu"
import Modal, { Props as ModalProps } from "components/modals/Modal"
import Description from "components/modals/Modal/Description"
import Popover from "components/presentational/Popover"
import { useShowSnackbar } from "components/presentational/Snackbar"

import { roleLabels } from "lib/constants/roles"

type Props = Omit<ModalProps, "headerLabel" | "position" | "className">

const InviteMemberModal = (props: Props) => {
  return (
    <Modal
      headerLabel="Invite members"
      className="invite-member-modal"
      {...props}
    >
      <Content {...props} />
    </Modal>
  )
}

const Content = ({ isOpen, onClose }: Props) => {
  const router = useRouter()
  const intercom = useIntercom()
  const showSnackbar = useShowSnackbar()

  const [sent, setSent] = useState(false)
  const [role, setRole] = useState<RoleID>("org:member")
  const { user } = useOwnUser()

  const { control, handleSubmit, reset, watch } = useForm<{ emails: string[] }>(
    {}
  )

  const { data: members = [] } = useSeamQuery(["users", "list"], (seam) =>
    seam.users.list({})
  )

  const { data: pendingMembers = [] } = useSeamQuery(
    ["organizations", "pending_users.list"],
    (seam) => seam.organizations.pending_users.list({})
  )

  const qc = useQueryClient()
  const emails = watch("emails", [])
  const hasEmails = emails.length > 0
  const hasBadEmail =
    emails.filter((e) => !emailValidator.validate(e)).length > 0
  const hasOwnEmail = emails.filter((e) => e === user.email).length > 0
  const hasExistingMemberEmail =
    emails.filter((e) => members.find((m) => m.email === e)).length > 0
  const hasExistingPendingMemberEmail =
    emails.filter((e) => pendingMembers.find((m) => m.email === e)).length > 0

  const canSubmit =
    hasEmails &&
    !hasBadEmail &&
    !hasOwnEmail &&
    !hasExistingMemberEmail &&
    !hasExistingPendingMemberEmail

  const { mutateAsync: sendInvitation } = useSeamMutation(
    (seam, ev: { email: string }) => {
      intercom.trackEvent("Invited User to Organization", {
        email: ev.email,
        role,
      })

      return seam.organizations.invite_user({
        email: ev.email,
        role,
      })
    }
  )

  // Clear form on open/close
  useEffect(() => {
    reset()
  }, [isOpen, reset])

  const getSnackbarMessage = () => {
    const numInvites = emails.length
    const roleLabel = roleLabels[role].toLowerCase()

    return `${numInvites} ${roleLabel} email ${pluralize(
      "invitation",
      numInvites
    )} ${numInvites === 1 ? "was" : "were"} sent.`
  }

  return (
    <>
      <Description>
        Enter email addresses to invite people to your organization. Recipients
        will receive an email with instructions.
      </Description>
      <form
        onSubmit={handleSubmit(async () => {
          for (const email of emails) {
            await sendInvitation({ email })
          }
          qc.invalidateQueries(["organizations", "pending_users.list"])
          onClose()
          setSent(true)

          showSnackbar({
            variant: "success",
            message: getSnackbarMessage(),
            action: "Members page",
            onActionClick: () => {
              setSent(false)
              router.push("/members")
            },
          })
        })}
      >
        <Controller
          name="emails"
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <ChipInput
              className="mb-6"
              placeholder="Enter email addresses"
              label={(itemCount) =>
                itemCount > 1 ? `Recipients (${itemCount})` : "Recipients"
              }
              onBlur={onBlur}
              helperText="Tip: Try pasting a list of email addresses to add multiple members. "
              value={value}
              onChange={onChange}
              isValid={(e) =>
                emailValidator.validate(e) &&
                e !== user.email &&
                !members.find((m) => m.email === e) &&
                !pendingMembers.find((p) => p.email === e)
              }
            />
          )}
        />
        <div className="d-flex align-center mb-4">
          <span className="role-invite-label mr-2">
            Initial role for recipients
          </span>
          <RoleInviteSelect value={role} onChange={setRole} />
        </div>

        {hasBadEmail && (
          <div className="form-error">
            Please fix or remove bad email addresses before sending.
          </div>
        )}
        {hasOwnEmail && (
          <div className="form-error">
            You are already a member of this organization.
          </div>
        )}
        {hasExistingMemberEmail && (
          <div className="form-error">
            One or more of these email addresses is already a member of this
            organization.
          </div>
        )}
        {hasExistingPendingMemberEmail && (
          <div className="form-error">
            One or more of these email addresses has already been invited to
            this organization. If needed, you can resend the invitation from the{" "}
            <InternalLink onClick={onClose} href="/members">
              members page.
            </InternalLink>
          </div>
        )}

        <div className="buttons">
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Popover
            when={!hasEmails}
            anchor={
              <div>
                <Button variant="contained" type="submit" disabled={!canSubmit}>
                  Send
                </Button>
              </div>
            }
            content={<span>Please enter at least one email address</span>}
            openOnHover
          />
        </div>
      </form>
    </>
  )
}

export default InviteMemberModal
