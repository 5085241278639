// A keyboard event handler based on the [outdated] react-keyboard-event-handler package.
// https://github.com/linsight/react-keyboard-event-handler

import { KeyboardEvent, KeyboardEventHandler, useEffect } from "react"

interface Props {
  keys: string[]
  onKeyPress: (key: string, event: KeyboardEvent) => void
  handleFocusableElements?: boolean
  disabled?: boolean
  eventType?: "keydown" | "keyup" | "keypress"
}

const Keyboard = ({
  keys,
  onKeyPress,
  handleFocusableElements,
  disabled,
  eventType,
}: Props) => {
  useEffect(() => {
    const handleKeyboardEvent: KeyboardEventHandler = (event) => {
      if (disabled) return false
      if (eventType && eventType !== event.type) return false

      const isEligible =
        event.target === document.body || handleFocusableElements

      if (!isEligible) {
        return false
      }

      const matchedKey = keys.includes(event.key) ? event.key : null

      if (matchedKey || keys.includes("all")) {
        onKeyPress(matchedKey || event.key, event)
        return true
      }

      return false
    }

    // The callbacks here are not properly being narrowed into the
    // KeyboardEventHandler type. I have manually verified that the
    // types are correct, but TypeScript is not recognizing them as
    // such.

    // @ts-ignore
    document.addEventListener("keydown", handleKeyboardEvent, false)
    // @ts-ignore
    document.addEventListener("keyup", handleKeyboardEvent, false)
    // @ts-ignore
    document.addEventListener("keypress", handleKeyboardEvent, false)

    return () => {
      // @ts-ignore
      document.removeEventListener("keydown", handleKeyboardEvent, false)
      // @ts-ignore
      document.removeEventListener("keyup", handleKeyboardEvent, false)
      // @ts-ignore
      document.removeEventListener("keypress", handleKeyboardEvent, false)
    }
  }, [keys, onKeyPress, handleFocusableElements, disabled, eventType])

  return <></>
}

Keyboard.defaultProps = {
  handleFocusableElements: false,
  disabled: false,
  eventType: "keydown",
}

export default Keyboard
