import { UseQueryOptions } from "@tanstack/react-query"

import { useSeamQuery } from "hooks/useSeamQuery"

export const useLoginPortal = (
  login_portal_id?: string,
  options?: UseQueryOptions
) => {
  const { data: loginPortal, ...query } = useSeamQuery(
    ["login_portal", "get", { login_portal_id }],
    (seam) => seam.login_portals.get({ login_portal_id: login_portal_id! }),
    {
      enabled: Boolean(login_portal_id),
      ...options,
    }
  )

  return {
    loginPortal,
    ...query,
  }
}
