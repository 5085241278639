import Box, { BoxProps } from "@mui/system/Box"

const Screen = ({ children, ...props }: BoxProps) => {
  return (
    <Box className="screen" {...props}>
      {children}
    </Box>
  )
}

export default Screen
