import MuiFormHelperText, {
  FormHelperTextProps,
} from "@mui/material/FormHelperText"
import { styled } from "@mui/material/styles"

export interface Props extends FormHelperTextProps {}

const StyledFormHelperText = styled(MuiFormHelperText)(
  () => `
color: rgba(157, 161, 169, 1);
margin-top: 6px;
font-size: 0.75rem;
`
)

const FormHelperText = (props: Props) => <StyledFormHelperText {...props} />

export default FormHelperText
