import { icons } from "lib/constants/icons"

import { OrgRole } from "types"

export interface Role {
  id: OrgRole
  icon: React.ReactNode
  label: string
  caption: string
}

export type RoleID = "member" | "admin" | "super-admin"

export const roles: Role[] = [
  {
    id: "org:member",
    icon: icons.user,
    label: "Member (default)",
    caption: "Operator or manager access per building",
  },
  {
    id: "org:admin",
    icon: icons.userAdmin,
    label: "Admin",
    caption: "Admin access to all buildings",
  },
  {
    id: "org:superadmin",
    icon: icons.userAdmin,
    label: "Super admin",
    caption: "Admin access to all buildings + billing",
  },
]

export const roleLabels: Record<OrgRole, string> = {
  "org:member": "Member",
  "org:admin": "Admin",
  "org:superadmin": "Super admin",
}

export const rolesOrderedByAccess: OrgRole[] = [
  "org:superadmin",
  "org:admin",
  "org:member",
]
