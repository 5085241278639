import { Box } from "@mui/material"

import MemberProfile from "components/pages/buildings/MemberProfile"

import { colorDefaults } from "lib/mui"

import { User } from "types"

interface Props {
  members: User[]
}

const MemberList = ({ members }: Props) => {
  const shouldScroll = members.length > 5

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        overflowY: shouldScroll ? "scroll" : "hidden",
        maxHeight: shouldScroll ? "20rem" : "auto",
        border: shouldScroll
          ? `1px solid ${colorDefaults.itemClickBg}`
          : "none",
        padding: shouldScroll ? "0.5rem" : "0",
        borderRadius: shouldScroll ? "0.25rem" : "0",
      }}
    >
      {members.map((member) => (
        <MemberProfile key={member.user_id} member={member} />
      ))}
    </Box>
  )
}

export default MemberList
