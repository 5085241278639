import { useEffect } from "react"

import { useSeamQuery } from "hooks/useSeamQuery"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import Screen from "components/modals/StackModal/fragments/Screen"
import Sidebar from "components/shell/Sidebar"

const DeviceInfoScreen = () => {
  const context = useAccessPassModalContext()

  const { data: device, isLoading: deviceLoading } = useSeamQuery(
    ["devices", "get", { device_id: context.state.device?.device_id }],
    (seam) => seam.devices.get({ device_id: context.state.device?.device_id }),
    { enabled: !!context.state.device?.device_id }
  )

  const linked_account_id = (device && device.linked_account_id) || ""

  const { data: linkedAccount, isLoading: accountLoading } = useSeamQuery(
    ["linked_accounts", "get", { linked_account_id }],
    (seam) => seam.linked_accounts.get({ linked_account_id }),
    { enabled: linked_account_id.length > 0 }
  )

  useEffect(() => {
    context.setHeaderLabel("Device info")
    context.setOnBackButtonClickGoTo("details")
  }, [])

  if (!device) {
    return <></>
  }

  return (
    <Screen>
      <div className="wfull VStack">
        <Sidebar.Device
          device={device}
          linkedAccount={linkedAccount}
          isLoading={deviceLoading || accountLoading}
        />
      </div>
    </Screen>
  )
}

export default DeviceInfoScreen
