import { useState } from "react"
import { Checkbox } from "@mui/material"
import classNames from "classnames"
import { AnimatePresence } from "framer-motion"
import { CommonDevice, isLockDevice } from "seamapi"

import Image from "components/media/Image"
import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import Animated from "components/motion/Animated"
import { useShowSnackbar } from "components/presentational/Snackbar"

import { fetchBrandImageDetails } from "lib/brands"
import { icons } from "lib/constants/icons"
import { generateMods } from "lib/utils"
import { getDeviceProductModel } from "lib/utils/devices"

import { Device } from "types"

const LOCK_METHODS = ["Web unlock", "Access code"] as const

interface Props {
  device: Device
}

const Row = ({ device }: Props) => {
  const availableMethods = LOCK_METHODS.filter((method) => {
    const lock = device as unknown as CommonDevice
    if (!isLockDevice(lock)) {
      return false
    }

    if (!lock.capabilities_supported) {
      return false
    }

    switch (method) {
      case "Web unlock": {
        return lock.capabilities_supported.includes("lock")
      }
      case "Access code": {
        return lock.capabilities_supported.includes("access_code")
      }
      default: {
        return false
      }
    }
  })

  const context = useAccessPassModalContext()
  const showSnackbar = useShowSnackbar()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedMethods, setSelectedMethods] =
    useState<string[]>(availableMethods)

  const toggle = () => {
    setOpen(!open)
  }

  const toggleMethod = (method: string) => {
    if (selectedMethods.length === 1 && selectedMethods.includes(method)) {
      showSnackbar({
        message: "You must select at least one access method.",
        variant: "error",
      })
      return
    }

    let updatedSelectedMethods = selectedMethods
    if (selectedMethods.includes(method)) {
      updatedSelectedMethods = selectedMethods.filter((m) => m !== method)
    } else {
      updatedSelectedMethods = [...selectedMethods, method]
    }
    setSelectedMethods([...updatedSelectedMethods])

    context.setState((state) => {
      const selectedDevice = state.devices?.find(
        (d) => d.device_id === device.device_id
      )
      if (!selectedDevice) return state

      selectedDevice.can_use_access_code =
        updatedSelectedMethods.includes("Access code")
      selectedDevice.can_use_remote_unlock =
        updatedSelectedMethods.includes("Web unlock")
      return state
    })
  }

  const img = fetchBrandImageDetails(
    device.device_type,
    getDeviceProductModel(device)
  )

  return (
    <div
      className={classNames(
        "row",
        generateMods({
          open,
        })
      )}
    >
      <button className="trigger" onClick={toggle}>
        <div className="HStack">
          <div className="image">
            <Image src={img.src} alt={img.alt} />
          </div>
          <h4 className="name">{device.name}</h4>
        </div>
        <div className="HStack">
          <p className="caption">{selectedMethods.join(", ")}</p>
          <i className="icon size-6">{icons.accordionChevron}</i>
        </div>
      </button>

      <div className="content">
        <AnimatePresence>
          {open && (
            <Animated
              variants={{
                hidden: {
                  opacity: 0,
                  height: 0,
                },
                visible: {
                  opacity: 1,
                  height: "auto",
                },
              }}
              className="content-inner"
            >
              <div className="wfull SBStack">
                <div className="HStack">
                  {availableMethods.map((method) => (
                    <div key={method} className="HStack mr-4">
                      <Checkbox
                        name={method}
                        id={`${device.device_id}:${method}`}
                        color="primary"
                        checked={selectedMethods.includes(method)}
                        onChange={() => {
                          toggleMethod(method)
                        }}
                        size="small"
                      />
                      <label
                        className="caption m-0 ml-1"
                        htmlFor={`${device.device_id}:${method}`}
                      >
                        {method}
                      </label>
                    </div>
                  ))}
                </div>
                <button
                  className="row-action"
                  onClick={() => {
                    if (context.state) {
                      context.setState({
                        ...context.state,
                        device:
                          context.state.devices?.filter(
                            (d) => d.device_id === device.device_id
                          )[0] || ({} as Device),
                      })
                    }

                    context.navigate("deviceInfo", "forwards")
                  }}
                >
                  <span>Device info</span>
                  <i className="icon">{icons.accordionChevron}</i>
                </button>
              </div>
              <div className="h-4"></div>
            </Animated>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default Row
