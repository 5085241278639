import { useEffect } from "react"
import Image from "next/image"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"

interface Props {
  content: string
  autoRedirect?: boolean
}

const SuccessScreen = ({ content, autoRedirect = true }: Props) => {
  const context = useAccessPassModalContext()

  useEffect(() => {
    if (!autoRedirect) return

    const timer = setTimeout(() => {
      context.next()
    }, 1500)

    return () => clearTimeout(timer)
  }, [autoRedirect])

  return (
    <div className="wfull flex-c py-44">
      <Image
        src="/icons/green-check.svg"
        alt="Success"
        width={40}
        height={40}
      />
      <p className="fs-1 m-0 mt-2">{content}</p>
    </div>
  )
}

export default SuccessScreen
