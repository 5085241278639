/**
 *
 * @about Interweave two arrays together
 * @example
 * 		interweave([0, 2, 4, 6], [1, 3, 5]) -> [0, 1, 2, 3, 4, 5, 6]
 */
export const interweave = <T>([key, ...extras]: T[], values: T[] = []): T[] =>
  key === undefined ? values : [key, ...interweave(values, extras)]

export const range = (
  start: number,
  end: number,
  step: number = 1
): number[] => {
  return Array.from(
    { length: (end - start) / step + 1 },
    (_, i) => start + i * step
  )
}

export const mapBy = <T, K>(arr: T[], fn: (element: T) => K): Map<K, T[]> => {
  const map = new Map<K, T[]>()
  for (const element of arr) {
    const key = fn(element)
    const group = map.get(key)
    if (group) {
      group.push(element)
    } else {
      map.set(key, [element])
    }
  }
  return map
}
