import { Button } from "@mui/material"

import Buttons from "components/modals/LinkedAccountModal/summaries/Buttons"
import Header from "components/modals/LinkedAccountModal/summaries/Header"
import NumMappedDescription from "components/modals/LinkedAccountModal/summaries/NumMappedDescription"

import { Building } from "types"

interface Props {
  building: Building
  numDevicesMapped: number
  numDevicesTotal: number
  onDone: () => void
  onAddMore: () => void
}

const MoreDevicesAvailableSummary = ({
  numDevicesMapped,
  building,
  numDevicesTotal,
  onDone,
  onAddMore,
}: Props) => {
  const numRemaining = numDevicesTotal - numDevicesMapped
  const remainingText =
    numRemaining > 1
      ? `${numRemaining} devices were not added`
      : `One device was not added`

  return (
    <div className="screen">
      <Header className="my-32">
        <NumMappedDescription
          numMapped={numDevicesMapped}
          building={building}
        />
        <p className="mt-1">{remainingText}</p>
        <p>
          <Button color="secondary" variant="contained" onClick={onAddMore}>
            Add remaining devices
          </Button>
        </p>
      </Header>
      <Buttons onDone={onDone} />
    </div>
  )
}

export default MoreDevicesAvailableSummary
