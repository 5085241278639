import { Skeleton } from "@mui/material"
import { Box } from "@mui/system"

import { useSeamQuery } from "hooks/useSeamQuery"

import InternalLink from "components/interactive/InternalLink"
import Overflow from "components/typography/Overflow"

import IncidentTime from "./IncidentTime"

import { capitalize } from "lib/utils"

import { ApiResponseByClientMethod } from "types"

type Props = ApiResponseByClientMethod<"incidents.list">[number]

export const INCIDENT_TYPE_TO_DISPLAY_NAME: Record<string, string> = {
  "device.low_battery": "Low battery",
  "device.disconnected": "Device disconnected",
  "linked_account.disconnected": "Account offline",
}

const Incident = ({
  created_at,
  incident_type,
  building_id,
  device_id,
  linked_account_id,
}: Props) => {
  const { data: building } = useSeamQuery(
    ["buildings", "get", { building_id }],
    (seam) =>
      seam.buildings.get({
        building_id: building_id!,
      }),
    {
      enabled: !!building_id,
    }
  )

  const { data: device, isLoading: deviceLoading } = useSeamQuery(
    ["devices", "get", { device_id }],
    (seam) =>
      seam.devices.get({
        device_id: device_id!,
      }),
    {
      enabled: !!device_id,
    }
  )

  const { data: linkedAccount, isLoading: linkedAccountLoading } = useSeamQuery(
    ["linked_accounts", "get", { linked_account_id }],
    (seam) =>
      seam.linked_accounts.get({
        linked_account_id: linked_account_id!,
      }),
    {
      enabled: !!linked_account_id,
    }
  )

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        padding: "0.75rem 0",
        borderBottom: "1px solid #EEEEEE",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            width: "4.375rem",
          }}
        >
          <IncidentTime time={created_at} timezone={building?.timezone} />
        </Box>
        <Box>
          <Overflow
            sx={{
              fontSize: "1rem",
              fontWeight: 600,
              lineHeight: "21px",
            }}
            maxWidth="15.5rem"
          >
            {INCIDENT_TYPE_TO_DISPLAY_NAME[incident_type] ?? name}
          </Overflow>
          {linked_account_id && (
            <Overflow
              variant="body2"
              sx={{
                fontWeight: 400,
                lineHeight: "21px",
              }}
              maxWidth="15.5rem"
            >
              {linkedAccountLoading ? (
                <Skeleton variant="text" />
              ) : (
                <InternalLink href={`/linked-accounts/${linked_account_id}`}>
                  {capitalize(linkedAccount?.account_type ?? "")} Account
                </InternalLink>
              )}
            </Overflow>
          )}
          {device_id && (
            <Overflow
              variant="body2"
              sx={{
                fontWeight: 400,
                lineHeight: "21px",
              }}
              maxWidth="15.5rem"
            >
              {deviceLoading ? (
                <Skeleton variant="text" />
              ) : (
                <InternalLink href={`/devices/${device?.device_id}`}>
                  {device?.name}
                </InternalLink>
              )}
            </Overflow>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Incident
