import { Box } from "@mui/material"

import { colorDefaults } from "lib/mui"

const MenuSeparator = () => (
  <Box
    sx={{
      width: "100%",
    }}
    className="menu-separator"
  >
    <Box
      sx={{
        width: "100%",
        height: "1px",
        backgroundColor: colorDefaults.lines.dividerStrokeLight,
      }}
    />
  </Box>
)

export default MenuSeparator
