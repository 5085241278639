import dayjs, { Dayjs } from "dayjs"
import moment from "moment"

import { Datetime, Time } from "types"

export const LONG_DATE_AND_TIME_FORMAT = "ddd MMM D, YYYY [at] h:mm A" // Mon Jan 1, 2021 at 12:00 PM
export const LONG_DATE_FORMAT = "ddd MMM D, YYYY" // Mon Jan 1, 2021
export const LONG_TIME_FORMAT = "h:mm A" // 12:00 PM

export const SHORT_DATE_AND_TIME_FORMAT = "MMM DD [at] h:mm A" // Jan 1 at 12:00 PM
export const SHORT_DATE_FORMAT = "MMM DD, YYYY" // Jan 1, 2021

// This was altered from: https://stackoverflow.com/a/56490104
export const getBrowserTimezoneName = () => {
  const today = new Date()
  const short = today.toLocaleDateString(undefined)
  const long = today.toLocaleDateString(undefined, { timeZoneName: "long" })

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = long.indexOf(short)

  if (shortIndex >= 0) {
    const trimmed =
      long.substring(0, shortIndex) + long.substring(shortIndex + short.length)

    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "")
  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return long
  }
}

// Passsing `true` to the `guess()` method will ignore
// the timezone cache and request a new one from the browser.
//
// This means that if you get on a plane to Sydney, your timezone
// will change as you use Seam Space. This may not be the desired
// behavior, so this is TBD for the moment.
//
// FUTURE: Remove this message after behavior has been decided.
export const guessTimezone = () => {
  return moment.tz.guess(true) || "America/Los_Angeles"
}

export const mergeDateAndTime = (date: Dayjs, time: Dayjs): Dayjs => {
  // DayJS uses 24hr time so the meridiem is not needed
  return date.set("hour", time.get("hour")).set("minute", time.get("minute"))
}

export const validateDateAndTimeRange = (start: Datetime, end: Datetime) => {
  return (
    end.date.isAfter(start.date, "day") ||
    (end.date.isSame(start.date, "day") &&
      end.time.isAfter(start.time, "minute"))
  )
}

export const roundUpMinute = (minute: string) => {
  const rounded = Math.ceil(Number(minute) / 5) * 5

  if (rounded === 60 || rounded === 0) {
    return "00"
  }

  if (rounded < 10) {
    return `0${rounded}`
  }

  return rounded.toString()
}

export const timeObjectToDayjs = (time: Time): Dayjs => {
  return dayjs(`${time.hour}:${time.minute} ${time.meridiem}`, "h:mm A")
}
