// A specific version of a Card meant to display
// either a numerical statistic or an icon.

import { SxProps, Typography } from "@mui/material"

import Card, { Props as CardProps } from "components/cards/Card"

interface Props extends Pick<CardProps, "contained" | "onClick"> {
  value: number | string | React.ReactNode
  label: string
  variant?: "default" | "warning" | "error"
  sx?: SxProps
}

const Tile = ({ value, label, variant, sx = {}, ...props }: Props) => {
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        border: "none",
        ...sx,
      }}
      variant="statistic"
      {...props}
    >
      {typeof value === "string" || typeof value === "number" ? (
        <Typography
          variant="body1"
          component="h2"
          sx={{
            fontSize: "2.25rem",
            lineHeight: "2.875rem",
            color: variant === "error" ? "error.light" : "text.primary",
          }}
        >
          {value}
        </Typography>
      ) : (
        value
      )}
      <Typography
        variant="body2"
        sx={{
          fontSize: "0.75rem",
          fontWeight: "700",
          lineHeight: "0.875rem",
          letterSpacing: "0.05em",
          color: "#6E7179",
          textTransform: "uppercase",
          whiteSpace: "nowrap",
          mt: 0.5,
        }}
      >
        {label}
      </Typography>
    </Card>
  )
}

export default Tile
