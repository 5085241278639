import Buttons from "components/modals/LinkedAccountModal/summaries/Buttons"
import Header from "components/modals/LinkedAccountModal/summaries/Header"
import NumMappedDescription from "components/modals/LinkedAccountModal/summaries/NumMappedDescription"

import { Building } from "types"

interface Props {
  numMapped: number
  building: Building
  onDone: () => void
}

const MappedAllDevicesSummary = ({ numMapped, building, onDone }: Props) => {
  return (
    <div className="screen">
      <Header className="my-32">
        <NumMappedDescription numMapped={numMapped} building={building} />
      </Header>
      <Buttons onDone={onDone} />
    </div>
  )
}

export default MappedAllDevicesSummary
