import Building from "./Building"
import Device from "./Device"
import Home from "./Home"

const index = {
  Home,
  Building,
  Device,
}

export default index
