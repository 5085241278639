import { useRouter } from "next/router"

import { usePanels } from "providers/PanelsProvider"

import { Incident } from "./Incident"
import { useBuildingQuery, useDeviceQuery } from "./queries"
import Row from "./Row"

import { fetchBrandImageDetails } from "lib/brands"
import { getDeviceProductModel } from "lib/utils/devices"

interface Props {
  incident: Incident
}

const DeviceRow = ({ incident }: Props) => {
  const { data: building } = useBuildingQuery(incident.building_id)
  const { data: device } = useDeviceQuery(incident.device_id)

  const { push } = useRouter()
  const panels = usePanels()

  const onClick = () => {
    push(`/devices/${incident.device_id}`)
    panels.set("issueModal", false)
  }

  if (!device) {
    return (
      <button onClick={onClick} className="row">
        <div className="row-container">
          <div className="row-content">Unknown Device</div>
        </div>
      </button>
    )
  }

  const brandImage = fetchBrandImageDetails(
    device.device_type,
    getDeviceProductModel(device)
  )

  return (
    <Row
      incidentType={incident.incident_type}
      label={device.name}
      caption={building?.name ?? "Unknown Building"}
      imageDetails={brandImage}
      onClick={onClick}
    />
  )
}

export default DeviceRow
