import { Link } from "@mui/material"
interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  openInNewTab?: boolean
}

const ExternalLink = ({
  href,
  openInNewTab,
  className,
  children,
  ...props
}: Props) => {
  return (
    <Link
      component="a"
      href={href}
      rel="nofollow noopener noreferrer"
      sx={{ textDecoration: "none" }}
      target={openInNewTab ? "_blank" : "_self"}
      className={className}
      {...props}
    >
      {children}
    </Link>
  )
}

ExternalLink.defaultProps = {
  openInNewTab: true,
}

export default ExternalLink
