import { Box, Typography } from "@mui/material"
import dayjs from "dayjs"

import Logo from "components/brand/Logo"
import DynamicLink from "components/interactive/DynamicLink"

import { colorDefaults } from "lib/mui"

import type { Action } from "types"

const links: Action[] = [
  {
    label: "Learn about Seam",
    href: "https://seam.co",
  },
  {
    label: "Frequently asked questions",
    href: "https://help.seam.co/en/",
  },
  {
    label: "Contact us",
    href: "mailto:support@getseam.com",
  },
]

interface Props {
  bg?: string
  logoHref?: string
}

const Footer = ({ bg, logoHref = "/" }: Props) => {
  return (
    <Box
      sx={{
        py: 4,
        backgroundColor: bg ?? "#F3F5F6",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: 4,
        }}
      >
        <Logo href={logoHref} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {links.map((link, index) => (
              <DynamicLink
                key={link.label}
                href={link.href ?? "/"}
                className="flex flex-row"
              >
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    color: colorDefaults.text.textGray1,

                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {link.label}
                </Typography>
                {index !== links.length - 1 && (
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      color: colorDefaults.text.textGray2,
                      margin: "0 0.5rem",
                    }}
                  >
                    •
                  </Typography>
                )}
              </DynamicLink>
            ))}
          </Box>

          <Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: "0.875rem",
                color: colorDefaults.text.textGray1,
                mt: 0.5,
              }}
            >
              Copyright &copy; {dayjs().year()} Seam Labs, Inc. All rights
              reserved.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
