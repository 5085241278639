import { MouseEventHandler } from "react"
import { AvatarGroup, Box, ButtonBase, Typography } from "@mui/material"

import MemberAvatar from "components/data/MemberAvatar"

import { colorDefaults } from "lib/mui"

import { User } from "types"

interface Props {
  members: User[]
  onClick: MouseEventHandler<HTMLButtonElement>
}

const MemberGroup = ({ members, onClick }: Props) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <ButtonBase
        onClick={onClick}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          backgroundColor: "transparent",
          position: "relative",

          "&:hover::after": {
            opacity: 1,
          },

          "&::after": {
            content: "''",
            position: "absolute",
            inset: "-0.25rem",
            borderRadius: "0.375rem",
            border: `4px solid ${colorDefaults.background.bgB}`,
            backgroundColor: colorDefaults.background.bgB,
            transition: "opacity var(--quick-1) var(--expressive)",
            opacity: 0,
            zIndex: 1,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "row",
            position: "relative",
            zIndex: 2,
          }}
        >
          <AvatarGroup max={5}>
            {members.map((member) => (
              <MemberAvatar key={member.user_id} member={member} />
            ))}
          </AvatarGroup>
          <Typography
            variant="body2"
            sx={{
              color: colorDefaults.text.hyperlink,
              ml: 1,
            }}
          >
            See details
          </Typography>
        </Box>
      </ButtonBase>
    </Box>
  )
}

export default MemberGroup
