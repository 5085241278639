import type { Children } from "types"

interface Props extends Children, React.HTMLAttributes<HTMLDivElement> {
  src: string
  alt?: string
}

const BackgroundImage = ({ src, alt, children, style, ...props }: Props) => {
  return (
    <div
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        ...style,
      }}
      {...props}
    >
      {alt && <span className="sr-only">{alt}</span>}
      {children}
    </div>
  )
}

export default BackgroundImage
