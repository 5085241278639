import { createContext, Dispatch, SetStateAction, useContext } from "react"

import { contextDefaults } from "components/modals/StackModal/defaults"
import { StackOperatingContext } from "components/modals/StackModal/types"

import { AccessPass, Device } from "types"
import { Datetime } from "types/time"

export interface AccessPassModalData {
  state: AccessPassModalState
  setState: Dispatch<SetStateAction<AccessPassModalState>>
}

export interface AccessPassModalState {
  // The currently selected device (set when clicking "Device Info")
  device: Device | undefined

  // The devices selected in DeviceListScreen
  devices?: (Device & {
    can_use_access_code?: boolean
    can_use_remote_unlock?: boolean
  })[]

  // The name of the access pass, set in DetailsScreen
  name?: string

  // Whether the user has enabled custom time, set in TimingBlock
  customTimeEnabled?: boolean

  // The selected start and end time, set in TimingBlock
  start?: Datetime
  end?: Datetime

  // The access pass, set after creation
  access_pass?: AccessPass

  // Whether the custom time set is valid (i.e. start < end)
  isCustomTimeValid?: boolean
}

export const defaultState = {
  device: undefined,
  devices: undefined,
  name: undefined,
  start: undefined,
  end: undefined,
}

export const AccessPassModalContext = createContext<
  StackOperatingContext<AccessPassModalData>
>({
  ...contextDefaults,
  state: defaultState,
  setState: () => {},
})

export const useAccessPassModalContext = () => {
  return useContext(AccessPassModalContext)
}
