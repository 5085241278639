import { MouseEventHandler } from "react"
import { Box, SxProps, Typography } from "@mui/material"

import Badge, { Props as BadgeProps } from "components/data/Badge"

import type { Children } from "types"

export interface Props extends Children, React.HTMLAttributes<HTMLDivElement> {
  title?: string
  badgeContent?: React.ReactNode
  variant?: "default" | "statistic"

  // Contained within another Card
  contained?: boolean
  action?: React.ReactNode

  onClick?: MouseEventHandler

  sx?: SxProps
  badgeProps?: BadgeProps
}

const Card = ({
  title,
  badgeContent,
  action,
  variant,
  contained,
  onClick,
  sx,
  children,
  badgeProps,
  ...props
}: Props) => {
  const getClickableStyles = () => {
    if (onClick) {
      return {
        cursor: "pointer",
        "&:hover": {
          background: "linear-gradient(180deg, #F8FCFF 0%, #FFFFFF 100%)",
          boxShadow: "0px 0px 1px #0B53A8, 0px 2px 14px rgba(7, 70, 107, 0.09)",
        },
        "&:active": {
          background: "#DDE0E2",
          boxShadow: "0px 0px 1px #0B53A8, 0px 2px 14px rgba(7, 70, 107, 0.09)",
        },
      }
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        padding: variant === "statistic" ? "1rem 1.5rem" : "1.5rem",
        background: contained
          ? "white"
          : "linear-gradient(180deg, #f3f7fa 0%, #f9fafc 100%)",
        boxShadow: contained
          ? "none"
          : "0px 0px 1px rgba(11, 83, 168, 0.72), 0px 2px 14px rgba(7, 70, 107, 0.09)",
        borderRadius: "0.75rem",
        transition: "all 0.2s ease-in-out !important",
        ...getClickableStyles(),
        ...sx,
      }}
      onClick={onClick}
      {...props}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <div className="HStack">
          {title && (
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.125rem",
                fontWeight: 600,
                lineHeight: "23.76px",
              }}
            >
              {title}
            </Typography>
          )}
          {typeof badgeContent !== "undefined" && (
            <Badge {...badgeProps}>{badgeContent}</Badge>
          )}
        </div>
        {action}
      </Box>
      {children}
    </Box>
  )
}

export default Card
