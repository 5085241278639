export const avatarColors = [
  "#B88FFD",
  "#868BFF",
  "#57C8AD",
  "#38A8E7",
  "#127BC7",
  "#EF9B5E",
]

export const generateNumericalHash = (str: string): number => {
  let hash = 0

  str.split("").forEach((_, index) => {
    hash = str.charCodeAt(index) + ((hash << 5) - hash)
  })

  if (hash < 0) {
    hash = hash * -1
  }

  return hash
}

export function generateColorFromString(name: string) {
  const hash = generateNumericalHash(name)
  return avatarColors[hash % avatarColors.length]
}
