// A button that looks like a link

import { MouseEventHandler } from "react"
import { useRouter } from "next/router"
import { ButtonBase, ButtonBaseProps, SxProps } from "@mui/material"

import { colorDefaults } from "lib/mui"

import { Children } from "types"

interface Props extends Children, ButtonBaseProps {
  href?: string
  sx?: SxProps
}

const LinkButton = ({ href, onClick, sx, children, ...props }: Props) => {
  const router = useRouter()

  const handleClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    if (href) {
      ev.preventDefault()
      router.push(href)
    }

    if (onClick) {
      onClick(ev)
    }
  }

  return (
    <ButtonBase
      onClick={handleClick}
      sx={{
        color: colorDefaults.text.hyperlink,
        ...sx,
      }}
      {...props}
    >
      {children}
    </ButtonBase>
  )
}

export default LinkButton
