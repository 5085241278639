import Image from "next/image"
import classNames from "classnames"

import { useSeamQuery } from "hooks/useSeamQuery"

import { fetchBrandLogoAndName } from "lib/brands"
import { LinkedAccountType, UNKNOWN_LOCK_IMAGE } from "lib/constants"
import ChevronRight from "assets/images/chevron-right.svg"

import { LinkedAccount } from "types"

interface Props {
  linkedAccount: LinkedAccount
  onClick: () => void
}

const LinkedAccountRow = ({ linkedAccount, onClick }: Props) => {
  const options = { has_no_building: true }
  const { data: devices = [] } = useSeamQuery(
    [
      "devices",
      "list",
      { linked_account_id: linkedAccount.linked_account_id },
      options,
    ],
    (seam) =>
      seam.devices.list({
        linked_account_id: linkedAccount.linked_account_id,
        ...options,
      })
  )

  const brand = fetchBrandLogoAndName(
    linkedAccount.account_type as LinkedAccountType
  )

  const image = brand ? brand.logo : UNKNOWN_LOCK_IMAGE
  const name = brand ? brand.readableName : "Unknown Account Type"

  const disabled = devices.length === 0

  return (
    <div
      className={classNames("pr-2 pl-6 row", { disabled })}
      onClick={() => {
        if (!disabled) onClick()
      }}
    >
      <div className="d-flex align-center linked-account w-full">
        <div className="brand-image mr-4">
          <Image src={image} alt={name} width={120} />
        </div>
        <div className="SBStack w-full">
          <div className="fs--2">
            <span>{linkedAccount.user_identifier.email}</span>
            <div className="text-tertiary mt-1">
              <span>{name}</span>
              <span className="mx-2">•</span>
              <span>{linkedAccount.device_count}</span>
            </div>
          </div>
          <ChevronRight />
        </div>
      </div>
    </div>
  )
}

export default LinkedAccountRow
