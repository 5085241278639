import { Box, BoxProps, SxProps, Typography } from "@mui/material"

import { colorDefaults } from "lib/mui"

export interface Props extends BoxProps {
  backgroundColor?: string
  fontColor?: string
  sx?: SxProps & {
    ml?: number
  }
}

const Badge = ({
  backgroundColor,
  fontColor,
  sx,
  children,
  ...props
}: Props) => {
  return (
    <Box
      sx={{
        minWidth: "1.25rem",
        backgroundColor: backgroundColor ?? colorDefaults.text.textGray2,
        borderRadius: "2.5rem",
        padding: "0 0.25rem",
        textAlign: "center",
        gap: "0.75rem",
        ml: sx?.ml ?? 1,
        ...sx,
      }}
      {...props}
    >
      <Typography
        sx={{
          color: fontColor ?? "white",
          fontSize: "0.875rem",
          fontWeight: 600,
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

export default Badge
