import { Autocomplete, TextField } from "@mui/material"

import { Building } from "types"

interface Props {
  value: Building | null
  onChange: (building: Building | null) => void
  buildings?: Building[]
}

const BuildingPicker = ({ onChange, buildings, value }: Props) => {
  return (
    <div className="wfull my-6">
      <div className="HStack justify-c">
        <p className="m-0">Add devices to</p>
        <div className="ml-2">
          <Autocomplete
            disablePortal
            options={buildings || []}
            getOptionLabel={(option) => option.name}
            value={value}
            onChange={(_event, updated) => {
              onChange(updated)
            }}
            disabled={!buildings}
            isOptionEqualToValue={(a, b) => a.building_id === b.building_id}
            aria-label="building picker"
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                placeholder="Choose a building"
                hiddenLabel
                variant="outlined"
                sx={{
                  background: "#F3F5F7",
                  border: "none !important",
                  boxShadow: "none !important",
                }}
                {...params}
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default BuildingPicker
