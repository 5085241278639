import type { Variant } from "framer-motion"

export interface Variants {
  hidden: Variant
  visible: Variant
  exit?: Variant
}

export const variants = {
  fade: {
    default: {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    },
    in: {
      up: {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
      },
      right: {
        hidden: { opacity: 0, x: -20 },
        visible: { opacity: 1, x: 0 },
      },
      left: {
        hidden: { opacity: 0, x: 20 },
        visible: { opacity: 1, x: 0 },
      },
    },
  },
  scale: {
    full: {
      hidden: { opacity: 0, scale: 0 },
      visible: { opacity: 1, scale: 1 },
    },
    half: {
      hidden: { opacity: 0, scale: 0.5 },
      visible: { opacity: 1, scale: 1 },
    },
    partial: {
      hidden: { opacity: 0, scale: 0.85 },
      visible: { opacity: 1, scale: 1 },
    },
    subtle: {
      hidden: { opacity: 0, scale: 0.95 },
      visible: { opacity: 1, scale: 1 },
    },
  },
} as const

export const transition = {
  default: {
    type: "tween",
    duration: 0.5,
  },
  baseSpring: {
    type: "spring",
    bounce: 0.5,
    duration: 0.25,
  },
}

export const withDuration = (duration: number) => {
  return {
    ...transition.default,
    duration,
  }
}

export const withDelay = (delay: number) => {
  return {
    ...transition.default,
    delay,
  }
}
