import { UNKNOWN_LOCK_IMAGE } from "./misc"

export type SmartThingsLockModel =
  | "yale"
  | "august"
  | "kwikset"
  | "schlage"
  | "keywe"
  | "lockly"
  | "philia"
  | "samsung"
  | "unknown"

import { StaticImageData } from "next/image"

import AugustDeviceImage from "../../public/devices/smartthings/august.png"
import KeyweDeviceImage from "../../public/devices/smartthings/keywe.png"
import KwiksetDeviceImage from "../../public/devices/smartthings/kwikset.png"
import LocklyDeviceImage from "../../public/devices/smartthings/lockly.png"
import PhiliaDeviceImage from "../../public/devices/smartthings/philia.png"
import SchlageDeviceImage from "../../public/devices/smartthings/schlage.png"
import YaleDeviceImage from "../../public/devices/smartthings/yale.png"

export const SMARTTHINGS_DEVICE_MODEL_IMAGES: {
  [key in SmartThingsLockModel]: StaticImageData
} = {
  yale: YaleDeviceImage,
  august: AugustDeviceImage,
  kwikset: KwiksetDeviceImage,
  schlage: SchlageDeviceImage,
  keywe: KeyweDeviceImage,
  lockly: LocklyDeviceImage,
  philia: PhiliaDeviceImage,
  samsung: UNKNOWN_LOCK_IMAGE,
  unknown: UNKNOWN_LOCK_IMAGE,
}
