import { useShowSnackbar } from "components/presentational/Snackbar"

const DEFAULT_SUCCESS_MESSAGE = "Copied to clipboard"
const DEFAULT_ERROR_MESSAGE = "Failed to copy to clipboard"

interface Options {
  showSnackbarOnSuccess?: boolean
  snackbarSuccessMessage?: string
  snackbarErrorMessage?: string
}

export const useClipboard = (rootOptions?: Options) => {
  const showSnackbar = useShowSnackbar()

  const copy = async (text: string, options?: Options) => {
    const {
      showSnackbarOnSuccess = true,
      snackbarSuccessMessage = DEFAULT_SUCCESS_MESSAGE,
      snackbarErrorMessage = DEFAULT_ERROR_MESSAGE,
    } = {
      ...rootOptions,
      ...options,
    } || {}

    if (!navigator.clipboard) {
      showSnackbar({
        message: "Your browser does not support copying to clipboard",
        variant: "error",
      })
      return
    }

    try {
      await navigator.clipboard.writeText(text)

      if (showSnackbarOnSuccess) {
        showSnackbar({
          message: snackbarSuccessMessage,
          variant: "success",
        })
      }
    } catch (error) {
      showSnackbar({
        message: snackbarErrorMessage,
        variant: "error",
      })
    }
  }

  return { copy }
}
