import { useEffect, useState } from "react"
import dayjs from "dayjs"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import TimeMenu from "components/modals/AccessPassCreateModal/fragments/TimingBlock/TimeMenu"

import { range } from "lib/utils"

export interface Time {
  hour: string
  minute: string
  meridiem: Meridiem
}

type Meridiem = "AM" | "PM"

const roundUpMinute = (minute: string) => {
  const rounded = Math.ceil(Number(minute) / 5) * 5

  if (rounded === 60 || rounded === 0) {
    return "00"
  }

  if (rounded < 10) {
    return `0${rounded}`
  }

  return rounded.toString()
}

interface Props {
  rangePosition: "start" | "end"
}

const TimePicker = ({ rangePosition }: Props) => {
  const context = useAccessPassModalContext()

  const [time, setTime] = useState<Time>(() => {
    const today = dayjs()

    const hour = today.format("h")
    const minute = today.format("m")
    const meridiem = today.format("A")

    return {
      hour,
      minute: roundUpMinute(minute),
      meridiem: meridiem as Meridiem,
    }
  })

  const handleChange = (
    value: string,
    type: "hour" | "minute" | "meridiem"
  ) => {
    setTime({
      ...time,
      [type]: value,
    })
  }

  useEffect(() => {
    context.setState((state) => ({
      ...state,
      [rangePosition]: {
        ...state[rangePosition],
        time: dayjs(`${time.hour}:${time.minute} ${time.meridiem}`, "h:mm A"),
      },
    }))
  }, [time, rangePosition])

  return (
    <div className="time-picker">
      <div className="fake-input">
        <TimeMenu
          type="hour"
          rangePosition={rangePosition}
          options={range(1, 12).map((i) => i.toString())}
          value={time.hour}
          onChange={(value) => handleChange(value, "hour")}
        />
        <p>:</p>
        <TimeMenu
          type="minute"
          rangePosition={rangePosition}
          options={range(0, 55, 5).map((i) => {
            if (i < 10) {
              return `0${i}`
            }

            return i.toString()
          })}
          value={time.minute}
          onChange={(value) => handleChange(value, "minute")}
        />
        <TimeMenu
          type="meridiem"
          rangePosition={rangePosition}
          options={["AM", "PM"]}
          value={time.meridiem}
          onChange={(value) => handleChange(value, "meridiem")}
        />
      </div>
    </div>
  )
}

export default TimePicker
