import { Box } from "@mui/material"
import moment from "moment-timezone"

import { useBuilding } from "hooks/api/useBuilding"

import Brand from "components/brand/Brand"
import Card from "components/cards/Card"
import Caption from "components/typography/Caption"

import { brands } from "lib/brands"
import { LinkedAccountType } from "lib/constants"

const TIME_FORMAT = "MMM D, YYYY [at] h:mm A"

export interface Props {
  accountType: LinkedAccountType
  userIdentifier: any
  createdAt: string
}

export const LinkedAccountCard = ({
  accountType,
  userIdentifier,
  createdAt,
}: Props) => {
  const createdAtTimestamp = moment(createdAt)
  const brand = brands[accountType]

  const { building } = useBuilding()
  if (!building) {
    return null
  }

  return (
    <Card
      title="Linked account"
      sx={{
        mt: 3,
      }}
    >
      <Box my={1}>
        <Brand brand={accountType} />
      </Box>

      <Box
        mt={3}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: "0.25rem 0",
        }}
      >
        {brand && <Caption>{brand.readableName}</Caption>}
        <Caption>{userIdentifier?.email || userIdentifier?.phone}</Caption>
        <Caption>
          Linked on{" "}
          {createdAtTimestamp.tz(building.timezone).format(TIME_FORMAT)}
        </Caption>
      </Box>
    </Card>
  )
}

export default Props
