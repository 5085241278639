import { useUserOrgRole } from "hooks/api/useUserOrgRole"
import useSessionStore from "hooks/useSessionStore"

/**
 * Utility to quickly get the highest role of the current user in relation to
 * the current organization.
 */
export const useMyOrgRole = () => {
  const user_id = useSessionStore((state) => state.user_id)

  return useUserOrgRole(user_id)
}

export default useMyOrgRole
