import { Ref } from "react"
import { AnimatePresence, motion } from "framer-motion"

import useDynamicPanel from "hooks/useDynamicPanel"

import DynamicLink from "components/interactive/DynamicLink"

import Trigger from "./Trigger"

import { transition, variants } from "lib/constants/motion"

const Mobile = () => {
  const { ref, isOpen, toggle } = useDynamicPanel()

  return (
    <div className="mobile-menu-wrapper relative">
      <Trigger onClick={toggle} />

      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={ref as Ref<HTMLDivElement>}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants.fade.in.left}
            transition={{
              ...transition.baseSpring,
              duration: 0.75,
            }}
            className="mobile-menu"
          >
            <DynamicLink href="/account" className="menu-action">
              Account
            </DynamicLink>
            <DynamicLink href="/logout" className="menu-action">
              Account
            </DynamicLink>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Mobile
