import { Box } from "@mui/material"

import Image from "components/media/Image"

import { brands } from "lib/brands"

interface Props {
  brand?: keyof typeof brands
}

const Brand = ({ brand }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "0.75rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "2px 20px",
        height: "52px",
        cursor: "pointer",

        "&:hover": {
          backgroundColor: "white",
          boxShadow:
            "0px 0px 1px rgba(11, 83, 168, 0.72), 0px 2px 12px rgba(7, 70, 107, 0.06)",
        },

        "&:active": {
          backgroundColor: "#DDE0E2",
          boxShadow:
            "0px 0px 1px rgba(11, 83, 168, 0.72), 0px 2px 12px rgba(7, 70, 107, 0.06)",
        },
      }}
    >
      <Image
        src={brands[brand ?? "august"].logo}
        alt={brands[brand ?? "august"].readableName}
        height={38}
      />
    </Box>
  )
}

export default Brand
