import { PropsWithChildren } from "react"
import { Box, CircularProgress } from "@mui/material"

import DeviceRow from "components/modals/LinkedAccountModal/MapDeviceStep/DeviceRow"
import Caption from "components/typography/Caption"

import { Device } from "types"

interface Props {
  onSelectAll: () => void
  onUnselectAll: () => void
  value: string[]
  onChange: (deviceIds: string[]) => void
  devices: Device[]
}

const DevicePicker = (props: Props) => {
  const { onSelectAll, onUnselectAll, value, devices } = props

  const selectedCount = value.length
  const totalCount = devices.length

  return (
    <>
      <div className="devices-list mx-8 scrollable-content">
        <div className="header">
          <div className="SBStack">
            <div>
              <div className="HStack">
                <button className="header-action" onClick={onSelectAll}>
                  All
                </button>
                <p className="fs--1 mx-2 opacity-05">•</p>
                <button className="header-action" onClick={onUnselectAll}>
                  None
                </button>
              </div>
            </div>
            <div>
              <p className="fs--1 m-0">
                {selectedCount} of {totalCount} selected
              </p>
            </div>
          </div>
        </div>
        <div className="list">
          <List {...props} />
        </div>
      </div>
    </>
  )
}

function List({ devices, value, onChange }: Props) {
  if (!devices) {
    return (
      <EmptyPlaceholder>
        <CircularProgress size={24} />
      </EmptyPlaceholder>
    )
  }

  if (devices.length === 0) {
    return (
      <EmptyPlaceholder>
        <Caption>This account has no devices</Caption>
      </EmptyPlaceholder>
    )
  }
  return (
    <>
      {devices.map((device, index) => (
        <DeviceRow
          key={device.device_id}
          device={device}
          index={index}
          onToggle={() => {
            onChange(
              value.includes(device.device_id) // already selected?
                ? value.filter((deviceId) => deviceId !== device.device_id) // Remove
                : [...value, device.device_id] // add
            )
          }}
          isSelected={value.includes(device.device_id)}
        />
      ))}
    </>
  )
}

function EmptyPlaceholder({ children }: PropsWithChildren) {
  return (
    <Box
      sx={{
        minHeight: "370px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  )
}

export default DevicePicker
