import DatePicker from "components/modals/AccessPassCreateModal/fragments/TimingBlock/DatePicker"
import TimePicker from "components/modals/AccessPassCreateModal/fragments/TimingBlock/TimePicker"

import { capitalize } from "lib/utils"

interface Props {
  rangePosition: "start" | "end"
}

const DatetimePicker = ({ rangePosition }: Props) => {
  return (
    <div className="datetime-picker">
      <p className="track-label">{capitalize(rangePosition)}</p>
      <DatePicker rangePosition={rangePosition} />
      <TimePicker rangePosition={rangePosition} />
    </div>
  )
}

export default DatetimePicker
