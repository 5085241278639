import React, { useEffect, useRef, useState } from "react"
import { BsX } from "react-icons/bs"
import { inputUnstyledClasses } from "@mui/base/InputUnstyled"
import { styled } from "@mui/material/styles"
import { isFunction } from "lodash"

import TextInput, { TextInputProps } from "components/form/TextInput"
import Chip from "components/interactive/Chip"

import { handleString } from "lib/inputs"

const paddingY = "7px"
const paddingX = "8px"

const StyledTextInput = styled(TextInput, {
  shouldForwardProp: (prop: string) =>
    !["disableBottomPadding", "hideInputOnBlur"].includes(prop),
})<{
  disableBottomPadding?: boolean
  hideInputOnBlur?: boolean
}>(
  (props) => `
  .${inputUnstyledClasses.root} {
    flex-wrap: wrap;
    justify-content: flex-start;
    height:auto;
    padding: ${
      props.disableBottomPadding
        ? `${paddingY} ${paddingX} 0`
        : `${paddingY} ${paddingX}`
    };
    min-height: 40px;
    max-height: 300px;
    overflow-y: auto;
  }

  .${inputUnstyledClasses.input} {
    width: auto;
    flex: 1;

    padding: 0!important;
    ${props.hideInputOnBlur ? "height: 0;" : ""}
    margin-bottom: 0;

    &:focus {
      height: auto;
      margin-bottom: ${props.disableBottomPadding ? paddingY : "0"};
    }
  }
`
)

const StyledChip = styled(Chip)(
  () => `
      margin-right: 0.5rem;
      margin-bottom: 7px;
`
)

export type ChipInputProps = {
  value?: string[]
  "aria-label"?: string
  name?: string
  label?: string | ((itemCount: number) => string)
  disabled?: boolean
  onChange: (value: string[]) => void
  helperText?: string
  placeholder?: string
  isValid?: (item: string) => boolean
  className?: string
  onBlur?: TextInputProps["onBlur"]
}

const ChipInput = ({
  onChange,
  value,
  helperText,
  label,
  disabled,
  placeholder,
  isValid,
  "aria-label": ariaLabel,
  className,
  onBlur,
}: ChipInputProps) => {
  const [input, setInput] = useState("")
  const [items, setItems] = useState<string[]>(value || [])

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    onChange(items)
  }, [items, onChange])

  const addItem = () => {
    if (!input) {
      return
    }

    const existing = items.find((t) => t === input)
    if (existing) {
      setInput("")
      return
    }

    const isEmpty = input.replace(/\s/g, "").length === 0
    if (isEmpty) {
      return
    }

    const value = input.trim() // Don't want trailing white space

    const added = [...items, value]
    setItems(added)
    setInput("")
  }

  const removeItem = (value: string) => {
    const removed = items.filter((t) => t !== value)
    setItems(removed)
  }
  const handleBackspace = () => {
    const isEditing = input.length > 0
    if (isEditing) {
      return
    }

    const lastItem = items[items.length - 1]
    if (!lastItem) {
      return
    }

    removeItem(lastItem)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Keys that trigger an input / item add, aka the delimiters.
    switch (event.key) {
      case "Enter":
      case "Tab":
      case " ":
      case ",":
        event.preventDefault() // Prevent submitting form
        addItem()
        break
      case "Backspace":
        handleBackspace()
        break
    }
  }

  const hasItems = items.length > 0

  const clearInput = () => {
    setInput("")
  }

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    addItem()
    clearInput()
    onBlur && onBlur(event)
  }

  const focusInput = () => {
    inputRef.current?.click()
  }

  const handlePasteText = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault()
    const text = event.clipboardData.getData("Text")

    // We'll allow delimiting by comma OR space, but not both because that would be crazy.
    const delimiter = text.includes(",") ? "," : " "
    const items = text.split(delimiter).map((item) => item.trim()) // trim in case we have spaces left over
    setItems((existing) => [...new Set([...existing, ...items])]) // Remove duplicates
  }

  const hasError = (item: string) => isValid && !isValid(item)

  return (
    <StyledTextInput
      className={className}
      onChange={handleString(setInput)}
      label={isFunction(label) ? label(items.length) : label}
      value={input}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      disableBottomPadding={hasItems}
      hideInputOnBlur={hasItems}
      placeholder={hasItems ? "" : placeholder}
      onPaste={handlePasteText}
      ref={inputRef}
      onBlur={handleBlur}
      aria-label={ariaLabel}
      startAdornment={
        <>
          {items.map((item) => (
            <StyledChip
              onClick={focusInput}
              key={item}
              tabIndex={-1} // Disable text highlight
              label={
                <span aria-label={hasError(item) ? "invalid" : ""}>{item}</span>
              }
              onDelete={() => removeItem(item)}
              disabled={disabled}
              error={hasError(item)}
              deleteIcon={
                <div className="adornment" aria-label={`remove ${item}`}>
                  <i className="icon">
                    <BsX />
                  </i>
                </div>
              }
            />
          ))}
        </>
      }
      fullWidth
      helperText={helperText}
    />
  )
}

export default ChipInput
