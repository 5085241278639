import type { NextApiRequest, NextApiResponse } from "next"
import { orderBy } from "lodash"
import ms from "ms"

import { type Routes as OfficialRoutes } from "../space-api-client/routes"
import { type UnofficialRoutes } from "./unofficial-routes"
/**
 * This file contains all the routes to do operations on the fake
 * database.
 *
 * Note that this implementation misses a lot of edge cases and that's OK, it's
 * supposed to be lean and easy to edit so that people developing the frontend
 * can quickly modify it.
 **/

type RouteDefs = Omit<OfficialRoutes, keyof UnofficialRoutes> &
  UnofficialRoutes &
  InternalRoutes
type RoutePaths = keyof RouteDefs

import { Routes as InternalRoutes } from "../space-api-client/internal-routes"
import { FakeDatabase, time } from "./get-seeded-db"
import {
  ExampleAccessCodes,
  ExampleBuildings,
  ExampleDevices,
  ExampleLinkedAccounts,
  ExampleUnmanagedAccessCodes,
  uuid,
} from "./seed-data"
import { getNextFakeId } from "./util/get-next-fake-id"

import { AccessCode } from "types"

const WEBVIEW_URL =
  "https://connect.getseam.com/connect_webviews/view?connect_webview_id=91321e59-22d5-4f3a-afb4-c1d17d3cf751&auth_token=N6Z3nYo1Cf6WG6YiUtCM6w3YhNawgFcEW"

const validateLoggedIn = (req: any) => {
  if (req.headers.Authorization == "Bearer unauthenticated") {
    return false
  }
  if (
    !req.headers["Seam-Organization-Id"] ||
    req.headers["Seam-Organization-Id"]?.length == 0
  ) {
    return false
  }
  return true
}

const getBuildingDeviceCount = (building_id: string) => {
  if (building_id === "e1741d59-61df-418e-a458-56cc1429b242") {
    return 12
  } else if (building_id === "2f072a89-f29a-4fe7-a43d-3fae0f09ab31") {
    return 17
  } else if (building_id === "28511b9f-db2a-4b53-86cf-529a02f996b0") {
    return 22
  } else if (building_id === "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec") {
    return 23
  } else if (building_id === "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf") {
    return 22
  } else if (building_id === "d4c68b97-cf0f-43b0-a3f7-31a369155ed9") {
    return 11
  } else if (building_id === "241a0ce1-f0cf-4027-bc11-dd979fdfea78") {
    return 17
  } else if (building_id === "69f1ce59-daad-4775-a530-62cfe4a64c8b") {
    return 16
  } else if (building_id === "27816c08-658c-4c86-83ee-fde8a65ac669") {
    return 4
  } else if (building_id === "fe38801c-b4c0-4e11-8195-e6f96bdac092") {
    return 16
  } else if (building_id === "227daf76-35d8-42d7-9068-14ef0c4a056b") {
    return 19
  } else if (building_id === "8ad13286-17c9-48bd-a60f-ed6be3ecf057") {
    return 31
  } else if (building_id === "c9450aa1-276f-496e-b333-dea0a6b2fee6") {
    return 28
  } else if (building_id === "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5") {
    return 21
  } else if (building_id === "80605ae7-9cb0-4015-8481-daea1916de00") {
    return 33
  } else if (building_id === "0570bd0f-00c4-4cd3-918a-15f6167ba81e") {
    return 7
  } else if (building_id === "57051d8d-c75e-48ca-8151-f73d7277db22") {
    return 6
  } else {
    return 1
  }
}

export const routes: Partial<{
  [Route in RoutePaths]: (
    req: Omit<NextApiRequest, "body" | "query"> & {
      body: RouteDefs[Route]["jsonBody"] & RouteDefs[Route]["commonParams"]
      query: RouteDefs[Route]["queryParams"] & RouteDefs[Route]["commonParams"]
      db: FakeDatabase
    },
    res: NextApiResponse<
      Omit<RouteDefs[Route]["jsonResponse"], "ok"> & { ok?: boolean }
    >
  ) => any
}> = {
  "/health": (req, res) => {
    res.status(200).json({ ok: true })
  },
  "/internal/user_sessions/create": (req, res) => {
    res.status(200).json({
      session: {
        user_id: "123",
        user_session_id: "123",
        session_key: "123",
        expires_at: time(),
        created_at: time(),
      },
    })
  },
  "/internal/users/get_me": (req, res) => {
    res.status(200).json({
      user: {
        user_id: "user-1",
        email: "dwight.schrute@dunder-mifflin.com",
        first_name: "Dwight",
        last_name: "Schrute",
        title: "Sales Associate",
        organization_id: "org-1",
        created_at: time(),
        auth_provider: "email",
      },
    })
  },
  "/internal/organizations/list": (req, res) => {
    res.status(200).json({
      organizations: [
        {
          organization_id: "org-1",
          name: "Acme Property Management Buildings",
          created_by: null,
          created_at: time(),
        },
        {
          organization_id: "org-2",
          name: "Oak Hotel",
          created_by: null,
          created_at: time(),
        },
      ],
    })
  },
  "/internal/access_codes/get_details": (req, res) => {
    res.status(200).json({
      access_code: {
        access_code_id: "access-code-1",
        created_at: time(),
        code: "1234",
        type: "ongoing",
        ...({
          last_used_at: time(),
          name: "Access Code 1",
          used_count: 1,
        } as any),
      },
      device_name: "Front Door",
    })
  },
  "/internal/access_passes/get_details": (req, res) => {
    const access_pass = req.db.access_passes[0]
    const devices = ExampleDevices.slice(0, 3)

    res.status(200).json({
      building: ExampleBuildings[0],
      access_pass,
      accessible_devices: devices.map((device, i) => ({
        device_id: device.device_id,
        access_code_id: i % 2 === 0 ? `access-code-${i + 1}` : null,
        can_use_access_code: i % 2 === 0,
        can_use_remote_unlock: true,
        code: i % 2 === 0 ? "1234" : null,
        properties: {
          locked: true,
        },
      })),
      device_names: devices.map((device) => ({
        device_id: device.device_id,
        name: device.name,
      })),
    })
  },
  "/internal/access_passes/unlock_with_pass": async (req, res) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    res.status(200).json({
      ok: true,
    })
  },
  "/internal/access_passes/lock_with_pass": async (req, res) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    res.status(200).json({
      ok: true,
    })
  },
  "/access_passes/create_from_access_code": async (req, res) => {
    const body = req.body as {
      access_code_id: string
      can_use_remote_unlock: boolean
    }

    const access_code = ExampleAccessCodes.find(
      (ac) => ac.access_code_id === body.access_code_id
    )

    if (!access_code) {
      return res.status(500).end
    }

    const access_pass = {
      access_pass_id: uuid(),
      organization_id: "org-1",
      access_pass_name: "Access Pass 3",
      url: "https://seam.to/1234",
      starts_at: time(),
      does_not_end: true,
      last_used_at: time(),
      created_at: time(),
      device_count: 1,
      ends_at: null,
    }

    req.db.access_passes.push(access_pass)

    await new Promise((resolve) => setTimeout(resolve, 2000))
    res.status(200).json({
      access_pass,
    })
  },
  "/buildings/create": (req, res) => {
    res.status(200).json({
      building: ExampleBuildings[0],
    })
  },
  "/buildings/update": (req, res) => {
    res.status(200).json({})
  },
  "/buildings/get": (req, res) => {
    res.status(200).json({
      building:
        ExampleBuildings.find((b) => b.building_id == req?.body?.building_id) ||
        ExampleBuildings[0],
    })
  },
  "/buildings/get_count_summary": (req, res) => {
    if (!validateLoggedIn(req)) return res.status(500).end

    const building_id = req?.body?.building_id || ""

    res.status(200).json({
      building_count_summary: {
        devices: getBuildingDeviceCount(req?.body?.building_id ?? ""),
        incidents:
          building_id === "2f072a89-f29a-4fe7-a43d-3fae0f09ab31" ? 1 : 0,
        active_access_passes: 1,
        low_battery_incidents: 0,
        offline_account_incidents: 0,
        device_incidents: 0,
      },
    })
  },
  "/buildings/list": (req, res) => {
    if (!validateLoggedIn(req)) return res.status(500).end

    let buildings = ExampleBuildings

    const queries = req.body?.query?.split(" ") || []

    if (queries.includes("has:active-access-pass")) {
      const activeAccessPass = buildings.slice(5)
      buildings = activeAccessPass
    } else if (queries.includes("has:active-incidents")) {
      buildings = buildings.slice(15)
    }

    if (queries.includes("sort:events-desc(24h)")) {
      buildings = orderBy(buildings, "recent_event_count", "desc")
    } else if (queries.includes("sort:name-asc")) {
      buildings = orderBy(buildings, "name", "asc")
    } else if (queries.includes("sort:devices-asc")) {
      buildings = orderBy(buildings, "device_count", "asc")
    } else if (queries.includes("sort:access-passes-asc")) {
      buildings = orderBy(buildings, "active_access_pass_count", "asc")
    }

    res.status(200).json({
      buildings,
    })
  },
  "/buildings/remove_user": (req, res) => {
    req.db.building_roles = req.db.building_roles.filter(
      (r) => r.building_id != req?.body?.building_id
    )

    res.status(200).json({
      ok: true,
    })
  },
  "/buildings/update_user_role": (req, res) => {
    const { building_id, building_roles } = req?.body

    req.db.building_roles = [
      ...req.db.building_roles.filter((r) => r.building_id != building_id),
      {
        building_id,
        building_name: ExampleBuildings.find(
          (b) => b.building_id == building_id
        )!.name,
        building_roles,
        created_at: time(),
      },
    ]

    res.status(200).json({
      ok: true,
    })
  },
  "/buildings/remove_device": (req, res) => {
    res.status(200).json({})
  },
  "/devices/list": (req, res) => {
    const building_id = req?.body?.building_id
    const device_ids = req?.body?.device_ids
    const linked_account_id = req?.body?.linked_account_id
    const has_no_building = req?.body?.has_no_building
    let devices: any[] = []
    if (building_id) {
      devices = ExampleDevices.filter((d) => d.building_id == building_id)
    }
    if (linked_account_id) {
      devices = ExampleDevices.filter(
        (d) => d.linked_account_id == linked_account_id
      )
    }

    if (device_ids) {
      devices = ExampleDevices.filter((d) => device_ids.includes(d.device_id))
    }

    const queries = req.body.query?.split(" ") || []

    const filtered = devices.filter((d) => {
      if (queries.includes("has:low-battery")) {
        return d.properties.battery_level < 0.4
      }

      if (queries.includes("has:active-access-pass")) {
        return d.active_access_pass_count > 0
      }

      if (queries.includes("has:active-incidents")) {
        return d.issue_count > 0
      }

      if (queries.includes("has:offline-account")) {
        return d.offline_account === true
      }

      return d
    })

    const sort = (devices: any[]) => {
      if (queries.includes("sort:events-desc")) {
        return orderBy(devices, "recent_event_count", "desc")
      }

      if (queries.includes("sort:name-asc")) {
        return orderBy(devices, "name", "asc")
      }

      if (queries.includes("sort:active-access-passes-desc")) {
        return orderBy(devices, "active_access_pass_count", "desc")
      }

      return devices
    }

    if (has_no_building) {
      devices = ExampleDevices.filter((d) => !d.building_id)
    }
    res.status(200).json({
      devices: sort(filtered),
    })
  },
  "/devices/get": (req, res) => {
    const device = ExampleDevices.find(
      (d) => d.device_id == req?.body?.device_id
    )
    res.status(200).json({
      device: device as any,
    })
  },
  "/devices/get_count_summary": (req, res) => {
    res.status(200).json({
      device_count_summary: {
        active_access_passes: 4,
      },
    })
  },
  "/devices/list_counts": (req, res) => {
    res.status(200).json({
      device_counts: req.body.queries.map((query) => {
        if (query === "has:low-battery") {
          return {
            query,
            count: 0,
          }
        }

        if (query === "has:active-access-pass") {
          return {
            query,
            count: 39,
          }
        }

        if (query === "has:active-incidents") {
          return {
            query,
            count: 85,
          }
        }

        if (query === "has:offline-account") {
          return {
            query,
            count: 0,
          }
        }

        return {
          query,
          count: getBuildingDeviceCount(req.body.building_id ?? ""),
        }
      }),
    })
  },
  "/devices/update": (req, res) => {
    res.status(200).json({})
  },
  "/access_codes/get": (req, res) => {
    const access_code = ExampleAccessCodes.find(
      (a) => a.access_code_id == req?.body?.access_code_id
    )

    res.status(200).json({
      access_code: access_code as AccessCode,
    })
  },
  "/access_codes/update": (req, res) => {
    res.status(200).json({})
  },
  "/access_codes/delete": (req, res) => {
    res.status(200).json({})
  },
  "/access_passes/create": (req, res) => {
    const { access_pass_id } = getNextFakeId(
      req.db.access_passes,
      "access_pass_id"
    )

    const newAccessPass = {
      access_pass_id,
      url: `${process.env.NEXT_PUBLIC_SPACE_FRONTEND_URL}/access?access_pass_id=${access_pass_id}&auth_token=some-fake-token`,
      created_at: time(),
      type: "ongoing",
      access_methods: [
        {
          access_method: "remote_unlock",
          device_id: "device-1",
        },
        {
          access_method: "access_code",
          device_id: "device-2",
          code: "1234",
        },
      ],
      ...({
        access_pass_name: req.body.name,
        last_used_at: time(),
        recently_used_count: 1,
        used_count: 1,
      } as any),
      device_count: 0,
    }

    req.db.access_passes.push(newAccessPass)

    res.status(200).json({ access_pass: newAccessPass })
  },
  "/access_passes/get": (req, res) => {
    const access_pass = req.db.access_passes.find(
      (ap) => ap.access_pass_id === req.body.access_pass_id
    )!
    res.status(200).json({ access_pass })
  },
  "/access_passes/get_count_summary": (req, res) => {
    res.status(200).json({
      access_pass_counts: {
        total_uses: 1,
        uses_in_period: 1,
      },
    })
  },
  "/access_passes/list": (req, res) => {
    const query_key_value_pairs = req.body.query?.split(" ") || []

    const filtered = req.db.access_passes.filter((pass) => {
      if (query_key_value_pairs.includes("is:active")) {
        if (new Date(pass.starts_at) < new Date()) {
          if (pass.ends_at) {
            if (new Date(pass.ends_at) > new Date()) {
              return true
            } else {
              return false
            }
          }

          return true
        }

        return false
      }

      if (query_key_value_pairs.includes("does:not-expire")) {
        return !pass.ends_at
      }

      if (query_key_value_pairs.includes("is:expired")) {
        return pass.ends_at && new Date(pass.ends_at) < new Date()
      }

      if (query_key_value_pairs.includes("has:uses(0)")) {
        return false
      }

      return true
    })

    const sort = (passes: any[]) => {
      if (query_key_value_pairs.includes("sort:starts_at-desc")) {
        return orderBy(passes, "starts_at", "desc")
      }

      if (query_key_value_pairs.includes("sort:created_at-desc")) {
        return orderBy(passes, "created_at", "asc")
      }

      if (query_key_value_pairs.includes("sort:uses-desc")) {
        return orderBy(passes, "uses", "desc")
      }

      if (query_key_value_pairs.includes("sort:uses-in-period-desc(1d)")) {
        return orderBy(passes, "uses", "desc")
      }

      return passes
    }

    res.status(200).json({
      access_passes: sort(filtered),
    })
  },
  "/access_passes/list_accessible_devices": (req, res) => {
    res.status(200).json({
      accessible_devices: ExampleDevices.slice(0, 3).map((device, i) => ({
        device_id: device.device_id,
        access_code_id: i % 2 === 0 ? `access-code-${i + 1}` : null,
        can_use_access_code: i % 2 === 0,
        can_use_remote_unlock: true,
        code: i % 2 === 0 ? "1234" : null,
        properties: {
          locked: true,
        },
      })),
    })
  },
  "/access_passes/update": (req, res) => {
    res.status(200).json({})
  },
  "/access_passes/delete": (req, res) => {
    res.status(200).json({})
  },
  "/events/list": (req, res) => {
    res.status(200).json({
      events: [
        {
          event_type: "device.low_battery",
          event_id: "event-1",
          device_id: "288872ca-4ffa-4e45-a06e-7038914f8f39",
          occurred_at: time(),
          organization_id: "org-1",
          battery_level: 0.35,
          building_id: null,
        },
        {
          event_type: "lock.unlocked",
          organization_id: "org-2",
          event_id: "event-2",
          device_id: "c186f8a7-01fe-406c-83c5-6598abf16ef3",
          building_id: "building-2",
          occurred_at: time(),
          method: "manual",
          user_id: "user-1",
        },

        {
          event_type: "lock.unlocked",
          organization_id: "org-3",
          event_id: "event-3",
          device_id: "759cbd55-3be5-4463-aa7e-90e706d9dcc3",
          building_id: "building-3",
          occurred_at: time(),
          method: "access_pass",
          access_pass_id: "access-pass-1",
          user_id: "user-1",
        },
        {
          event_type: "access_code.created",
          organization_id: "org-4",
          event_id: "event-9",
          occurred_at: time(),
          access_code_id: "access-code-1",
          device_id: "759cbd55-3be5-4463-aa7e-90e706d9dcc3",
        },
        {
          event_type: "lock.unlocked",
          organization_id: "org-4",
          event_id: "event-10",
          device_id: "759cbd55-3be5-4463-aa7e-90e706d9dcc3",
          building_id: "building-3",
          occurred_at: time(),
          method: "keycode",
          access_code_id: "access-code-1",
        },
        {
          event_type: "linked_account.connected",
          organization_id: "org-4",
          event_id: "event-4",
          occurred_at: time(),
          linked_account_id: "la-4",
        },
        {
          event_type: "device.disconnected",
          organization_id: "org-2",
          event_id: "event-24",
          device_id: "759cbd55-3be5-4463-aa7e-90e706d9dcc3",
          building_id: null,
          occurred_at: time(),
        },
        {
          event_type: "access_pass.used",
          access_pass_id: "access-pass-1",
          user_id: "user-2",
          organization_id: "org-2",
          event_id: "event-5",
          building_id: null,
          occurred_at: time(),
        },
        {
          event_type: "device.tampered",
          event_id: "event-43",
          device_id: "288872ca-4ffa-4e45-a06e-7038914f8f39",
          occurred_at: time(),
          organization_id: "org-1",
          building_id: null,
        },
      ],
    })
  },
  "/events/get_count_summary": (_req, res) => {
    res.status(200).json({
      event_count_summary: {
        lock_unlock_events: 23,
        access_pass_events: 50,
        connection_events: 5,
        battery_events: 3,
      },
    })
  },
  "/access_codes/list": (req, res) => {
    const device_id = req?.body?.device_id
    let access_codes: AccessCode[] = []
    if (device_id) {
      access_codes = ExampleAccessCodes.filter(
        (ap) => ap.device_id == device_id
      )
    }
    res.status(200).json({
      access_codes,
    })
  },
  "/access_codes/unmanaged/update": (req, res) => {
    res.status(200).json({})
  },
  "/access_codes/unmanaged/delete": (req, res) => {
    res.status(200).json({})
  },
  "/access_codes/unmanaged/list": (req, res) => {
    const building_id = req?.body?.building_id
    const device_id = req?.body?.device_id
    let access_codes: Array<any> = []
    if (building_id) {
      access_codes = ExampleUnmanagedAccessCodes.filter(
        (ap) => ap.building_id == building_id
      )
    } else if (device_id) {
      access_codes = ExampleUnmanagedAccessCodes.filter(
        (ap) => ap.device_id == device_id
      )
    }
    res.status(200).json({
      access_codes,
    })
  },
  "/access_codes/unmanaged/get": (req, res) => {
    const access_code = ExampleUnmanagedAccessCodes.find(
      (a) => a.access_code_id == req?.body?.access_code_id
    )
    res.status(200).json({
      access_code: access_code || ExampleUnmanagedAccessCodes[0],
    })
  },
  "/linked_accounts/get": (req, res) => {
    const linked_account = ExampleLinkedAccounts.find(
      (a) => a.linked_account_id == req?.body?.linked_account_id
    )
    res.status(200).json({
      linked_account: linked_account || ExampleLinkedAccounts[0],
    })
  },
  "/linked_accounts/list": (req, res) => {
    res.status(200).json({
      linked_accounts: ExampleLinkedAccounts,
    })
  },
  "/linked_accounts/delete": (req, res) => {
    res.status(200).json({})
  },
  "/locks/unlock": async (req, res) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    res.status(200).json({
      ok: true,
    })
  },
  "/locks/lock": async (req, res) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    res.status(200).json({
      ok: true,
    })
  },
  "/login_portals/create": (req, res) => {
    res.status(200).json({
      login_portal: {
        login_portal_id: "login-portal-1",
        organization_id: "org-1",
        created_at: time(),
        view_url: WEBVIEW_URL,
        login_successful: true,
        linked_account_id: null,
        status: "authorized",
      },
    })
  },
  "/login_portals/get": (req, res) => {
    res.status(200).json({
      login_portal: {
        login_portal_id: "login-portal-1",
        organization_id: "org-1",
        created_at: time(),
        view_url: WEBVIEW_URL,
        login_successful: true,
        linked_account_id: "linked-account-au",
        status: "authorized",
      },
    })
  },
  "/login_portals/list": (req, res) => {
    res.status(200).json({
      login_portals: [
        {
          login_portal_id: "login-portal-1",
          organization_id: "org-1",
          created_at: time(),
          view_url: WEBVIEW_URL,
          login_successful: false,
          linked_account_id: null,
          status: "pending",
        },
        {
          login_portal_id: "login-portal-1",
          organization_id: "org-1",
          created_at: time(),
          view_url: WEBVIEW_URL,
          login_successful: true,
          linked_account_id: "linked-account-1",
          status: "authorized",
        },
      ],
    })
  },
  "/login_portals/delete": (req, res) => {
    res.status(200).json({})
  },
  "/organizations/get": (req, res) => {
    res.status(200).json({
      organization: {
        organization_id: "org-1",
        created_at: time(),
        created_by: "user-1",
        name: "Organization 1",
      },
    })
  },
  "/organizations/create": (req, res) => {
    res.status(200).json({
      organization: {
        organization_id: "org-1",
        created_at: time(),
        created_by: "user-1",
        name: "Organization 1",
      },
    })
  },
  "/organizations/update": (req, res) => {
    res.status(200).json({})
  },
  "/organizations/list": (req, res) => {
    res.status(200).json({
      organizations: [
        {
          organization_id: "org-1",
          name: "Acme Property Management",
          created_at: time(),
          created_by: "user-1",
        },
        {
          organization_id: "org-2",
          name: "Oak Hotel",
          created_at: time(),
          created_by: "user-2",
        },
      ],
    })
  },
  "/organizations/get_count_summary": (req, res) => {
    res.status(200).json({
      organization_counts: {
        incidents_in_period: 1,
        total_active_access_passes: 1,
        total_devices: 1,
        total_online_devices: 1,
        total_buildings: 3,
        total_linked_accounts: 2,
        total_super_admins: 1,
        total_admins: 1,
        total_members: 4,
      },
    })
  },
  "/organizations/invite_user": (req, res) => {
    res.status(200).json({})
  },
  "/organizations/pending_users/delete": (req, res) => {
    res.status(200).json({})
  },
  "/organizations/pending_users/list": (req, res) => {
    res.status(200).json({
      pending_users: [
        {
          organization_invitation_id: "org-invitation-1",
          organization_id: "org-1",
          email: "foo@example.com",
          user_id: "pending-user-1",
          status: "pending",
          role: "org:member",
          created_at: time(),
        },
        {
          organization_invitation_id: "org-invitation-2",
          organization_id: "org-1",
          email: "bar@example.com",
          user_id: "pending-user-2",
          status: "pending",
          role: "org:member",
          created_at: time(),
        },
      ],
    })
  },
  "/organizations/pending_users/resend_invitation": (req, res) => {
    res.status(200).json({})
  },
  "/organizations/pending_users/update": (req, res) => {
    res.status(200).json({})
  },
  "/organizations/update_user_role": (req, res) => {
    // Only want to trigger cannot change super admin error
    // for the first user
    if (req.body.user_id === "user-0") {
      // Errors are untyped, so we have to ignore types here
      res.status(400).json({
        // @ts-ignore
        error: {
          type: "last_super_admin_error",
        },
      })
    } else {
      res.status(200).json({})
    }
  },
  "/users/list": (req, res) => {
    res.status(200).json({
      users: [
        "Michael Scott",
        "Dwight Shrute",
        "Jim Halpert",
        "Pam Beasley",
        "Angela Martin",
        "Kevin Malone",
        "Oscar Martinez",
        "Toby Flenderson",
        "Phyllis Lapin",
        "Stanley Hudson",
        "Creed Bratton",
        "Kelly Kapoor",
        "Ryan Howard",
        "Meredith Palmer",
        "Erin Hannon",
        "Andy Bernard",
        "Robert California",
        "Darryl Philbin",
        "Gabe Lewis",
        "Holly Flax",
        "Jan Levinson",
        "Nellie Bertram",
        "Clark Green",
        "Jo Bennett",
        "Karen Filippelli",
        "Charles Miner",
        "David Wallace",
        "Mose Schrute",
        "Roy Anderson",
        "Todd Packer",
        "Deangelo Vickers",
        "Hank the security guard",
        "Alexandrina Constantinople-Marmaduke",
      ].map((name, i) => {
        let role = "org:member"

        if (i === 0 || i === 2) {
          role = "org:superadmin"
        }

        if (i === 4) {
          role = "org:admin"
        }

        return {
          first_name: name.split(" ")[0],
          last_name: name.split(" ")[1],
          email: `${name.replace(" ", ".").toLowerCase()}@dunder-mifflin.com`,
          created_at: time(),
          organization_id: "org-1",
          user_id: `user-${i}`,
          title: "Property Manager",
          role,
        }
      }),
    })
  },
  "/users/get": (req, res) => {
    res.status(200).json({
      user: {
        user_id: "user-1",
        created_at: time(),
        organization_id: "org-1",
        email: "foo@example.com",
        first_name: "Dwight",
        last_name: "Schrute",
      },
    })
  },
  "/users/get_building_roles": (req, res) => {
    res.status(200).json({
      user_building_roles: req.db.building_roles,
    })
  },
  "/users/list_organization_roles": (req, res) => {
    res.status(200).json({
      user_organization_roles: [
        {
          user_id: "user-1",
          organization_roles: ["org:superadmin"],
          can_view_all_buildings: true,
        },
      ],
    })
  },
  "/incidents/list": (req, res) => {
    res.status(200).json({
      incidents: [
        {
          incident_id: "incident-1",
          created_at: time(),
          incident_type: "device.low_battery",
          organization_id: "org-1",
          linked_account_id: null,
          device_id: "c2e5f00f-9efd-4661-af5a-b7e2e6317425",
          started_at: time(),
          ended_at: null,
          building_id: "e1741d59-61df-418e-a458-56cc1429b242",
          is_resolved: false,
        },
        {
          incident_id: "incident-2",
          created_at: new Date(Date.now() - ms("7d")).toISOString(),
          incident_type: "device.disconnected",
          organization_id: "org-1",
          linked_account_id: null,
          device_id: "79f6bf35-bca7-4c9c-99d0-0e82f188495c",
          started_at: new Date(Date.now() - ms("7d")).toISOString(),
          ended_at: new Date(Date.now() - ms("1d")).toISOString(),
          building_id: "e1741d59-61df-418e-a458-56cc1429b242",
          is_resolved: true,
        },
        {
          incident_id: "incident-3",
          created_at: time(),
          incident_type: "linked_account.disconnected",
          organization_id: "org-1",
          linked_account_id: "linked-account-dk",
          device_id: null,
          started_at: time(),
          ended_at: null,
          building_id: null,
          is_resolved: false,
        },
      ],
    })
  },
}
