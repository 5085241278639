import { PropsWithChildren } from "react"
import { SxProps, Typography } from "@mui/material"

import { colorDefaults } from "lib/mui"

interface Props {
  sx?: SxProps
}

const Caption = ({ sx, children, ...props }: PropsWithChildren<Props>) => (
  <Typography
    variant="body1"
    sx={{
      fontSize: "0.875rem",
      lineHeight: "1.1875rem",
      color: colorDefaults.text.textDefaultColor,
      ...sx,
    }}
    {...props}
  >
    {children}
  </Typography>
)

export default Caption
