import { SxProps, Typography, TypographyProps } from "@mui/material"

import type { Children } from "types"

interface Props extends Children, TypographyProps {
  maxWidth: string
  sx?: SxProps
}

const Overflow = ({ maxWidth, sx, children, ...props }: Props) => {
  return (
    <Typography
      sx={{
        maxWidth,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}

export default Overflow
