// Current user avatar

import { useOwnUser } from "hooks/api/useUser"

import Avatar, { Props as AvatarProps } from "components/media/Avatar"

import { generateColorFromString } from "lib/utils"

interface Props extends AvatarProps {}

const UserAvatar = ({ ...props }: Props) => {
  const { user } = useOwnUser()

  return (
    <Avatar color={generateColorFromString(user.full_name)} {...props}>
      {user.initials}
    </Avatar>
  )
}

export default UserAvatar
