import { uniq } from "lodash"
import pluralize from "pluralize"

import CategoryHeader from "./CategoryHeader"
import DeviceRow from "./DeviceRow"
import { Incident } from "./Incident"

interface Props {
  incidents: Incident[]
}

const DeviceIssues = ({ incidents }: Props) => {
  const building_ids = uniq(incidents.map((incident) => incident.building_id))

  return (
    <div className="modal-category">
      <CategoryHeader
        label="Devices"
        badge={incidents.length}
        caption={`${building_ids.length} ${pluralize(
          "building",
          building_ids.length
        )} affected`}
      />

      {incidents.map((incident) => (
        <DeviceRow key={incident.incident_id} incident={incident} />
      ))}
    </div>
  )
}

export default DeviceIssues
