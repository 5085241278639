import { MouseEventHandler } from "react"
import ms from "ms"

import { useSeamQuery } from "hooks/useSeamQuery"

import UserAvatar from "components/user/UserAvatar"

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Trigger = ({ onClick }: Props) => {
  const { data: incidents } = useSeamQuery(["incidents", "list"], (seam) =>
    seam.incidents.list({
      between: [
        // Last 30 days
        new Date(Date.now() - ms("30d")),
        new Date(),
      ],
    })
  )

  const hasIncidents = () => {
    if (typeof incidents === "undefined") return false
    return incidents.length > 0
  }

  return (
    <button onClick={onClick} className="bg-transparent">
      <UserAvatar notify={hasIncidents()} />
    </button>
  )
}

export default Trigger
