import InputUnstyled, { inputUnstyledClasses } from "@mui/base/InputUnstyled"
import { styled } from "@mui/system"

import { colorDefaults } from "lib/mui"

const Input = styled(InputUnstyled, {
  shouldForwardProp: (prop: string) =>
    !["fullWidth", "disablePaddingX", "nativeFocus", "inputRef"].includes(prop),
})<{
  fullWidth?: boolean
  disablePaddingX?: boolean
  nativeFocus?: boolean
}>(
  (props) => `
  border-radius: 8px;
  color: ${colorDefaults.text.textDefaultColor};
  background: ${colorDefaults.background.gray};
  border: 1px solid ${colorDefaults.background.gray};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: ${props.fullWidth ? "100%" : "min-content"};

  &.filled {
    background-color: white;
    border-color: ${colorDefaults.text.textGray3};
  }
  &:hover {
    background-color: ${colorDefaults.background.bgA};
    border-color: ${colorDefaults.text.textGray3};
  }
  &.focused, &${props.nativeFocus ? ":focus-within" : ".no-focus"} {
    background-color: white;
    border-color: ${colorDefaults.text.hyperlink};
    box-shadow: 0px 0px 0px 3px rgba(182, 242, 255, 0.4);
  }

  .${inputUnstyledClasses.input} {
    display: inline-block;
    font-size: 14px;
    font-family: inherit;
    font-weight: 400;
    border: none;
    outline: 0;
    background-color: transparent;
    width: ${props.fullWidth ? "100%" : "140px"};
    padding: 10px 8px;
    ${props.disablePaddingX ? "padding-left: 0;" : ""}
    ${props.disablePaddingX ? "padding-right: 0;" : ""}
  }
  `
)

export default Input
