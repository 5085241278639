import { useEffect, useState } from "react"

import { Props as ModalProps } from "components/modals/Modal"
import StackModal from "components/modals/StackModal"

import {
  AccessPassModalContext,
  AccessPassModalData,
  AccessPassModalState,
  defaultState,
} from "./context"
import DetailsScreen from "./screens/DetailsScreen"
import DeviceInfoScreen from "./screens/DeviceInfoScreen"
// Screens
import DeviceListScreen from "./screens/DeviceListScreen"
import PassCreatedScreen from "./screens/PassCreatedScreen"
import PassSharedScreen from "./screens/PassSharedScreen"
import ShareScreen from "./screens/ShareScreen"

import { Device } from "types"

interface Props extends Pick<ModalProps, "isOpen" | "onClose"> {
  device?: Device
}

const AccessPassCreateModal = ({ device, isOpen, ...props }: Props) => {
  const [state, setState] = useState<AccessPassModalState>({
    ...defaultState,
    device,
  })

  useEffect(() => {
    setState((state) => ({
      ...state,
      device,
    }))
  }, [device, isOpen])

  return (
    <StackModal<AccessPassModalData>
      OperatingContext={AccessPassModalContext}
      className="access-pass-create-modal"
      screens={{
        deviceList: DeviceListScreen,
        details: DetailsScreen,
        deviceInfo: DeviceInfoScreen,
        passCreated: PassCreatedScreen,
        shareScreen: ShareScreen,
        passShared: PassSharedScreen,
      }}
      sequence={[
        "deviceList",
        "details",
        "passCreated",
        "shareScreen",
        "passShared",
      ]}
      headerLabel="Create Access Pass"
      screenProps={{
        state,
        setState,
      }}
      onReset={() => setState(defaultState)}
      isOpen={isOpen}
      {...props}
    />
  )
}

export default AccessPassCreateModal
