import { QueryKey, UseQueryOptions } from "@tanstack/react-query"

import { useSeamQueryWithoutOrg } from "./useSeamQueryWithoutOrg"
import useSessionStore from "./useSessionStore"

import { SeamSpaceClient } from "lib/space-api-client/space-api-client"

export const useSeamQuery = <T extends Promise<Awaited<T>>>(
  queryKey: QueryKey,
  cb: (seam: SeamSpaceClient) => T,
  options: UseQueryOptions = {}
) => {
  const hasOrgId = useSessionStore((s) => Boolean(s.organization_id))

  return useSeamQueryWithoutOrg(queryKey, cb, {
    ...options,
    enabled: hasOrgId && (options.enabled ?? true),
  })
}
