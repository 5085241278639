import { kebabize } from "lib/utils"

interface Mods {
  [key: string]: string | boolean | number | undefined
}

/**
 *
 * @param {Mods} mods
 * @returns {string[]}
 * @note This is assumed to be placed into a call to classNames.
 *
 */
export const generateMods = (mods: Mods) => {
  const keys = Object.keys(mods)

  if (keys.length === 0) {
    return ""
  }

  return keys.map((key) => {
    const value = mods[key]

    if (value === true) {
      return kebabize(key)
    }

    if (!value) {
      return ""
    }

    return `${kebabize(key)}-${
      typeof value === "number" ? value : kebabize(value)
    }`
  })
}
