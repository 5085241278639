import { MouseEvent, useEffect, useRef } from "react"
import { AnimatePresence } from "framer-motion"

import useScrollLock from "hooks/useScrollLock"

import Animated from "components/motion/Animated"
import Keyboard from "components/utility/Keyboard"

import { variants } from "lib/constants/motion"

import { Children } from "types"

export interface Props extends Children {
  isOpen: boolean
  onClose: () => void
  className: string
  clickOutsideToClose?: boolean
  maxHeight?: string | number
}

const BaseModal = ({
  isOpen,
  onClose,
  className,
  clickOutsideToClose,
  maxHeight = "700px",
  children,
}: Props) => {
  const containerEl = useRef<HTMLDivElement>(null)
  const scroll = useScrollLock(containerEl)

  useEffect(() => {
    if (isOpen) {
      scroll.lock()

      // When modal opens, set focus so that user can use keyboard to navigate
      containerEl.current?.focus()
    } else {
      scroll.unlock()
    }
  }, [isOpen, scroll])

  return (
    <AnimatePresence>
      {isOpen && (
        <Animated
          variants={variants.fade.default}
          transition={{
            type: "tween",
            duration: 0.3,
          }}
          className={className}
          onClick={clickOutsideToClose ? onClose : undefined}
        >
          <Animated
            variants={variants.scale.partial}
            transition={{
              type: "spring",
              bounce: 0.45,
              duration: 0.5,
            }}
            className="inner"
            style={{
              maxHeight,
            }}
            onClick={
              clickOutsideToClose
                ? (ev: MouseEvent) => ev.stopPropagation()
                : undefined
            }
          >
            {children}
          </Animated>

          <Keyboard
            keys={["Escape"]}
            onKeyPress={onClose}
            handleFocusableElements
          />
        </Animated>
      )}
    </AnimatePresence>
  )
}

BaseModal.defaultProps = {
  clickOutsideToClose: true,
}

export default BaseModal
