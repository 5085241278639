import { useEffect, useState } from "react"
import Image from "next/image"
import { Box, Button, Link } from "@mui/material"
import dayjs from "dayjs"
import emailValidator from "email-validator"

import { useBuilding } from "hooks/api/useBuilding"
import { useOwnUser } from "hooks/api/useUser"

import CopyInput from "components/interactive/CopyInput"
import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import OptionalBlock from "components/modals/AccessPassCreateModal/fragments/OptionalBlock"
import ShareBlock from "components/modals/AccessPassCreateModal/fragments/share/ShareBlock"
import ShareBlockSectionLabel from "components/modals/AccessPassCreateModal/fragments/share/ShareBlockSectionLabel"
import ScreenCaption from "components/modals/components/ScreenCaption"
import Screen from "components/modals/StackModal/fragments/Screen"

import { getShortLink } from "lib/services/link-shortener"
import { LONG_DATE_AND_TIME_FORMAT } from "lib/utils"

const LINE_BREAK = "%0D%0A"

const ShareScreen = () => {
  const { user } = useOwnUser()
  const { building } = useBuilding()

  const context = useAccessPassModalContext()
  const { access_pass } = context.state

  const [email, setEmail] = useState("")
  const [emailEnabled, setEmailEnabled] = useState(true)

  const [shortLink, setShortLink] = useState<string | undefined>(undefined)

  useEffect(() => {
    context.setShowBackButton(false)
    context.setHeaderLabel("Share it!")
  }, [])

  const setAccessPassLink = async () => {
    const longPassURL = access_pass?.url ?? ""

    getShortLink(longPassURL)
      .then((res) => {
        if (res && res.status === 200 && res.data && res.data.data) {
          setShortLink(res.data.data.short_url)
        } else {
          setShortLink(longPassURL)
        }
      })
      .catch((err) => {
        console.error(err)
        setShortLink(longPassURL)
      })
  }

  useEffect(() => {
    if (!shortLink) setAccessPassLink()
  }, [shortLink])

  const isEmailValid = emailValidator.validate(email)

  const getEmailTimeSpecification = () => {
    if (access_pass?.does_not_end) {
      return "The key will be active until it is revoked."
    } else {
      return `The key will be active from ${dayjs(
        access_pass?.starts_at ?? access_pass?.created_at
      ).format(LONG_DATE_AND_TIME_FORMAT)} to ${dayjs(
        access_pass?.ends_at
      ).format(LONG_DATE_AND_TIME_FORMAT)}`
    }
  }

  const getEmailContent = () => {
    const body = [
      `You have received an access pass from ${user.full_name}.`,
      `The following link contains unlock buttons or pin codes for accessing the door(s) you've been granted access to:`,
      shortLink ?? "...",
      getEmailTimeSpecification(),
    ]

    if (building?.location.full_address_string) {
      const addr = [
        "The key is valid for the location:",
        building?.location?.full_address_string,
      ]
      body.push(addr.join(LINE_BREAK))
    }

    return {
      subject: `Seam: New Access Pass from ${user.full_name}`,
      body: Object.values(body)
        .map((value) => encodeURIComponent(value))
        .join(LINE_BREAK),
    }
  }

  const emailContent = getEmailContent()

  return (
    <Screen>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          mb: 2,
        }}
      >
        <ScreenCaption>Use this form to share your access pass.</ScreenCaption>

        <OptionalBlock
          label="Send email"
          onChange={(enabled) => setEmailEnabled(enabled)}
        >
          <ShareBlock label="Email address">
            <input
              name="name"
              id="name"
              type="text"
              className="s-input mnw-60 wfull"
              placeholder="someone@example.com"
              value={email}
              onChange={(ev) => setEmail(ev.target.value)}
            />
          </ShareBlock>
        </OptionalBlock>

        <ShareBlockSectionLabel
          icon={
            <Image src="/icons/link.svg" alt="Link" width={24} height={24} />
          }
          label="Link"
        />

        <ShareBlock label="Share this link">
          <CopyInput
            value={shortLink ?? "Generating..."}
            disabled={!shortLink}
          />
        </ShareBlock>
      </Box>

      <div className="buttons">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => context.onClose()}
        >
          Cancel
        </Button>
        <Button
          LinkComponent={Link}
          href={
            emailEnabled
              ? `mailto:${email}?subject=${encodeURIComponent(
                  emailContent.subject
                )}&body=${emailContent.body}`
              : undefined
          }
          variant="contained"
          onClick={() => {
            if (emailEnabled) {
              context.next()
            } else {
              context.onClose()
              context.reset()
            }
          }}
          className="button"
          disabled={
            (emailEnabled && (email.trim() === "" || !isEmailValid)) ||
            shortLink === undefined
          }
        >
          {emailEnabled ? "Send" : "Done"}
        </Button>
      </div>
    </Screen>
  )
}

export default ShareScreen
