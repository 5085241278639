import { useEffect, useRef, useState } from "react"
import { Menu, MenuItem } from "@mui/material"
import classNames from "classnames"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"

type TimeType = "hour" | "minute" | "meridiem"

const timeTypeMap: Record<TimeType, string> = {
  hour: "h",
  minute: "mm",
  meridiem: "A",
}

interface Props {
  type: TimeType
  rangePosition: "start" | "end"
  options: string[]
  value?: string
  onChange?: (value: string) => void
}

const TimeMenu = ({
  type,
  rangePosition,
  options,
  value: defaultValue,
  onChange,
}: Props) => {
  const ref = useRef<HTMLButtonElement>(null)
  const context = useAccessPassModalContext()

  const [isOpen, setIsOpen] = useState(false)
  const [time, setTime] = useState<string>(() => {
    const time = context.state[rangePosition]?.time

    if (time) {
      return time.format(timeTypeMap[type])
    }

    return defaultValue || options[0]
  })

  useEffect(() => {
    if (onChange) {
      onChange(time)
    }
  }, [time])

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <div className="relative">
      <button
        ref={ref}
        onClick={handleOpen}
        className={classNames(type, isOpen && "active")}
      >
        {time}
      </button>
      <Menu
        anchorEl={ref.current}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            maxHeight: 250,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              setTime(option)
              handleClose()
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default TimeMenu
