import { useMemo } from "react"
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import classNames from "classnames"
import pluralize from "pluralize"

import { useSeamQuery } from "hooks/useSeamQuery"
import useSessionStore from "hooks/useSessionStore"

import { useOrgSetup } from "providers/OrgSetupProvider"
import { PanelID, usePanels } from "providers/PanelsProvider"

import BaseModal, { Props as BaseModalProps } from "components/modals/BaseModal"
import { ModalProps } from "components/modals/Modal/Modal"

import { colorDefaults } from "lib/mui"
import { getItem, removeItem, setItem } from "lib/utils/storage"
import BuildingIcon from "assets/images/building-purple.svg"
import CheckGreenIcon from "assets/images/check-green.svg"
import DeviceIcon from "assets/images/device-purple.svg"
import StarIcon from "assets/images/stars-purple.svg"
import MemberIcon from "assets/images/user-purple.svg"

export const shouldShowModal = (organization_id: string) => {
  return Boolean(getItem(modalKey(organization_id)))
}

export const setShowModal = (organization_id: string, show: boolean) => {
  if (show) {
    setItem(modalKey(organization_id), "True")
  } else {
    removeItem(modalKey(organization_id))
  }
}

const modalKey = (organization_id: string) =>
  `panel:org_setup_modal:${organization_id}`

const columns = [
  {
    id: "buildings",
    icon: <BuildingIcon />,
    title: "Add buildings",
    status: "No buildings added yet",
    content:
      "Add buildings to your organization so you can control access to those buildings. Once you’ve added your linked devices to your buildings, you’ll be able to quickly grant building access to anyone. ",
    actionLabel: "Add a building",
  },
  {
    id: "devices",
    icon: <DeviceIcon />,
    title: "Link your devices",
    status: "No devices connected yet",
    content:
      "Link your external device accounts to add devices to your organization. After a device account is linked, add its devices to your buildings, so you can create access passes to those buildings.",
    actionLabel: "Add linked account",
  },
  {
    id: "members",
    icon: <MemberIcon />,
    title: "Invite members",
    status: "No members have joined",
    content:
      "If you want give others the ability to control access to your buildings, invite them to join your organization. Once your members set up their accounts, they’ll be able to see the organization whenever they sign in.",
    actionLabel: "Invite members",
  },
]

interface Props
  extends Omit<BaseModalProps, "className" | "onClose">,
    Pick<ModalProps, "onClose"> {}

const OrgSetupModal = ({ ...props }: Props) => {
  const organization_id = useSessionStore((s) => s.organization_id)
  const panels = usePanels()

  const { showingGetStartedButton, setShowingGetStartedButton } = useOrgSetup()

  const { data: buildings } = useSeamQuery(["buildings", "list"], (seam) =>
    seam.buildings.list({})
  )

  const getBuildingCount = () => {
    if (typeof buildings === "undefined") return 0
    return buildings.length
  }

  const { data: organizationSummaryCounts } = useSeamQuery(
    ["organizations", "get_count_summary", { organization_id }],
    async (seam) =>
      seam.organizations.get_count_summary({
        organization_id: organization_id!,
        between: [new Date(0), new Date()],
      })
    // NOTE: organization_id is guaranteed to be defined here
    //       because it is a precondition of useSeamQuery
  )

  const total_org_members = useMemo(() => {
    if (!organizationSummaryCounts) return 0

    return (
      organizationSummaryCounts.total_members +
      organizationSummaryCounts.total_admins +
      organizationSummaryCounts.total_super_admins
    )
  }, [organizationSummaryCounts])

  const { data: linked_accounts } = useSeamQuery(
    ["linked_accounts", "list"],
    (seam) => seam.linked_accounts.list({})
  )

  const total_devices = linked_accounts?.reduce(
    (acc, linked_account) => acc + linked_account.device_count,
    0
  )

  const closeThisAndOpen = (modal: PanelID) => {
    props.onClose()
    panels.set(modal, true)
  }

  const actionMap: Record<string, () => void> = {
    buildings: () => closeThisAndOpen("addBuildingModal"),
    devices: () => closeThisAndOpen("linkedAccountModal"),
    members: () => closeThisAndOpen("inviteMemberModal"),
  }

  const getStatusLine = (
    subject: string,
    count: number,
    zeroPredicate: string,
    predicate: string = "added",
    pluralizeZeroSubject: boolean = true
  ) => {
    if (count === 0)
      return `No ${subject}${pluralizeZeroSubject && "s"} ${zeroPredicate}`
    return `${count} ${pluralize(subject, count)} ${predicate}`
  }

  // TODO: Why is total_org_members - 1 sometimes -1 and sometimes 0?
  const getMemberCount = () => {
    const count = total_org_members - 1 // -1 for the current user
    if (count === -1) return 0
    return count
  }

  const statusMap: Record<string, string> = {
    buildings: getStatusLine("building", getBuildingCount(), "added yet"),
    devices: getStatusLine("device", total_devices ?? 0, "connected yet"),
    members: getStatusLine("member", getMemberCount(), "have joined", "joined"),
  }

  return (
    <BaseModal className="org-setup-modal" {...props}>
      <div className="wfull flex-c text-c mb-12">
        <h1 className="title">
          <StarIcon />
          Set up your new organization
        </h1>

        <div className="mw-96 mt-2">
          <p className="text">
            For new organizations, add buildings and connect some devices in
            order to start controlling access to buildings.
          </p>
        </div>
      </div>

      <div className="wfull grid grid-3 my-8">
        {columns.map((column) => (
          <div key={column.title} className="grid-block">
            <div className="inner-column">
              <div className="VStack">
                <div className="HStack">
                  {column.icon}
                  <div className="ml-2">
                    <h2 className="column-title">{column.title}</h2>
                  </div>
                </div>

                <div className="HStack my-3">
                  {!statusMap[column.id].startsWith("No") && (
                    <div className="mr-1">
                      <CheckGreenIcon />
                    </div>
                  )}
                  <p className="caption sm m-0 lh-1">{statusMap[column.id]}</p>
                </div>
                <p className="text">{column.content}</p>
              </div>

              <div className="mt-4">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={actionMap[column.id]}
                >
                  {column.actionLabel}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="footer">
        <div className={classNames("wfull", "SBStack")}>
          <div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showingGetStartedButton}
                    onChange={(e) =>
                      setShowingGetStartedButton(e.target.checked)
                    }
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  />
                }
                label="Show “Get started” button in navigation"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    color: colorDefaults.text.textGray1,
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1",
                    userSelect: "none",
                    ml: 0.5,
                  },
                }}
              />
            </FormGroup>
          </div>

          <button className="close-button" onClick={props.onClose}>
            Close
          </button>
        </div>
      </div>
    </BaseModal>
  )
}

export default OrgSetupModal
