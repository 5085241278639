import ExternalLink from "components/interactive/ExternalLink"
import InternalLink from "components/interactive/InternalLink"

const isInternalLink = (url: string) => {
  return url.startsWith("/") || url.startsWith("#")
}

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
}

const DynamicLink = ({ href, children, ...props }: Props) => {
  if (!href) return <></>
  if (isInternalLink(href)) {
    return (
      <InternalLink href={href} {...props}>
        {children}
      </InternalLink>
    )
  }

  return (
    <ExternalLink href={href} {...props}>
      {children}
    </ExternalLink>
  )
}

export default DynamicLink
