import { BsCheck } from "react-icons/bs"
import { MenuItem } from "@mui/material"
import classNames from "classnames"

import { generateMods } from "lib/utils"

interface Props {
  icon: React.ReactNode
  label: string
  caption: string
  isSelected?: boolean
  variant?: "default" | "danger"
  onClick?: () => void
}

const RoleItem = ({
  icon,
  label,
  caption,
  isSelected,
  variant,
  onClick,
}: Props) => {
  return (
    <MenuItem
      onClick={onClick}
      className={classNames(
        "role-item",
        generateMods({
          isSelected,
          variant,
        })
      )}
    >
      <div className="HStack">
        <div className="icon size-6">{icon}</div>
        <div className="VStack">
          <p className="label">{label}</p>
          <p className="caption">{caption}</p>
        </div>
      </div>
      <div>{isSelected && <BsCheck size="1.5rem" />}</div>
    </MenuItem>
  )
}

export default RoleItem
