import { createContext, useContext } from "react"

import { RoleID } from "components/menus/RoleMenu/RoleMenu"

interface User {
  role: RoleID
}

interface AuthContextProps {
  user: User
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

interface AuthProviderProps {
  children: JSX.Element
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  // TODO: Get the actual authenticated user.
  const user: User = {
    role: "org:superadmin",
  }

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuth must be used within <AuthProvider/>")
  }

  return context
}
