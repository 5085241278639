import { useEffect, useState } from "react"
import { Button } from "@mui/material"
import classNames from "classnames"

import useMyOrganization from "hooks/auth/useMyOrganization"
import useSessionStore from "hooks/useSessionStore"

import { useOrgSetup } from "providers/OrgSetupProvider"
import { usePanels } from "providers/PanelsProvider"

// import { useOrgSetup } from "providers/OrgSetupProvider"
import Logo from "components/brand/Logo"
import OrganizationMenu from "components/shell/Navigation/menus/OrganizationMenu/OrganizationMenu"

import AccountMenuDesktop from "./menus/AccountMenuDesktop"
import AccountMenuMobile from "./menus/AccountMenuMobile"

import { generateMods } from "lib/utils"
import StarIcon from "assets/images/stars-purple.svg"

const Navigation = () => {
  const [hasScrolled, setHasScrolled] = useState(false)
  const setup = useOrgSetup()
  const panels = usePanels()
  const hasOrganization = useSessionStore((s) => !!s.organization_id)

  const showOrgSetupModal = () => {
    panels.set("orgSetupModal", true)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 12) {
        setHasScrolled(true)
      } else {
        setHasScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const showingGetStartedButton =
    setup.showingGetStartedButton && hasOrganization

  return (
    <nav className={classNames("nav", generateMods({ hasScrolled }))}>
      <div className="container h-100p">
        <div className="nav-container">
          <div className="flex align-c flex-row">
            <Logo />
          </div>
          <div className="flex align-c flex-row">
            {showingGetStartedButton && (
              <Button
                variant="outlined"
                className="get-started-button"
                onClick={showOrgSetupModal}
              >
                <StarIcon />
                Get started
              </Button>
            )}
            <OrganizationMenu />
            <div className="ml-4">
              <AccountMenuDesktop />
              <AccountMenuMobile />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
