import Buttons from "components/modals/LinkedAccountModal/summaries/Buttons"
import Header from "components/modals/LinkedAccountModal/summaries/Header"

interface Props {
  onDone: () => void
  children: JSX.Element
}

const UnableToMapSummary = ({ onDone, children }: Props) => {
  return (
    <div className="screen">
      <Header className="my-28">
        <p>1 linked account was added.</p>
        {children}
      </Header>
      <Buttons onDone={onDone} />
    </div>
  )
}

export default UnableToMapSummary
