import Slide, { SlideProps } from "@mui/material/Slide"
import { SnackbarProvider as NotistackProvider, useSnackbar } from "notistack"

import Snackbar, {
  SnackbarProps,
} from "components/presentational/Snackbar/Snackbar"

interface Props {
  children: JSX.Element
}

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="up" />
}

const SnackbarProvider = ({ children }: Props) => {
  return (
    <NotistackProvider
      maxSnack={3}
      Components={{
        default: Snackbar,
        error: Snackbar,
        success: Snackbar,
        warning: Snackbar,
        info: Snackbar,
      }}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      classes={{
        root: "snackbar",
      }}
    >
      {children}
    </NotistackProvider>
  )
}

export const useShowSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  return ({
    message,
    ...otherProps
  }: Pick<SnackbarProps, "message"> &
    Partial<Omit<SnackbarProps, "isOpen" | "onClose">>) => {
    enqueueSnackbar(message, {
      close: (id: number) => closeSnackbar(id),
      autoHideDuration: 5000,
      ...otherProps,
    })
  }
}

export default SnackbarProvider
