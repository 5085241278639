import Image from "next/image"
import { Box, Skeleton } from "@mui/material"

import StatusPill from "components/data/StatusPill"

import { fetchBrandImageDetails } from "lib/brands"
import { DeviceType } from "lib/constants"

interface Props {
  deviceModel?: string
  deviceType: DeviceType
  online: boolean
}

const DeviceImage = ({ deviceModel, deviceType, online }: Props) => {
  const imageDetails = fetchBrandImageDetails(deviceType, deviceModel)

  if (!deviceType || online == undefined)
    return (
      <Skeleton
        sx={{
          background: "#EFF2F5",
          borderRadius: "16px",
          width: "100%",
          height: "187px",
        }}
        variant="rectangular"
      />
    )

  return (
    <Box
      sx={{
        width: "100%",
        height: "187px",
        background:
          "linear-gradient(180deg, #EFF2F5 0%, rgba(255, 255, 255, 0) 100%)",
        borderRadius: "16px",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "16px",
          left: "16px",
        }}
      >
        <StatusPill status={online ? "online" : "offline"} />
      </Box>

      <Image
        src={imageDetails.src}
        alt={imageDetails.alt}
        width={170}
        height={170}
        priority
      />
    </Box>
  )
}

export default DeviceImage
