import { Typography } from "@mui/material"
import { Box } from "@mui/system"

import type { Children } from "types"

const Title = ({ children }: Children) => {
  return (
    <Box
      sx={{
        padding: "1rem 0 0.5rem 0",
        borderBottom: "1px solid #EEEEEE",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "0.8125rem",
          fontWeight: 700,
          lineHeight: "1.125rem",
          letterSpacing: "6%",
          textTransform: "uppercase",
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

export default Title
