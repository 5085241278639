import { ReactElement } from "react"
import ReactDOMServer from "react-dom/server"

export const createButton = (icon?: ReactElement) => {
  const button = document.createElement("button")

  button.style.width = "30px"
  button.style.height = "30px"
  button.style.backgroundColor = "white"
  button.style.display = "flex"
  button.style.justifyContent = "center"
  button.style.alignItems = "center"

  if (icon) {
    button.innerHTML = ReactDOMServer.renderToStaticMarkup(icon)
  }

  return button
}
