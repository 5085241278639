// A tiny piece of UI that displays:
// - a green dot, when in a good state
// - a red x, when offline
// - a red circle with an exclamation mark, when in a bad state

import { BsCircleFill, BsXLg } from "react-icons/bs"
import { RiErrorWarningLine } from "react-icons/ri"
import { Box } from "@mui/material"

import { theme } from "lib/mui"
import { capitalize } from "lib/utils"
// import { IoMdBatteryDead } from "react-icons/io"
import BatteryLow from "assets/images/battery-low.svg"

interface Props {
  status: "online" | "offline" | "issue" | "low_battery"
  label?: string
  iconSize?: number | string
}

const StatusIndicator = ({ status, label, iconSize }: Props) => {
  if (!status) return null

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        color: status === "offline" ? theme.palette.error.light : undefined,
        gap: "4px",
        padding: 0,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "18px",
          width: "18px",
        }}
      >
        {status === "online" && (
          <BsCircleFill color={theme.palette.success.main} size={iconSize} />
        )}
        {status === "offline" && (
          <BsXLg color={theme.palette.error.light} size={iconSize} />
        )}
        {status === "issue" && (
          <RiErrorWarningLine
            color={theme.palette.error.light}
            size={iconSize}
          />
        )}
        {status === "low_battery" && (
          <BatteryLow width={iconSize} viewBox="0 0 29 29" />
        )}
      </Box>
      {capitalize(label || "")}
    </Box>
  )
}

StatusIndicator.defaultProps = {
  iconSize: "11.25px",
}

export default StatusIndicator
