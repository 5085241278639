import { AxiosAdapter, AxiosError } from "axios"
import { getReasonPhrase } from "http-status-codes"
import { cloneDeep, isEqual } from "lodash"

import useSessionStore from "hooks/useSessionStore"

import { getSeededDb } from "./get-seeded-db"
import { routes } from "./routes"

// To understand how adapters work, I recommend this README:
// https://github.com/axios/axios/tree/v1.x/lib/adapters
export const FakeAdapter: AxiosAdapter = async (config) => {
  let fakeEndpointFunc: any = Object.entries(routes).find(
    ([routePath]) => config.url === routePath
  )?.[1]

  if (!useSessionStore.getState().fake_db) {
    useSessionStore.setState({ fake_db: getSeededDb() })
  }

  // console.log(`got request for ${config.url}`)

  const shouldHaveResponseDelay =
    typeof window !== "undefined" &&
    window.location.href.includes("localhost:3000")

  if (shouldHaveResponseDelay) {
    await new Promise((resolve) => setTimeout(resolve, 0))
    // await new Promise((resolve) => resolve(void 0))
  }

  if (!fakeEndpointFunc) {
    throw new AxiosError(
      `FakeAdapter: No matching endpoint for "${config.url}"`,
      AxiosError.ERR_BAD_REQUEST,
      config,
      null
    )
  }

  const req = {
    db: cloneDeep(useSessionStore.getState().fake_db),
    method: config.method,
    body:
      config?.headers?.["Content-Type"] === "application/json"
        ? JSON.parse(config.data)
        : config.data,
    headers: config.headers,
    query: config.params,
  }
  let res: any = { statusCode: 200, headers: {} }
  res.status = (code: number) => {
    res.statusCode = code
    return res
  }
  res.json = (jsonBody: any) => {
    res.jsonBody = jsonBody
    return res
  }

  try {
    await fakeEndpointFunc(req, res)
  } catch (e: any) {
    throw new AxiosError(
      `FakeAdapter: Internal Endpoint Error: ${config.method} ${
        config.url
      }: ${e.toString()}`,
      AxiosError.ERR_BAD_REQUEST,
      config
    )
  }

  // NOTE: race condition here for simultaneous fake writes
  if (!isEqual(req.db, useSessionStore.getState().fake_db)) {
    useSessionStore.setState({ fake_db: req.db })
  }

  if (res.jsonBody.ok === undefined) {
    res.jsonBody.ok = true
  }

  const response = {
    data: res.jsonBody,
    status: res.statusCode,
    statusText: getReasonPhrase(res.statusCode),
    headers: res.headers,
    config,
  }

  if (config.validateStatus && !config.validateStatus(res.statusCode)) {
    throw new AxiosError(
      `FakeAdapter: Error ${config.method} ${config.url}: ${
        response.statusText
      }${response.data?.error ? `: ${response.data?.error}` : ""}`,
      AxiosError.ERR_BAD_REQUEST,
      config,
      null,
      response
    )
  }

  return response
}

export default FakeAdapter
