import { useQueryParams } from "hooks/url/useQueryParams"
import { useSeamQuery } from "hooks/useSeamQuery"

export const useBuilding = () => {
  // If we have the building_id in the route, we'll use that, otherwise we'll also
  // try to get the building from the device.
  const { building_id, device_id } = useQueryParams()

  const { data: device, isLoading: isLoadingDevice } = useSeamQuery(
    ["devices", "get", { device_id }],
    (seam) => seam.devices.get({ device_id }),
    { enabled: Boolean(device_id) }
  )

  const targetBuildingId = building_id || device?.building_id

  const { data: building, isLoading: isLoadingBuilding } = useSeamQuery(
    ["buildings", "get", { building_id: targetBuildingId }],
    (seam) => seam.buildings.get({ building_id: targetBuildingId! }),
    { enabled: Boolean(targetBuildingId) }
  )

  return { building, isLoading: isLoadingBuilding || isLoadingDevice }
}
