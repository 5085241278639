import { Box, SxProps } from "@mui/material"

import { icons } from "lib/constants/icons"

import type { Children } from "types"

interface Props extends Children {
  icon?: keyof typeof icons
  size?: string
  sx?: SxProps
}

const Icon = ({ icon, size, sx, children }: Props) => {
  return (
    <Box
      sx={{
        height: size || "1.25rem",
        width: size || "1.25rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      {icon && icons[icon]}
      {children}
    </Box>
  )
}

export default Icon
