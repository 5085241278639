import { DialogActions } from "@mui/material"
import Button from "@mui/material/Button"

interface Props {
  onDone: () => void
}

const Buttons = ({ onDone }: Props) => {
  return (
    <div className="buttons">
      <Button variant="contained" onClick={onDone}>
        Done
      </Button>
    </div>
  )
}

export default Buttons
