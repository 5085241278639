import Animated from "components/motion/Animated"

import type { Children, Direction } from "types"

interface Props extends Children {
  direction: Direction
}

const AnimatedPage = ({ direction, children }: Props) => {
  return (
    <Animated
      custom={direction}
      variants={{
        hidden: (direction: Direction) => ({
          opacity: 0,
          x: direction === "forwards" ? 32 : -32,
        }),
        visible: { opacity: 1, x: 0 },
        exit: (direction: Direction) => ({
          opacity: 0,
          x: direction === "forwards" ? -32 : 32,
        }),
      }}
      transition={{
        type: "tween",
        duration: 0.3,
      }}
      className="screen-wrap"
    >
      {children}
    </Animated>
  )
}

export default AnimatedPage
