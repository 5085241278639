// Methods for dealing with the data returned from Google's APIs

/**
 * Extracts attribution details from html_attribution given from a
 * google.maps.places.PlacePhoto object
 * @param html_attribution HTML string containing attribution details
 * @returns Attribution for the image in the form of "Google photo by John Doe"
 * @example
 * const html_attribution = "<a href="https://maps.google.com/xyz">John Doe</a>"
 * const attribution = extractAttribution(photo.html_attributions)
 * -> attribution = "Google photo by John Doe"
 */
export const extractGoogleImageAttribution = (html_attribution: string) => {
  if (!html_attribution || html_attribution === "") return "Google photo"

  const regex = />(.*?)<\/a>/
  const match = html_attribution.match(regex)
  const text = match ? match[1] : ""
  return `Google photo by ${text}`
}
