import { createContext, useMemo } from "react"
import { pick } from "lodash"

import useSessionStore from "hooks/useSessionStore"

import { FakeAdapter } from "lib/fake/fake-axios-adapter"
// TODO import from seamapi when Space Backend stabilizes
// import { SeamOS } from "seamapi"
import { SeamSpaceClient } from "lib/space-api-client/space-api-client"

export const SeamClientContext = createContext<SeamSpaceClient>(null as any)

export const FakeSeamClientProvider = ({ children }: { children: any }) => {
  const session = useSessionStore((s) =>
    pick(s, ["seam_user_session_token", "organization_id"])
  )
  const seam = new SeamSpaceClient({
    // Allow unauthenticated instantiation of seam client so we can call
    // out to create user sessions
    apiKey: session.seam_user_session_token ?? "unauthenticated",
    organizationId: session.organization_id,
  })

  seam.axios.defaults.adapter = FakeAdapter as any

  if (typeof window !== "undefined") {
    ;(window as any).seam = seam
  }

  return (
    <SeamClientContext.Provider value={seam}>
      {children}
    </SeamClientContext.Provider>
  )
}

export const SeamClientProvider = ({ children }: { children: any }) => {
  const session = useSessionStore((s) =>
    pick(s, ["seam_user_session_token", "organization_id"])
  )

  if (!process.env.NEXT_PUBLIC_SPACE_BACKEND_URL) {
    throw new Error(`NEXT_PUBLIC_SPACE_BACKEND_URL is not set!`)
  }

  const seam = useMemo(
    () =>
      new SeamSpaceClient({
        apiKey: session.seam_user_session_token,
        endpoint: process.env.NEXT_PUBLIC_SPACE_BACKEND_URL,
        organizationId: session.organization_id,
      }),
    [session]
  )

  return (
    <SeamClientContext.Provider value={seam}>
      {children}
    </SeamClientContext.Provider>
  )
}
