import { PropsWithChildren } from "react"
import { SxProps, Typography } from "@mui/material"

import { colorDefaults } from "lib/mui"

interface Props {
  sx?: SxProps
}

const ScreenCaption = ({ sx, children }: PropsWithChildren<Props>) => {
  return (
    <Typography
      sx={{
        fontSize: "0.875rem",
        lineHeight: "134%",
        color: colorDefaults.text.textGray1,
        mb: 2,
        ...sx,
      }}
    >
      {children}
    </Typography>
  )
}

export default ScreenCaption
