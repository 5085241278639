import { createContext, Dispatch, SetStateAction, useContext } from "react"

import { contextDefaults } from "components/modals/StackModal/defaults"
import { StackOperatingContext } from "components/modals/StackModal/types"

export interface OrganizationInfoModalState {
  organizationId?: string
  name: string
  mode: Mode
}

export interface OrganizationInfoModalData {
  state: OrganizationInfoModalState
  setState: Dispatch<SetStateAction<OrganizationInfoModalState>>
}

export const defaultState: OrganizationInfoModalState = {
  name: "",
  mode: "create",
}

type Mode = "create" | "edit"

export interface OrganizationInfoModalOperatingData {
  organization?: {
    organization_id: string
    name: string
  }
  mode: Mode
}

export const OrganizationInfoModalContext = createContext<
  StackOperatingContext<OrganizationInfoModalData>
>({
  ...contextDefaults,
  state: defaultState,
  setState: () => {},
})

export const useOrganizationInfoModalContext = () => {
  return useContext(OrganizationInfoModalContext)
}
