import { PropsWithChildren, useEffect } from "react"
import { useRouter } from "next/router"
import axios from "axios"
import { pick } from "lodash"

import { useSeamQueryWithoutOrg } from "hooks/useSeamQueryWithoutOrg"
import useSessionStore from "hooks/useSessionStore"

import RootTemplate, { RootTemplateProps } from "./RootTemplate"

interface Props {
  rootTemplateProps?: RootTemplateProps
}

/**
 * Verify that the user is authenticated. If not, redirect to the login page.
 */
const Authenticated = ({
  rootTemplateProps,
  children,
}: PropsWithChildren<Props>) => {
  const {
    seam_user_session_token,
    setPostAuthRedirectUrl,
    clearSession,
    setInviteEmail,
  } = useSessionStore((s) =>
    pick(s, [
      "seam_user_session_token",
      "setPostAuthRedirectUrl",
      "clearSession",
      "setInviteEmail",
    ])
  )

  const router = useRouter()

  const { error } = useSeamQueryWithoutOrg(
    ["users", "get_me"],
    (seam) => seam.axios.get("/internal/users/get_me"),
    {
      enabled: !!seam_user_session_token,
    }
  )

  useEffect(() => {
    if (
      !seam_user_session_token ||
      (axios.isAxiosError(error) && error.response?.status === 401)
    ) {
      // Pass any invite email along to the login page
      const email = router.query.invite_email as string | undefined

      if (email) {
        setInviteEmail(email)
      }

      clearSession()
      setPostAuthRedirectUrl(router.asPath)
      router.push("/login")
    }
  }, [error, seam_user_session_token])

  if (!seam_user_session_token) {
    return null
  }

  return <RootTemplate {...rootTemplateProps}>{children}</RootTemplate>
}

export default Authenticated
