import React, { forwardRef } from "react"
import FormControlUnstyled, {
  FormControlUnstyledProps,
  FormControlUnstyledState,
} from "@mui/base/FormControlUnstyled"
import classNames from "classnames"
import { isFunction } from "lodash"

import FormHelperText from "components/form/FormHelperText"
import Input from "components/form/Input"
import InputLabel from "components/form/InputLabel"

import { generateMods } from "lib/utils"

export interface TextInputProps extends FormControlUnstyledProps {
  placeholder?: string
  fullWidth?: boolean
  "aria-label"?: string
  focused?: boolean
  startAdornment?:
    | JSX.Element
    | ((state: FormControlUnstyledState) => JSX.Element)
  endAdornment?:
    | JSX.Element
    | ((state: FormControlUnstyledState) => JSX.Element)
  label?: string
  labelHelperText?: string
  helperText?: string
}

const TextInput = forwardRef(
  (
    {
      placeholder,
      fullWidth,
      startAdornment,
      endAdornment,
      label,
      labelHelperText,
      helperText,
      "aria-label": ariaLabel,
      focused,
      ...props
    }: TextInputProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const hasAdornment = Boolean(startAdornment) || Boolean(endAdornment)

    return (
      <FormControlUnstyled defaultValue="" {...props}>
        {(state: FormControlUnstyledState) => {
          const { error, filled } = state
          return (
            <>
              {label && (
                <InputLabel
                  error={error}
                  altClassName={
                    typeof labelHelperText === "string" ? "mb-0" : "mb-2"
                  }
                >
                  {label}
                </InputLabel>
              )}
              {labelHelperText && (
                <FormHelperText
                  error={state.error}
                  sx={{
                    mb: 1,
                    mt: 0,
                  }}
                >
                  {labelHelperText}
                </FormHelperText>
              )}
              <Input
                ref={ref}
                className={classNames(
                  generateMods({ focused: state.focused || focused, filled })
                )}
                placeholder={placeholder}
                fullWidth={fullWidth}
                disablePaddingX={hasAdornment}
                aria-label={ariaLabel}
                startAdornment={
                  isFunction(startAdornment)
                    ? startAdornment(state)
                    : startAdornment
                }
                endAdornment={
                  isFunction(endAdornment) ? endAdornment(state) : endAdornment
                }
              />
              {helperText && (
                <FormHelperText error={state.error}>
                  {helperText}
                </FormHelperText>
              )}
            </>
          )
        }}
      </FormControlUnstyled>
    )
  }
)

TextInput.displayName = "TextInput"

export default TextInput
