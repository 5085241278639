import { useCallback, useEffect } from "react"
import { Checkbox } from "@mui/material"
import dayjs from "dayjs"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import DatetimePicker from "components/modals/AccessPassCreateModal/fragments/TimingBlock/DatetimePicker"

import { colorDefaults } from "lib/mui"
import { validateDateAndTimeRange } from "lib/utils"
import { roundUp } from "lib/utils/math"

interface Props {
  timezone: string
}

const TimingBlock = ({ timezone }: Props) => {
  const context = useAccessPassModalContext()

  useEffect(() => {
    if (context.state.customTimeEnabled) {
      const start = context.state.start
      const end = context.state.end

      context.setState((data) => ({
        ...data,
        isCustomTimeValid: start && end && validateDateAndTimeRange(start, end),
      }))
    }
  }, [context.state.customTimeEnabled, context.state.start, context.state.end])

  const toggle = useCallback(() => {
    context.setState((data) => {
      const customTimeEnabled = !context.state.customTimeEnabled

      const start = customTimeEnabled
        ? {
            date: dayjs(),
            time: dayjs().minute(roundUp(dayjs().minute(), 5)),
          }
        : undefined

      const end = customTimeEnabled
        ? {
            date: dayjs().add(1, "day"),
            time: dayjs().minute(roundUp(dayjs().minute(), 5)),
          }
        : undefined

      return {
        ...data,
        customTimeEnabled,
        start,
        end,
      }
    })
  }, [context.state.customTimeEnabled])

  return (
    <div className="device-time-picker">
      <div className="VStack">
        <div className="SBStack wfull">
          <div className="HStack">
            <Checkbox
              color="primary"
              checked={Boolean(context.state.customTimeEnabled)}
              onChange={toggle}
            />
            <label className="caption m-0 ml-1 no-select" onClick={toggle}>
              Specify dates & times
            </label>
          </div>
          <div className="HStack">
            {context.state.customTimeEnabled && (
              <p className="picker-caption" title={timezone}>
                All times in {timezone}
              </p>
            )}
          </div>
        </div>
        <div className="VStack align-c wfull mt-4 pb-2">
          {!context.state.customTimeEnabled ? (
            <p className="picker-caption italic">
              This pass will be active 24/7 until it is manually disabled.
            </p>
          ) : (
            <>
              {!context.state.isCustomTimeValid && (
                <div className="wfull justify-l mb-2">
                  <p
                    className="picker-caption"
                    style={{ color: colorDefaults.status.redAlert }}
                  >
                    End time must be after Start time
                  </p>
                </div>
              )}

              <div className="tracks wfull grid grid-2 gap-6">
                <div className="track">
                  <DatetimePicker rangePosition="start" />
                </div>
                <div className="track">
                  <DatetimePicker rangePosition="end" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TimingBlock
