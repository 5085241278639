import { useEffect, useState } from "react"
import { useIntercom } from "react-use-intercom"
import { Button } from "@mui/material"

import { useSeamMutation } from "hooks/useSeamMutation"
import { useSeamQuery } from "hooks/useSeamQuery"

import { LoginPortal } from "types"

interface Props {
  onCreate: (loginPortal: LoginPortal) => void
  onCancel: () => void
  onNext: () => void
}

const LinkAccountStep = ({ onCreate, onCancel, onNext }: Props) => {
  const loginPortal = useCreateLoginPortal()
  const didComplete = useDidComplete()

  // Notify parent whenever a login portal is created
  useEffect(() => {
    if (!loginPortal) {
      return
    }

    onCreate(loginPortal)
  }, [loginPortal, onCreate])

  if (!loginPortal) {
    return null
  }

  return (
    <>
      <iframe
        style={{
          border: "none",
          minHeight: "650px", // Content size from seam-connect
          height: "100%",
          width: "100%",
        }}
        src={loginPortal.view_url}
      />
      {didComplete && (
        <div
          className="buttons"
          style={{
            backgroundColor: "#F7FAFC!important", // Inherited from webview
          }}
        >
          <Button variant="contained" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onNext}>
            Next
          </Button>
        </div>
      )}
    </>
  )
}

function useCreateLoginPortal() {
  const [value, setValue] = useState<LoginPortal | null>(null)
  const intercom = useIntercom()

  const { data: deviceProviders } = useSeamQuery(
    ["devices", "list_device_providers"],
    (seam) =>
      seam.devices.list_device_providers({ provider_category: "stable" })
  )

  const { isLoading: isCreating, mutate: createLoginPortal } = useSeamMutation(
    async (seam) => {
      const loginPortal = await seam.login_portals.create({
        accepted_providers: deviceProviders?.map(
          (deviceProvider) => deviceProvider.device_provider_name
        ),
      })
      intercom.trackEvent("Login Portal Created")
      setValue(loginPortal)
    }
  )

  // Create login portal on mount
  useEffect(() => {
    // need list of providers before we can create a login portal
    if (!deviceProviders) {
      return
    }

    if (isCreating) {
      return
    }

    if (value) {
      return
    }

    createLoginPortal(undefined)
  }, [createLoginPortal, value, isCreating, deviceProviders])

  return value
}

function useDidComplete() {
  const [didComplete, setDidComplete] = useState(false)

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      if (!event.data.current_pane_name) {
        return
      }

      if (didComplete) {
        return
      }

      if (event.data.current_pane_name === "finished_pane") {
        setDidComplete(true)
      }
    }

    window.addEventListener("message", handler)

    return () => {
      window.removeEventListener("message", handler)
    }
  }, [])

  return didComplete
}

export default LinkAccountStep
