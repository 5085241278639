import { createContext, useContext, useEffect, useState } from "react"

import { removeItem, setItem } from "lib/utils/storage"

interface OrgSetupContextProps {
  showingGetStartedButton: boolean
  setShowingGetStartedButton: (showing: boolean) => void
}

const OrgSetupContext = createContext<OrgSetupContextProps | undefined>(
  undefined
)

interface OrgSetupProviderProps {
  children: JSX.Element | JSX.Element[]
}

const hideGetStartedButtonKey = "panel:org_setup_modal:hide_get_started_button"

const setHideGetStartedButton = (hide: boolean) => {
  if (hide) {
    setItem(hideGetStartedButtonKey, "True")
  } else {
    removeItem(hideGetStartedButtonKey)
  }
}

export const OrgSetupProvider = ({ children }: OrgSetupProviderProps) => {
  const [showingGetStartedButton, setShowingGetStartedButton] = useState(true)

  // On changing whether to show the 'Get Started' button, we'll want to
  // persist it, so that it isn't shown again on next page load.
  useEffect(() => {
    setHideGetStartedButton(!showingGetStartedButton)
  }, [showingGetStartedButton])

  return (
    <OrgSetupContext.Provider
      value={{ showingGetStartedButton, setShowingGetStartedButton }}
    >
      {children}
    </OrgSetupContext.Provider>
  )
}

export const useOrgSetup = () => {
  const context = useContext(OrgSetupContext)
  if (context === undefined) {
    throw new Error("useOrgSetup must be used within a <OrgSetupProvider/>")
  }

  return context
}
