import { ButtonBase, ButtonBaseProps } from "@mui/material"

import { colorDefaults } from "lib/mui"

import { Children } from "types"

interface Props extends Children, ButtonBaseProps {
  iconAlignment?: "left" | "right"
}

const ImagePickerAction = ({
  iconAlignment = "left",
  children,
  ...props
}: Props) => {
  return (
    <ButtonBase
      sx={{
        height: "2rem",
        padding:
          iconAlignment === "left"
            ? "0.5rem 0.5rem 0.5rem 0.25rem"
            : "0.5rem 0.25rem 0.5rem 0.5rem",
        backgroundColor: colorDefaults.text.textDefaultColor,
        borderRadius: "0.5rem",
        color: "white",
        fontSize: "0.875rem",
        opacity: 0.85,
        cursor: "pointer",
        transition: "var(--quick-1) var(--expressive)",

        "&:hover": {
          opacity: 1,
        },
      }}
      {...props}
    >
      {children}
    </ButtonBase>
  )
}

export default ImagePickerAction
