export const DEVICE_TYPES = {
  AKUVOX_LOCK: "akuvox_lock",
  AUGUST_LOCK: "august_lock",
  BRIVO_ACCESS_POINT: "brivo_access_point",
  BUTTERFLYMX_PANEL: "butterflymx_panel",
  CONTROLBYWEB_LOCK: "controlbyweb_lock",
  DOORKING_LOCK: "doorking_lock",
  GENIE_DOOR: "genie_door",
  IGLOOHOME_LOCK: "igloohome_lock",
  KWIKSET_LOCK: "kwikset_lock",
  LINEAR_LOCK: "linear_lock",
  LOCKLY_LOCK: "lockly_lock",
  NUKI_LOCK: "nuki_lock",
  SALTO_LOCK: "salto_lock",
  SCHLAGE_LOCK: "schlage_lock",
  SEAM_RELAY: "seam_relay",
  SMARTTHINGS_LOCK: "smartthings_lock",
  MY_2N: "two_n_intercom",
  YALE_LOCK: "yale_lock",
} as const

export type DeviceType = (typeof DEVICE_TYPES)[keyof typeof DEVICE_TYPES]
