import { useEffect } from "react"
import { Box } from "@mui/material"

import Screen from "components/modals/StackModal/fragments/Screen"

import { useBuildingInfoModalContext } from "../context"
import SelectableImage from "../fragments/SelectableImage"

const ImagesScreen = () => {
  const context = useBuildingInfoModalContext()

  useEffect(() => {
    context.setShowBackButton(true)
    context.setOnBackButtonClickGoTo("buildingInfo")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Screen>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: 2,
          pb: 4,
        }}
      >
        {context.state.images.map((image) => (
          <SelectableImage key={image.url} image={image} />
        ))}
      </Box>
    </Screen>
  )
}

export default ImagesScreen
