import { SvgIcon } from "@mui/material"

import Tile from "components/cards/Tile"

import BatteryGood from "assets/images/battery-good.svg"
import BatteryLow from "assets/images/battery-low.svg"
import PlugOutline from "assets/images/plug-outline.svg"

export interface Props {
  batteryLevel: number | "none"
  isPowered?: boolean
}

const iconStyle = {
  width: "36px",
  height: "36px",
}

export const BatteryTile = ({ batteryLevel, isPowered }: Props) => {
  if (isPowered) {
    return (
      <Tile
        value={<SvgIcon component={PlugOutline} sx={iconStyle} />}
        label="Powered"
      />
    )
  }
  if (batteryLevel > 0.31) {
    return <Tile value={<BatteryGood />} label="Battery Good" />
  }
  return <Tile value={<BatteryLow />} label="Battery Low" />
}

export default BatteryTile
