import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"

import Row from "./Row"

const Accordion = () => {
  const context = useAccessPassModalContext()

  return (
    <div className="device-accordion">
      {Array.isArray(context.state.devices) &&
        context.state.devices.map((device) => (
          <Row key={device.device_id} device={device} />
        ))}
    </div>
  )
}

export default Accordion
