import MuiChip, { ChipProps } from "@mui/material/Chip"
import { styled } from "@mui/material/styles"

export interface Props extends Omit<ChipProps, "color"> {
  error?: boolean
}

const StyledChip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== "error",
})<{ error?: boolean }>(({ error }) => {
  const color = error ? "rgba(251, 242, 242, 1)" : "rgba(7, 143, 219, 0.16)"

  return `
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 5px;
    background-color: ${color};
    font-size: 0.875rem;

    .adornment {
      margin-right: 0.5rem;
  
       i {
        color: rgba(28, 27, 31, 1);
        opacity: 0.4;
      }
    }

    // Set the hover color explicitly to override MUI's default of 
    // a color change on hover when a chip is delete-able.
    &:hover {
      background-color: ${color};
    }
`
})

const Chip = (props: Props) => <StyledChip {...props} />

export default Chip
