import { useEffect, useState } from "react"

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"

import { ModalProps } from "components/modals/Modal/Modal"
import StackModal from "components/modals/StackModal"

import {
  BuildingInfoModalContext,
  BuildingInfoModalData,
  BuildingInfoModalOperatingData,
  BuildingInfoModalState,
  defaultState,
} from "./context"
import BuildingInfoScreen from "./screens/BuildingInfoScreen"
import ImagesScreen from "./screens/ImagesScreen"

interface Props extends Pick<ModalProps, "isOpen" | "onClose"> {
  operatingData: BuildingInfoModalOperatingData
}

const BuildingInfoModal = ({ operatingData, ...props }: Props) => {
  const [state, setState] = useState<BuildingInfoModalState>(defaultState)

  const { placesService } = usePlacesService({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? "",
  })

  useEffect(() => {
    if (operatingData.mode === "create" || !operatingData?.building) return
    const placeId = operatingData?.building?.location?.ext_google_place_id
    if (!placeId) {
      setState({
        building_id: operatingData?.building?.building_id,
        name: operatingData?.building?.name || "",
        location: undefined,
        images: [],
        selectedImage: {
          url: operatingData?.building?.image_url ?? "/public/placeholder.png",
          alt: "Google photo",
        },
        mode: operatingData.mode,
      })
    } else {
      placesService?.getDetails({ placeId }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setState({
            building_id: operatingData?.building?.building_id,
            name: operatingData?.building?.name || "",
            location: (place as google.maps.places.PlaceResult) || undefined,
            images: [
              {
                url:
                  operatingData?.building?.image_url ??
                  "/public/placeholder.png",
                alt: "Google photo",
              },
            ],
            selectedImage: {
              url:
                operatingData?.building?.image_url ?? "/public/placeholder.png",
              alt: "Google photo",
            },
            mode: operatingData.mode,
          })
        }
      })
    }
  }, [operatingData])

  return (
    <StackModal<BuildingInfoModalData>
      OperatingContext={BuildingInfoModalContext}
      className="building-info-modal"
      screens={{
        buildingInfo: BuildingInfoScreen,
        images: ImagesScreen,
      }}
      sequence={["buildingInfo"]}
      headerLabel={
        operatingData.mode === "create" ? "Add new building" : "Edit building"
      }
      screenProps={{
        state,
        setState,
      }}
      onReset={() => setState(defaultState)}
      {...props}
    />
  )
}

export default BuildingInfoModal
