import { createButton } from "./button"

import { icons } from "lib/constants/icons"

const createZoomButton = (map: google.maps.Map, type: "in" | "out") => {
  const button = createButton(type === "in" ? icons.plus : icons.minus)

  button.addEventListener("click", () =>
    map.setZoom((map.getZoom() ?? 0) + (type === "in" ? 1 : -1))
  )
  return button
}

export const createZoomControls = (map: google.maps.Map) => {
  const container = document.createElement("div")

  container.style.display = "flex"
  container.style.flexDirection = "column"
  container.style.margin = "8px"
  container.style.backgroundColor = "white"
  container.style.boxShadow = "0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
  container.style.borderRadius = "0.5rem"
  container.style.overflow = "hidden"

  const zoomIn = createZoomButton(map, "in")
  const zoomOut = createZoomButton(map, "out")

  container.appendChild(zoomIn)
  container.appendChild(zoomOut)

  return container
}
