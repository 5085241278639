import { DEVICE_TYPES } from "lib/constants"

import { AccessCode, UnmanagedAccessCode } from "types"

const getRandomIntegerInRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const time = (days?: number) => {
  let date = new Date()
  if (days != undefined) {
    date.setDate(date.getDate() + days)
    date.setHours(
      getRandomIntegerInRange(0, 23),
      getRandomIntegerInRange(0, 59)
    )
  }
  return date.toISOString()
}

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const padNumbers = (num: number, size: number) => {
  let numberString = num.toString()
  while (numberString.length < size) numberString = "0" + numberString
  return numberString
}

const BuildingNames: Array<string> = [
  "Azure Apartments",
  "Bay View Lofts",
  "Birchwood Apartments",
  "Sunset Lofts",
  "Castle Apartments",
  "North Beach Apartments",
  "350 Union St Apartments",
  "The Park Apartments",
  "Union Parkside",
  "1355 Euclid Ave",
  "Mission Bay Courtyard",
  "Bridgeview Suites",
  "Infinity Apartments",
  "The Edge",
  "Rockridge Apartments",
  "South End Apartments",
  "Maple Lofts",
  "Oakridge Apartments",
]

export const ExampleBuilding = (index: number) => {
  const buildingName = BuildingNames[index]
  return {
    building_id: uuid(),
    organization_id: "org-1",
    owner_id: uuid(),
    created_at: time(),
    name: buildingName,
    timezone: "America/Los_Angeles",
    ...({
      active_access_pass_count: getRandomIntegerInRange(0, 100),
      device_count: getRandomIntegerInRange(0, 100),
      device_issues_count: 0,
      low_battery_issues_count: 0,
      offline_account_issues_count: 0,
      recent_event_count: getRandomIntegerInRange(0, 20),
      issue_count: 0,
    } as any),
  }
}

export const ExampleBuildingWithIssues = (index: number) => {
  const device_issues_count = Math.floor(Math.random() * 3)
  const low_battery_issues_count = Math.floor(Math.random() * 3)
  const offline_account_issues_count = Math.floor(Math.random() * 3)

  const buildingName = BuildingNames[index]
  return {
    building_id: uuid(),
    organization_id: "org-1",
    owner_id: uuid(),
    created_at: time(),
    name: buildingName,
    timezone: "America/Los_Angeles",
    active_access_pass_count: Math,
    ...({
      active_access_pass_count: getRandomIntegerInRange(0, 100),
      device_count: getRandomIntegerInRange(0, 100),
      device_issues_count,
      low_battery_issues_count,
      offline_account_issues_count,
      recent_event_count: getRandomIntegerInRange(0, 20),
      issue_count:
        device_issues_count +
        low_battery_issues_count +
        offline_account_issues_count,
    } as any),
  }
}

// Create a list of example buildings of length variable `count`
// const createExampleBuildings = (count: number) => {
//   return Array(count)
//     .fill(0)
//     .map((v, i) => {
//       return getRandomIntegerInRange(0, 100) > 90
//         ? ExampleBuildingWithIssues(i)
//         : ExampleBuilding(i)
//     })
// }

export const ExampleAugustDevice = (
  index: number,
  building_id: string | undefined
) => ({
  device_id: uuid(),
  name: `Room ${padNumbers(index - 2, 3)}`,
  linked_account_id: "linked-account-au",
  device_type: DEVICE_TYPES.AUGUST_LOCK,
  created_at: time(),
  properties: {
    battery_level: index == 8 || index == 16 ? 0.2 : 0.9,
    is_powered: false,
    locked: true,
    online: index % 50 != 1,
    august_metadata: {
      _skuNumber: "AUG-041",
    },
  },
  building_id,
  ...({
    access_pass_count: getRandomIntegerInRange(0, 30),
    active_access_pass_count: getRandomIntegerInRange(0, 10),
    issue_count: index,
    offline_account: false,
    recent_event_count: index,
  } as any),
  capabilities_supported: ["lock", "access_code"],
})

export const ExampleDoorKingDevice = (
  index: number,
  building_id: string | undefined
) => ({
  device_id: uuid(),
  name: `Lobby Door ${index + 1} (Callbox)`,
  linked_account_id: "linked-account-dk",
  device_type: DEVICE_TYPES.DOORKING_LOCK,
  created_at: time(),
  properties: {
    battery_level: "none",
    is_powered: true,
    locked: true,
    online: true,
  },
  building_id,
  ...({
    access_pass_count: getRandomIntegerInRange(0, 50),
    active_access_pass_count: getRandomIntegerInRange(0, 40),
    issue_count: index,
    offline_account: false,
    recent_event_count: index,
  } as any),
  capabilities_supported: ["lock", "access_code"],
})

export const Example2NDevice = (
  index: number,
  building_id: string | undefined
) => ({
  device_id: uuid(),
  name: `Maintenance room`,
  linked_account_id: "linked-account-2n",
  device_type: DEVICE_TYPES.MY_2N,
  created_at: time(),
  properties: {
    battery_level: 0.9,
  },
  building_id,
  ...({
    access_pass_count: getRandomIntegerInRange(0, 30),
    active_access_pass_count: getRandomIntegerInRange(0, 10),
    issue_count: index,
    offline_account: false,
    recent_event_count: index,
  } as any),
  capabilities_supported: ["lock", "access_code"],
})

const SALTO_WALL_READER_NAMES = [
  "Lobby Door (Card Reader)",
  "Gym",
  "Rooftop Lounge",
  "Swimming Pool",
  "Garage",
]

export const ExampleSaltoWallReaderDevice = (
  index: number,
  building_id: string | undefined
) => ({
  device_id: uuid(),
  name: SALTO_WALL_READER_NAMES[index],
  linked_account_id: "linked-account-sa",
  device_type: DEVICE_TYPES.SALTO_LOCK,
  created_at: time(),
  properties: {
    battery_level: "none",
    is_powered: true,
    online: true,
    salto_metadata: {
      lock_type: "wall_reader",
      locked_state: "locked",
    },
  },
  building_id: building_id || ExampleBuildings[0]?.building_id,
  ...({
    access_pass_count: getRandomIntegerInRange(0, 30),
    active_access_pass_count: getRandomIntegerInRange(0, 10),
    issue_count: index,
    offline_account: false,
    recent_event_count: getRandomIntegerInRange(0, 10),
  } as any),
  capabilities_supported: ["lock", "access_code"],
})

export const createExampleDevices = (
  count: number,
  building_id: string | undefined
) => {
  const devices = []
  devices.push(ExampleDoorKingDevice(0, building_id))
  devices.push(ExampleSaltoWallReaderDevice(1, building_id))
  devices.push(ExampleSaltoWallReaderDevice(2, building_id))
  for (let i = 3; i < count; i++) {
    devices.push(ExampleAugustDevice(i, building_id))
  }
  devices.push(Example2NDevice(count, building_id))
  return devices
}

const ACCESS_PASS_NAMES = [
  "United Plumbing",
  "Emergency Services",
  "Alexandra Aoun",
  "Belle Blue",
  "Callie Class",
  "Dylan Dancer",
  "Ethan Earhorn",
  "USPS Delivery",
]

export const ExampleUpcomingAccessPass = (
  index: number,
  device_id: string,
  building_id: string
) => ({
  access_pass_id: uuid(),
  name: ACCESS_PASS_NAMES[index % ACCESS_PASS_NAMES.length],
  created_at: time(),
  code: "1234",
  type: "timebound",
  device_id: device_id,
  building_id: building_id,
  starts_at: time(1),
  ends_at: time(2),
  ...({
    last_used_at: time(),
    recently_used_count: 1,
    used_count: 0,
  } as any),
})

export const ExampleActiveAccessPass = (
  index: number,
  device_id: string,
  building_id: string
) => ({
  access_pass_id: uuid(),
  name: ACCESS_PASS_NAMES[index % ACCESS_PASS_NAMES.length],
  created_at: time(),
  code: "1234",
  device_id: device_id,
  building_id: building_id,
  type: "timebound",
  starts_at: time(-1),
  ends_at: time(Math.floor(Math.random() * 10) + 1),
  ...{
    last_used_at: time(-1 * Math.floor(Math.random() * 3)),
    recently_used_count: 1,
    used_count: getRandomIntegerInRange(0, 10),
  },
})

export const ExampleExpiredAccessPass = (
  index: number,
  device_id: string,
  building_id: string
) => {
  return {
    access_pass_id: uuid(),
    name: ACCESS_PASS_NAMES[index % ACCESS_PASS_NAMES.length],
    created_at: time(),
    code: "1234",
    device_id: device_id,
    building_id: building_id,
    type: "timebound",
    starts_at: time(-4),
    ends_at: time(-2),
    ...({
      last_used_at: time(getRandomIntegerInRange(-6, -2)),
      recently_used_count: 1,
      used_count: getRandomIntegerInRange(0, 10),
    } as any),
  }
}

export const ExampleAugustAccount = {
  linked_account_id: "linked-account-au",
  organization_id: "org-1",
  login_portal_id: "login-portal-0",
  user_identifier: {
    email: "eahab@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-0",
  created_at: "2021-07-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 846,
  account_type: "august",
  did_complete_first_sync: true,
}

export const ExampleSmartThingsAccount = {
  linked_account_id: "linked-account-st",
  organization_id: "org-1",
  login_portal_id: "login-portal-1",
  user_identifier: {
    email: "joni@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-1",
  created_at: "2021-08-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 0,
  account_type: "smartthings",
  did_complete_first_sync: true,
}

export const ExampleSaltoAccount = {
  linked_account_id: "linked-account-sa",
  organization_id: "org-1",
  login_portal_id: "login-portal-2",
  user_identifier: {
    email: "kelly@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-2",
  created_at: "2021-09-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 34,
  account_type: "doorking",
  did_complete_first_sync: true,
}

export const ExampleDoorKingAccount = {
  linked_account_id: "linked-account-dk",
  organization_id: "org-1",
  login_portal_id: "login-portal-3",
  user_identifier: {
    email: "kelly@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-3",
  created_at: "2021-09-02T00:00:00Z",
  errors: [
    {
      message: "account_disconnected",
      error_code: "account_disconnected",
      created_at: time(),
    },
  ],
  warnings: [],
  device_count: 17,
  account_type: "doorking",
  did_complete_first_sync: true,
}

export const ExampleMy2NAccount = {
  linked_account_id: "linked-account-2n",
  organization_id: "org-1",
  login_portal_id: "login-portal-0",
  user_identifier: {
    email: "2n@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-0",
  created_at: "2021-07-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 846,
  account_type: "my_2n",
  did_complete_first_sync: true,
}

export const ExampleKwiksetAccount = {
  linked_account_id: "linked-account-ks",
  organization_id: "org-1",
  login_portal_id: "login-portal-0",
  user_identifier: {
    email: "jane@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-0",
  created_at: "2021-07-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 846,
  account_type: "kwikset",
  did_complete_first_sync: true,
}

export const ExampleLinkedAccounts = [
  ExampleAugustAccount,
  ExampleSmartThingsAccount,
  ExampleSaltoAccount,
  ExampleDoorKingAccount,
  ExampleMy2NAccount,
  ExampleKwiksetAccount,
]

export const ExampleBuildings = [
  {
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "2cc697a3-2a0a-4675-9956-c4e0fff899ab",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Azure Apartments",
    timezone: "America/Los_Angeles",
    location: {
      latitude: 37.758282,
      longitude: -122.410622,
      addr1: "123 Main St",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      zip_code: "94105",
      full_address_string: "123 Main St, San Francisco, CA 94105",
      country: "US",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "e5cc39d8-855e-4922-b2ff-b7a2ced3c836",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Bay View Lofts",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "124 16th Ave, San Francisco, CA 94122",
      latitude: 37.76166,
      longitude: -122.40935,
      addr1: "124 16th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "6ed5fab9-98ab-4fe3-9b36-085ab535701d",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Birchwood Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "125 17th Ave, San Francisco, CA 94122",
      latitude: 37.763161,
      longitude: -122.415161,
      addr1: "125 17th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "4324d62f-fca1-4a4c-8ac3-e52d8f7e1fdf",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Sunset Lofts",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "126 18th Ave, San Francisco, CA 94122",
      latitude: 37.768539,
      longitude: -122.412979,
      addr1: "126 18th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "9b6f6d96-fc65-4090-b152-cc642bcad1df",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Castle Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "127 19th Ave, San Francisco, CA 94122",
      latitude: 37.746712,
      longitude: -122.422401,
      addr1: "127 19th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "85a335a9-b4d8-4a0a-bf30-7bca59245bdb",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "North Beach Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "128 20th Ave, San Francisco, CA 94122",
      latitude: 37.7509899,
      longitude: -122.4182186,
      addr1: "128 20th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "dd631142-8632-4505-86de-946a5c0ad5e4",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "350 Union St Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "129 21st Ave, San Francisco, CA 94122",
      latitude: 37.7425957,
      longitude: -122.4264613,
      addr1: "129 21st Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "e56f91f2-b0eb-49f8-aabc-2a59833633dc",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "The Park Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "130 22nd Ave, San Francisco, CA 94122",
      latitude: 37.7608915,
      longitude: -122.4466,
      addr1: "130 22nd Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "79af708a-0cb1-4680-bf0e-641a8213c702",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Union Parkside",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "131 23rd Ave, San Francisco, CA 94122",
      latitude: 37.7643981,
      longitude: -122.4383671,
      addr1: "131 23rd Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "27f48c95-9f54-4e04-b97a-93b92e28b4c7",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "1355 Euclid Ave",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "132 24th Ave, San Francisco, CA 94122",
      latitude: 37.7698246,
      longitude: -122.4660867,
      addr1: "132 24th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "f5e34cad-de6b-4d04-87e4-afeef242ce8c",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Mission Bay Courtyard",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "133 25th Ave, San Francisco, CA 94122",
      latitude: 37.7463573,
      longitude: -122.4802386,
      addr1: "133 25th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "94857520-1bea-42be-a8a5-cd75961ccf31",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Bridgeview Suites",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "134 26th Ave, San Francisco, CA 94122",
      latitude: 37.7245167,
      longitude: -122.4800002,
      addr1: "134 26th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq6Z4Zj9-j4AR4Z1Z1Z1Z1Z1Z",
    },
  },
  {
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "21bbe2e7-2653-4051-9a6f-b45bb3971536",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Infinity Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "135 27th Ave, San Francisco, CA 94122",
      latitude: 37.7802891,
      longitude: -122.4159728,
      addr1: "135 27th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq9Z1Z1KAh",
    },
  },
  {
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "3e716de0-603e-4710-9f4a-056a93b08a00",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "The Edge",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "136 28th Ave, San Francisco, CA 94122",
      latitude: 37.7898335,
      longitude: -122.4044682,
      addr1: "136 28th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq9Z4Zj1-j4AR4Z1Z1Z1Z1Z1Z",
    },
  },
  {
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "d10f03c4-b628-4895-8be2-586493bfe4f2",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Rockridge Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "137 29th Ave, San Francisco, CA 94122",
      latitude: 37.7934198,
      longitude: -122.399472,
      addr1: "137 29th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq6Z4Zj9-j4AR4Z1Z1Z1Z1Z1Z",
    },
  },
  {
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "72aee6c9-93ac-4bf5-a337-84de120d0e86",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "South End Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "138 30th Ave, San Francisco, CA 94122",
      latitude: 37.7976525,
      longitude: -122.4066167,
      addr1: "138 30th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq6Z4Zj9-j4AR4Z1Z1Z1Z1Z1Z",
    },
  },
  {
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "9748df89-1ce7-4959-90c2-b0bba3c7a3a6",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Maple Lofts",
    timezone: "America/Los_Angeles",
    active_access_pass_count: 30,
    location: {
      full_address_string: "139 31st Ave, San Francisco, CA 94122",
      latitude: 37.794636,
      longitude: -122.411491,
      addr1: "139 31st Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq6Z4Zj9-j4AR4Z1Z1Z1Z1Z1Z",
    },
  },
]

const make2nDevice = (
  building_id: string,
  name: string = "Lobby rear door",
  defaultLocked = true
) => ({
  device_id: uuid(),
  name,
  linked_account_id: "linked-account-2n",
  device_type: "2n_lock",
  created_at: "2022-12-18T04:35:20.737Z",
  properties: {
    battery_level: 0.9,
    is_powered: false,
    locked: defaultLocked,
    online: true,
  },
  building_id,
  access_pass_count: 2,
  active_access_pass_count: 1,
  issue_count: 6,
  offline_account: false,
  recent_event_count: 6,
  capabilities_supported: ["lock", "access_code"],
})

const makeNukiDevice = (
  building_id: string,
  name: string = "Front door",
  defaultLocked = true
) => ({
  device_id: uuid(),
  name,
  linked_account_id: "linked-account-nuki",
  device_type: "nuki_lock",
  created_at: "2022-12-18T04:35:20.737Z",
  properties: {
    battery_level: 0.9,
    is_powered: false,
    locked: defaultLocked,
    online: true,
  },
  building_id,
  access_pass_count: 2,
  active_access_pass_count: 1,
  issue_count: 6,
  offline_account: false,
  recent_event_count: 6,
  capabilities_supported: ["lock", "access_code"],
})

export const ExampleDevices = [
  makeNukiDevice("e1741d59-61df-418e-a458-56cc1429b242"),
  make2nDevice("e1741d59-61df-418e-a458-56cc1429b242"),
  make2nDevice("2f072a89-f29a-4fe7-a43d-3fae0f09ab31"),
  make2nDevice("28511b9f-db2a-4b53-86cf-529a02f996b0"),
  make2nDevice("421679a8-71cf-4dd5-8e31-09bbc4e1b1ec"),
  make2nDevice("b6ab1921-9ad9-4b6a-bafd-1a0b27746adf"),
  make2nDevice("d4c68b97-cf0f-43b0-a3f7-31a369155ed9"),
  make2nDevice("241a0ce1-f0cf-4027-bc11-dd979fdfea78"),
  make2nDevice("69f1ce59-daad-4775-a530-62cfe4a64c8b"),
  make2nDevice("27816c08-658c-4c86-83ee-fde8a65ac669"),
  make2nDevice("fe38801c-b4c0-4e11-8195-e6f96bdac092"),
  make2nDevice("227daf76-35d8-42d7-9068-14ef0c4a056b"),
  make2nDevice("8ad13286-17c9-48bd-a60f-ed6be3ecf057"),
  make2nDevice("c9450aa1-276f-496e-b333-dea0a6b2fee6"),
  make2nDevice("2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5"),
  make2nDevice("80605ae7-9cb0-4015-8481-daea1916de00"),
  make2nDevice("0570bd0f-00c4-4cd3-918a-15f6167ba81e"),
  make2nDevice("57051d8d-c75e-48ca-8151-f73d7277db22"),
  {
    device_id: "288872ca-4ffa-4e45-a06e-7038914f8f39",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: null,
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "c186f8a7-01fe-406c-83c5-6598abf16ef3",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: null,
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "759cbd55-3be5-4463-aa7e-90e706d9dcc3",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: null,
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "2dfaf3c6-9a52-46f8-9319-7a9035578f2d",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "c2e5f00f-9efd-4661-af5a-b7e2e6317425",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      locked: false,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "unlocked",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "79f6bf35-bca7-4c9c-99d0-0e82f188495c",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      locked: false,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "unlocked",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "a96d1a6a-cf4c-4c62-a62a-110443e819e6",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "250d9ff4-d3fe-4a64-8d53-49b2206add5e",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "8c76a1f2-1583-4535-8fc9-8f04a6f45144",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "288872ca-4ffa-4e45-a06e-7018914f8f39",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "c186f8a7-01fe-406c-83c5-6598abf16ef9",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "759cbd55-3be5-4463-aa7e-90e706d9dcc2",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "402fd3cc-4fe8-4638-9b45-7fad61d7103d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "056a79d3-1767-416a-bb92-acb8e1fda027",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "546a274f-56b1-4bef-a593-debb0cf3080f",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "d01494c7-9826-4e93-8217-70855e451f09",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "bdfeb5ee-b4e2-4f69-a3b7-0ee8d0e969b3",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "75718afb-5a3b-4bee-9bdb-7b6f522b5c95",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "ba5869c3-50c9-49a4-a58e-4ae41e8bd25a",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "785587c8-13e1-4b83-84ba-fe1528ec8cff",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "ef3b716d-4fb0-4ead-b29b-6a791098fb00",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "77f4387b-802d-48fb-a948-db8f0c33b881",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "5d576095-9581-46c4-a096-7d84e9b6247b",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "15653865-7574-4b23-a41f-434a08ed8858",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "7a493849-6b15-401f-83c2-c8e9b9f406b3",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "f8f50725-6288-403c-bc6e-62fbf885319c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "62ee31bf-6288-49a3-92d1-b26f12d44f4d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "a83fb95a-684b-4f72-a6a8-dcc751b69be3",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "c0c823e3-1b60-4a64-aef0-6b507f68d524",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "2ffc2c08-dcca-43ef-b470-a8fa05e62f1d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "53c89473-a264-43f3-a965-dc1bec8d1eb2",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "d7ebd09d-1d65-4c61-8014-cd01b70ab46b",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "45cec35b-aae0-4ff2-9d93-c9e6663dc42f",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "a4d6c940-85fb-4b3d-be5e-ddeeae56638f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "9e7d66ca-386f-46fd-a950-d3366ff6cc3b",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "77600596-d1da-43f5-812e-708276ee5059",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "355141e0-dded-4132-90dc-ffa255e27a62",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "14dbd035-db38-42b7-aa36-b6d381e36832",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "9d6c78eb-d96b-48f7-b20e-307f6d6c513d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "e55ecbba-d710-494d-b374-f8025e182629",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "27af4334-d80c-4d7e-ab7a-f6fae876c34c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "37af5d3e-f761-45b7-8029-eaa2778c7590",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "1c29ea9c-01ec-4618-8788-2939dd728997",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "05670828-70e8-4f06-befc-91940df97473",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "a6b40b93-dbe8-4ef4-8457-bf7a65ea17fe",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "2c22d141-2f00-4b84-bcf8-7d31180e6f52",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "bcd24cf7-c036-4432-919a-01e4563dc326",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "da836d6d-1d26-4865-a8e5-9701acd2eaaa",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "bee96acd-ec99-4ba8-885b-d218d36b5a7b",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "925f0520-8deb-4757-923a-36eedeb9e99f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "a1ad2f2e-cc8f-4aa0-8492-61337e9bf0ae",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "ab132fbf-a617-43a5-84e8-99908f0cf7f2",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "a09adb82-d319-4636-acc1-1d8893b4830c",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "4db91d63-73c7-442e-905f-b4bd6df81a7e",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 2,
  },
  {
    device_id: "6f5aafc1-48cc-474c-ad88-ca28e35888cf",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "b1fa1bc6-8ee5-42dc-89cb-15f4054ebed0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "e394c2c0-ef1d-4856-b491-71535ed5b75d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "5ab5bfc6-b973-4adb-8a7b-4520e8e27ccc",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "969f6404-58d7-4895-9a02-6d30965207da",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "1849c4e2-ca94-4811-9c2a-91607ff1382a",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "e93e275c-a64c-4041-a084-2d27ff29a242",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "89355cac-a34d-42c7-a4f0-01247bdba256",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "39b0aa27-384f-46c6-a25d-602034c16c24",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "049b3789-d62f-4c52-9296-a900ba6b4174",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "5d9c7599-8aa9-403c-96a5-b5f679e65a88",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "c96892a6-5fdb-4c51-a036-202c2e3995ae",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "9c4634c1-f0db-43be-b8da-6600b912d98b",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "f874b5e1-9fba-4b6d-9310-a98c8b8508cf",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "fd1edbbc-a9bd-4548-8504-ef4cad517012",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "c74fda4d-02cb-45e7-89ff-6ca7cc4af174",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "36ff22d3-a549-4b54-b949-d5e04d3dcfa6",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "50d44916-d98d-4ed9-a97a-b0ceacb14fc4",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "02960793-587c-4942-8df6-f65e5f0333f9",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 019",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 21,
    offline_account: false,
    recent_event_count: 21,
  },
  {
    device_id: "021b8d59-3f3c-48b6-95a5-fe1145f6a564",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 5,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "126a72f8-91f3-46d4-973c-4f300cd20267",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 5,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "f07d92d4-3a8b-43e8-838d-1e3cc446bb95",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 5,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 2,
  },
  {
    device_id: "592e67b4-ceb2-44b6-bf51-7274da8c5a5b",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 5,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "d0895650-344b-4f90-92db-b80292c07bcd",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 5,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "4bf5006c-6cb1-41bb-bf9c-acae79ddda8f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 4,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "a270b053-dcb1-4680-a0f7-8a007e224222",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 3,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "3da6ebc1-a85b-4746-84c7-4cb0c8667f18",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "b7fe0a5c-3bd8-4897-824a-760d61a49abc",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "7d64e870-951b-41c7-ab36-4611e18c9eb0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "f5f61988-46f5-458a-9358-b321580c3ddd",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "0229110d-bddf-4d55-9365-856c3ad48caa",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "7a938950-80a6-401f-99cf-b51ec0e4ebbd",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "07d9b176-e3e3-41f0-a4cb-6c93649bdfc2",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "848f126e-875e-46f2-a8f7-ae1a97ff8630",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "d75911e5-5985-4df0-9c69-03cd9c66db92",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "88d847e0-8a02-4db9-a8bc-e3aa1c4ed95e",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "63ccc55c-1876-4ec0-b589-fa49342717f7",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "5b0502d9-0c74-4ba3-a153-49403b831d6b",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "02e51fe2-9a13-45c5-8859-a7edf49bdcfc",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "c0e60057-a3dc-4b1e-b824-8c04f14ecbac",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "2732820f-6453-4f5d-9104-6b688a4b58fd",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "1cd7eeec-ab58-4e9b-9135-1aea8fb4070a",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "0abd616b-ecb2-4061-88d3-dcfafb70552f",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "ca4163cc-98eb-4cb4-86c6-0cb49eef5669",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "28b52aa9-e816-48fa-acae-4b38f83cae8f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "ad43cf60-994b-4074-9c6b-7732972585bf",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "d260a125-5963-4140-a348-a57755a7f2bc",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "3bedf53c-6d6c-4348-b5ba-85663c764195",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "e18ec69d-44e2-4fa8-bfcb-2210a6720fe0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.2,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "e893dae2-451f-4d07-9928-49d82dd0de91",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "e8adecec-182a-4231-9f25-1e044ccfe5f4",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "5a932c81-0af3-4cfd-ad6c-f6c3bee0539a",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "470ac0d4-05c7-4242-aa81-c8096e493f97",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 2,
  },
  {
    device_id: "44f1355e-d504-42e0-8299-84542992b1e3",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "0b2e6569-2bb9-404a-980e-cb9896567ba0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "1c22561f-55a3-4ec7-9cb9-3bd9f5a210c8",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "473f9293-07bf-44b8-8f47-5a12b239f00c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "b33934c6-67b9-4389-a8de-ea1164e1d660",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "5b1a70ab-5840-433a-9030-4b5a470c5983",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "97618635-0731-4511-93c1-ad3119e8e97c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "ef2f8317-8535-455e-9aa9-5259e6446ce8",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "d74c3102-164a-42cb-a2bc-659a68060fa0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "a18d6395-07ce-43f1-a5ae-14589b12012c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "9385851d-dc9d-4462-9a72-a1249840030c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "6a5c7e30-1550-45b3-b66f-2fbd96bdee3c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "4ccb88a8-c7ac-495f-9a29-63473a4f9796",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "527f1d88-7ddf-4c7f-94ab-fac8ec3d4ac1",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "d6cda425-a0fd-4ac4-b4f7-22270fd2d86d",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "98c2b2e0-237e-41f2-8d37-bd08b95ff016",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "f9cffd3e-f72e-40c6-9e64-e969f819f501",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "8c74a765-0b50-4247-b76b-731c95f52041",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "9ac88ab0-149f-4fcf-91da-b31602b6fd7f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "07a31679-a12f-46c4-9f23-a968db92b1b9",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "85b9a31e-80bd-4bd0-b44b-8790d49c7c80",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "d3fa96b2-6066-48c1-942e-4d46703ffe9b",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "d836356d-c6cd-46ef-94ff-82b49bb44c1d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "68cbb570-8bfe-4a20-b6ac-147159301424",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "09f4dd1b-ffda-4e07-b1b8-da3844ad1f5d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "2c90cb1b-536b-404b-a2b9-646cd75fba13",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "70c00223-7f1b-4046-8fe9-67d1eac97a61",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "f7256fbb-919f-4dc6-b394-2df1dd99153d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    access_pass_count: 15,
    active_access_pass_count: 14,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    access_pass_count: 15,
    active_access_pass_count: 15,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 2,
  },
  {
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    access_pass_count: 15,
    active_access_pass_count: 14,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "652e074b-91f1-4865-8cf1-e80cf2ad9e0b",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "e553d62a-4ab0-4d02-b438-ce91a2810b53",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "8b6c751c-bd53-42d7-8abf-6820b934032a",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "7495ff13-1209-4f37-8477-bddb08ab8eb4",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "9d49e8fb-0770-453b-bfc1-044acb4aea43",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "0a1d5a4a-8d01-4f8a-b38e-1e04d670a8d3",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "aac78e9f-9532-4eb9-bee8-d7085781f65e",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "5b48dd67-ade8-44d3-898b-fdbcf8fb98f9",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "3e3ac0ca-3422-4e39-ab1e-5067e001677f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "9fef663b-17d8-4cd9-bcb6-4087a042cddb",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "5672e41e-34be-4a2a-bdcd-4350fce4ce08",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "5b8612ea-2053-4d6f-a3e2-ddd09b824a5f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "f233d23b-4dd2-4806-9897-ad545a4cdaf1",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "19c04cd0-72da-4555-b12f-5b28bf1b4e86",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "01f97216-fe02-4bab-9ad3-5471f61c8dc2",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "aaa189fb-6774-488f-84e4-1da3b9efd7b1",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "654b3a0e-9b99-4580-98ab-64bc82fde1dd",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "edbe997b-21b7-4567-84b8-b55beae1235c",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "fc3e3e00-5cb5-477c-93b1-ceb78a9b779c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "6e27fff4-9b76-461d-96f5-bb17f59d52d0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "4e8f45a1-a1b4-4016-9e03-a740537aaa48",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "0e21dd78-7473-42cf-b9a5-264d29d07590",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "6be35199-e977-43da-9c1b-25c1a880bef3",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "242095bc-c82a-4028-8a31-9f75a03b795d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "c03a1640-9187-4760-bb1c-f623cdf0aaee",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "274d6ab3-b780-477f-9884-52bcba87165f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "0594c332-5bf8-42f9-98db-e445813276a1",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "4000aead-a6e6-4e37-b421-3456093ea203",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "0ef5b8a5-cc72-4776-b464-c79111540c77",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "0ef9c7d4-7c53-4dad-99d6-17a6ef2d7ec8",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "2c75480b-5842-43b2-9089-c2d3d2bf1f90",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "edc8adee-329c-49b4-abad-14355bdbd40b",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "6fce444d-d86d-4f59-8601-a7ee1b2608bc",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "1efb6f7b-6e3c-43e2-8446-0f1cd34a0db1",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "790de092-8303-44f3-9397-2874b3a2dfe8",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "4fae1dd1-d131-4cda-9dcf-0af6054ff1cc",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 1,
  },
  {
    device_id: "cd968a6b-b531-4caa-884b-e414ce2be398",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "ea125a83-0f8b-42de-8dca-af9f844504d7",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "c1753a8b-d22c-4fb3-98e7-6935ee4f3511",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "18c53769-4c36-4871-a062-91e36ff026e0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "e3cf8d32-e511-49bc-81d9-d17257c9689f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "ce291b4b-5183-43d5-a23c-c2c9dd49d204",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "73d80b6d-52bb-4ce4-8153-3016795afbb1",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "074237ee-5fc9-407a-84c9-560f683e906e",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "aadd6c5f-18e8-43fe-b67d-88be7c451fa1",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "24109c89-e85b-4142-80e3-14625beaa219",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "640c9489-65ea-4c9e-b085-d5a1f187d15b",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "a1be7d36-d1d5-4de3-abce-06db9bf754ee",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "340d4d9f-9664-4240-bb03-47336e5c5408",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "a69420df-4a6f-4054-af8e-47d5fcaf4c83",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "8682c06d-ab4e-4822-90ed-1b687c6caf9e",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "4e2a3d77-5f59-4b80-9613-8baf74e0d949",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "2f3ecdf6-4d61-481b-8d00-19edbe31d319",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "abf056e2-bf14-4242-84f6-a16c1108a6b8",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "74c4ffc4-23a3-400e-9277-40e27cf51917",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 019",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 21,
    offline_account: false,
    recent_event_count: 21,
  },
  {
    device_id: "4d930b36-242f-4686-8ccc-1282c49f9f36",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 020",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 22,
    offline_account: false,
    recent_event_count: 22,
  },
  {
    device_id: "040cde3c-f150-460b-b45c-db17ec1b46ee",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 021",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 23,
    offline_account: false,
    recent_event_count: 23,
  },
  {
    device_id: "bb629ade-d23c-46be-abd9-227a95a705a8",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 022",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 24,
    offline_account: false,
    recent_event_count: 24,
  },
  {
    device_id: "8cd75a85-ed1e-415f-bfa1-e58e261695cb",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 023",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 25,
    offline_account: false,
    recent_event_count: 25,
  },
  {
    device_id: "bebb0024-89bc-4b5f-b188-25b93da73dd8",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 024",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 26,
    offline_account: false,
    recent_event_count: 26,
  },
  {
    device_id: "a869379f-65b8-4d0a-a169-7f076f8324f6",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 025",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 27,
    offline_account: false,
    recent_event_count: 27,
  },
  {
    device_id: "f8bdf069-c5a9-45a9-815f-33868ced10d4",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 026",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 28,
    offline_account: false,
    recent_event_count: 28,
  },
  {
    device_id: "f0421162-e471-47dd-af03-394dfdcad2c2",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 027",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 29,
    offline_account: false,
    recent_event_count: 29,
  },
  {
    device_id: "075c2687-469f-4f91-ae62-b0eeec71ce4c",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "fd0e5cf9-741b-4279-aa15-7446588d8418",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "3d166f7b-4f52-4566-a3f8-ae0d64759643",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "2bd99808-3375-4860-b776-efc3d9f991fe",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "03e1a824-1619-431f-9159-9eef34baf7c4",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "d6ecb2de-b8cc-46d6-a380-921387806a6a",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "3c94b133-395d-4127-a649-1efe752cd2d0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "109c9712-97b6-4868-801e-ae6d53f01099",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "235afd9c-35d9-4a38-8ce6-0e713337655f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "8fbc544c-d421-4bae-9e68-47aac1db1eca",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "ea5f6060-09f6-4467-b87a-74863ec710db",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "fe359c52-acf6-4212-afd9-d394364e01d2",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "a308e4ec-4a16-43a8-9b98-b4f78d682aa0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "f485bbb3-d44b-48b9-aede-853bfc346812",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "7dc729da-f0d7-4e3b-91da-71d3250f55ca",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "7914ea4b-81bd-4129-8d91-7afabafd3312",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "09e98216-96fa-4ccc-8e33-168eec30dc75",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "8418f2e0-3b30-4db3-8814-aa1173df8e3b",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "de36ecc5-d03a-439e-acbb-e5cb6a02398f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "483098d8-e0fc-49ea-a42f-39e156229c39",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "0ed06d38-bba7-441e-8335-05fa5bb6c5d6",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "c0016ae5-549d-41f5-bd03-f3ead7b70edc",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 019",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 21,
    offline_account: false,
    recent_event_count: 21,
  },
  {
    device_id: "88f3aa22-7789-4efb-b462-6f0d9232933e",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 020",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 22,
    offline_account: false,
    recent_event_count: 22,
  },
  {
    device_id: "07376b2e-7739-4271-96f2-c15981ca8ae4",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 021",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 23,
    offline_account: false,
    recent_event_count: 23,
  },
  {
    device_id: "bf476594-6c69-4973-8abe-d8495c34f64c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 022",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 24,
    offline_account: false,
    recent_event_count: 24,
  },
  {
    device_id: "368ea69c-e488-4b96-8579-6e4e82ac3096",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 023",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 25,
    offline_account: false,
    recent_event_count: 25,
  },
  {
    device_id: "814826fb-6685-4168-878f-117f1535cc4a",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 024",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 26,
    offline_account: false,
    recent_event_count: 26,
  },
  {
    device_id: "4e456c03-e2b3-4e57-80dc-ed64ef1c5ba2",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "42fa1af4-4447-4d4d-8867-3c836704b7a1",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "13f9a58b-af92-49cd-9a4e-11d67eda4c00",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 2,
  },
  {
    device_id: "55b844b4-6530-4247-b31c-2d63e5adaf8d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "9cc0bc5a-f9ab-4d37-bc3c-52020f60d035",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "d2448623-5362-42e0-86ba-cc0db7d7bddf",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "8a6b5929-5dbd-4d8f-9822-fd0ab4081ab5",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "b7be540a-8eb8-494c-ad77-d3b69186e262",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "76696d82-96ba-4d76-8348-577783371845",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "4c1cdd40-48cf-4aaf-b31e-c57cd8145041",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "e1578a4a-9c4b-46c6-aa7c-bca733e640b2",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "6068312f-be23-47c3-b63c-1255ebae1f37",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "ce659449-af98-4d0e-9866-4b9e6f047bd6",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "4a34dac2-85c5-46d9-8a5f-ea150990bdc0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "5472a410-bbec-426e-9b3c-15653bf936ec",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "b38b285c-80cc-486b-9844-2a81357eb0ae",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "77f3f379-2a1b-49de-9de9-f46741639446",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "f4a39294-0412-40ab-8542-1369b6faa4ab",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "d755b97a-49c3-46e9-be3f-7f2a541459d3",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "3cbd3a1f-60fb-4176-b751-3ab3ec9692fd",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "f20d54b9-3736-47ad-a86c-6d44b5f53c6d",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "df9833df-b794-4f2d-a2cd-dc5c5202ac77",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "d3557d28-8fc5-4837-94a4-75c6a1fb8893",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "c96674b3-2e5c-434b-b1f0-ada0dcee8f43",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "35a92636-dc58-4fa2-8e05-bd29b89dc405",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "03d9eb01-d49b-4c41-8d11-02ac8725f7aa",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "07868bc7-0ea5-4e1d-8646-53742a0a29da",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "da956da2-9438-470d-a9e6-0d0713870a55",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "6d09717a-a56f-4e6a-a95b-3882174c2308",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "65ce138e-a757-4b74-8f7d-c3756b5f1452",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "83ee04e7-196e-4545-993b-def2e5fc109c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "7db83a9f-6afe-419f-9d09-b04ecfb62ac8",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "270884a5-9433-4451-87b1-74bc970c398f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "80f959d7-5fc0-4f46-81c5-60ea8c6d6945",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "2546d809-e763-4259-9a62-2035754aa5bd",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "a7711c02-1468-4f23-8aa1-b59970b93c3f",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "ea182511-3f9d-494e-b400-47c631e92c46",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "0896c51b-b3ff-48c3-b12a-c2936eb777fa",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "9b2567d8-61bf-4b62-9196-6de04a073cfd",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "cb619490-368b-4ea5-aa65-585fafc1527c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "5a4ad751-bb60-40d3-a7f3-c95151f547f8",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "69ddd073-8930-46e7-8f9f-cdaa8d3316b9",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 019",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 21,
    offline_account: false,
    recent_event_count: 21,
  },
  {
    device_id: "d3337a1a-efdf-4a8d-9a8f-07ce5b4875a4",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 020",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 22,
    offline_account: false,
    recent_event_count: 22,
  },
  {
    device_id: "ce97b839-44d0-4a0c-ae7f-bc10d4b0c0b5",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 021",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 23,
    offline_account: false,
    recent_event_count: 23,
  },
  {
    device_id: "08918e94-c495-450a-9bf1-7ada81a47be6",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 022",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 24,
    offline_account: false,
    recent_event_count: 24,
  },
  {
    device_id: "142c6141-589c-4c56-830b-6d8ea2d64716",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 023",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 25,
    offline_account: false,
    recent_event_count: 25,
  },
  {
    device_id: "c093e2e9-739c-4d5f-beb5-c6926abfab5d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 024",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 26,
    offline_account: false,
    recent_event_count: 26,
  },
  {
    device_id: "02e48050-d375-4018-9a76-d186468d6059",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 025",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 27,
    offline_account: false,
    recent_event_count: 27,
  },
  {
    device_id: "d3a061d0-30ec-47ef-b824-2c2507c70850",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 026",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 28,
    offline_account: false,
    recent_event_count: 28,
  },
  {
    device_id: "b0ee301c-ff46-4a27-b21b-d6d28ef22b54",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 027",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 29,
    offline_account: false,
    recent_event_count: 29,
  },
  {
    device_id: "751cc2c8-daf4-4f44-8e2a-0f0ed6e405d6",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 028",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 30,
    offline_account: false,
    recent_event_count: 30,
  },
  {
    device_id: "fc0d6b4d-ee54-4144-b171-a6c381f31dd7",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 029",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 31,
    offline_account: false,
    recent_event_count: 31,
  },
  {
    device_id: "577ee38b-1753-496c-824e-07662ded837d",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "0d0be591-e219-4797-9e16-510d67847f63",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "ba820a05-b71d-4fda-9512-0a0a4965d642",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "8edf9164-6062-4d54-bee6-ded3209f40ad",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "1bb48879-b4ad-4bc8-b097-adacd2d205c5",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "e17b7dd1-83d9-4e20-a0f8-e033c5808b2c",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "aed06ec9-790e-46e8-9c57-e4952cd06f10",
    capabilities_supported: ["lock", "access_code"],
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "20b1797a-ce4b-4fb6-b53e-8a1261e820ed",
    capabilities_supported: ["lock", "access_code"],
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "8f49b3d4-b89d-4edc-a42e-400f733d80ea",
    capabilities_supported: ["lock", "access_code"],
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "4abd9b83-43da-440b-874d-1ef6999ac1a0",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "7fc8811a-bad4-4a04-b169-6bc0d39df06d",
    capabilities_supported: ["lock", "access_code"],
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
]

export const createExampleAccessPasses = (
  count: number,
  building_id: string
) => {
  const devices = ExampleDevices.filter((d) => d.building_id === building_id)
  const passes = []
  passes.push(
    ExampleUpcomingAccessPass(
      0,
      devices[0 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleUpcomingAccessPass(
      1,
      devices[1 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleActiveAccessPass(
      2,
      devices[2 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleActiveAccessPass(
      3,
      devices[3 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleActiveAccessPass(
      4,
      devices[4 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleExpiredAccessPass(
      5,
      devices[5 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleExpiredAccessPass(
      6,
      devices[6 % devices.length].device_id,
      building_id
    )
  )
  for (let i = 7; i < count; i++) {
    passes.push(
      ExampleActiveAccessPass(
        i,
        devices[i % devices.length].device_id,
        building_id
      )
    )
  }
  return passes
}

const makeAccessCode = ({
  device_id = "056a79d3-1767-416a-bb92-acb8e1fda027",
  code = "1234",
  type = "ongoing",
  name = "Test Access Code",
  ...rest
}: // @ts-ignore
Partial<AccessCode>): AccessCode => ({
  access_code_id: uuid(),
  device_id,
  name,
  code,
  status: "set",
  created_at: time(),
  is_managed: true,
  type,
  starts_at: time(0),
  ends_at: time(7),
  ...rest,
})

export const ExampleAccessCodes: AccessCode[] = [
  makeAccessCode({
    name: "Access Code 1",
  }),
  makeAccessCode({
    name: "Access Code 2",
    type: "time_bound",
    starts_at: time(),
    ends_at: time(),
  }),
]

const makeUnmanagedAccessCode = (
  building_id = "e1741d59-61df-418e-a458-56cc1429b242",
  device_id = "2dfaf3c6-9a52-46f8-9319-7a9035578f2d",
  type = "ongoing"
) =>
  ({
    access_code_id: uuid(),
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    type,
    device_id,
    building_id,
    starts_at: "2022-12-19T05:50:18.231Z",
    ends_at: "2022-12-20T05:50:18.231Z",
    last_used_at: "2022-12-18T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 6,
    is_managed: false,
    status: "set",
  } as UnmanagedAccessCode & { building_id: string; device_id: string })

export const ExampleUnmanagedAccessCodes = [
  makeUnmanagedAccessCode(
    "e1741d59-61df-418e-a458-56cc1429b242",
    "c2e5f00f-9efd-4661-af5a-b7e2e6317425"
  ),
  makeUnmanagedAccessCode("28511b9f-db2a-4b53-86cf-529a02f996b0"),
]
