// eslint-disable-next-line no-restricted-syntax
export enum LinkedAccountType {
  // SeamRelay = "seam_relay",
  Akuvox = "akuvox",
  August = "august",
  Brivo = "brivo",
  ButterflyMX = "butterflymx",
  ControlByWeb = "controlbyweb",
  DoorKing = "doorking",
  Genie = "genie",
  IglooHome = "igloohome",
  Kwikset = "kwikset",
  Linear = "linear",
  Lockly = "lockly",
  Nuki = "nuki",
  Salto = "salto",
  Schlage = "schlage",
  Seam = "seam_relay",
  SmartThings = "smartthings",
  TTLock = "ttlock",
  My2N = "my_2n",
  Yale = "yale",
}
