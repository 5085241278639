import { cloneElement, ReactElement, useRef, useState } from "react"
import MuiPopover, {
  PopoverProps as MuiPopoverProps,
} from "@mui/material/Popover"
import { styled } from "@mui/material/styles"
import { isFunction } from "lodash"

interface AnchorProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void
}

interface ContentProps {
  onClose: () => void
}

const StyledMuiPopover = styled(MuiPopover, {
  shouldForwardProp: (prop: string) =>
    !["openOnHover", "disablePadding"].includes(prop),
})<{
  openOnHover?: boolean
  disablePadding?: boolean
}>(
  (props) => `
  & .MuiPaper-root {
    border-radius: 8px;
    padding: ${props.disablePadding ? "0" : "10px"};
    font-size: 14px;
    line-height: 134%;
    display: flex;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 2px 16px 2px rgba(15, 22, 28, 0.15);
  }
`
)

export interface TooltipProps
  extends Omit<
    MuiPopoverProps,
    "children" | "anchorEl" | "elevation" | "open"
  > {
  openOnHover?: boolean
  when?: boolean
  disablePadding?: boolean
  anchor:
    | ReactElement<AnchorProps>
    | ((showing: boolean) => ReactElement<AnchorProps>)
  content: JSX.Element | ((props: ContentProps) => JSX.Element)
  anchorOriginVertical?: "top" | "bottom"
  anchorOriginHorizontal?: "left" | "center" | "right"
}

/**
 * How much space between popover, and the anchor element.
 */
const SPACE_BETWEEN_ANCHOR_PX = 8

const Popover = ({
  anchor,
  content,
  openOnHover,
  when: canOpen = true,
  disablePadding,
  anchorOriginVertical = "top",
  anchorOriginHorizontal = "center",
  PaperProps,
  ...props
}: TooltipProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const hoveringContent = useRef(false)

  const showing = canOpen && Boolean(anchorEl)

  const anchorComponent = isFunction(anchor) ? anchor(showing) : anchor

  const callOriginal = (
    event: React.MouseEvent<HTMLElement>,
    key: keyof AnchorProps
  ) => {
    const handler = anchorComponent.props[key]
    if (handler) {
      handler(event)
    }
  }

  const anchorHandleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    if (openOnHover) {
      setAnchorEl(event.currentTarget)
    }

    callOriginal(event, "onMouseEnter")
  }

  const handleMouseLeftAnchor = () => {
    setTimeout(() => {
      if (!hoveringContent.current) {
        onClose()
      }
    }, 50)
  }

  const anchorHandleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    if (openOnHover) {
      handleMouseLeftAnchor()
    }

    callOriginal(event, "onMouseLeave")
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!openOnHover) {
      event.stopPropagation()
      const el = Boolean(anchorEl) ? null : event.currentTarget
      setAnchorEl(el)
    }

    callOriginal(event, "onClick")
  }

  const boundAnchor = cloneElement(anchorComponent, {
    onClick: handleClick,
    onMouseEnter: anchorHandleMouseEnter,
    onMouseLeave: anchorHandleMouseLeave,
  })
  const anchorHeight = anchorEl?.clientHeight || 0

  const anchorTop =
    anchorOriginVertical === "top"
      ? -SPACE_BETWEEN_ANCHOR_PX
      : anchorHeight + SPACE_BETWEEN_ANCHOR_PX

  const contentHandleMouseEnter = () => {
    hoveringContent.current = true
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const contentHandleMouseLeave = () => {
    onClose()
    hoveringContent.current = false
  }

  const contentComponent = isFunction(content) ? content({ onClose }) : content

  return (
    <>
      <StyledMuiPopover
        anchorOrigin={{
          vertical: anchorTop,
          horizontal: anchorOriginHorizontal,
        }}
        elevation={0}
        PaperProps={{
          sx: {
            pointerEvents: "auto",
          },
          onMouseEnter: contentHandleMouseEnter,
          onMouseLeave: contentHandleMouseLeave,
          ...PaperProps,
        }}
        sx={{
          pointerEvents: "none",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        anchorEl={anchorEl}
        open={showing}
        disablePadding={disablePadding}
        openOnHover={openOnHover}
        {...props}
      >
        {contentComponent}
      </StyledMuiPopover>
      {boundAnchor}
    </>
  )
}

export default Popover
