import { useEffect, useState } from "react"

import { useClipboard } from "hooks/useClipboard"

export const useClipboardTooltip = () => {
  const clipboard = useClipboard()

  const [showTooltip, setShowTooltip] = useState(false)

  const copyToClipboard = (text: string) => {
    clipboard.copy(text)
    setShowTooltip(true)
  }

  useEffect(() => {
    if (showTooltip) {
      const timeout = setTimeout(() => {
        setShowTooltip(false)
      }, 2000)

      return () => clearTimeout(timeout)
    }
  }, [showTooltip])

  return { copyToClipboard, showTooltip, setShowTooltip }
}
