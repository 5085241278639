/**
 * Get the next fake identifier in a list of fake resources
 * const { access_code_id } = getNextFakeId(req.db.access_codes, "access_code_id")
 **/
export const getNextFakeId = <K extends string>(
  arr: Array<{ [idKey in K]: string }>,
  idKey: K
): { [id in K]: string } & { count: number } => {
  const largestSuffix = arr.reduce(
    (largest: number, item: { [idKey in K]: string }) => {
      const suffix = Number(item[idKey].split("-").pop())
      return largest > suffix ? largest : suffix
    },
    0
  )
  return {
    [idKey]: `${idKey}-${largestSuffix + 1}`,
    count: largestSuffix + 1,
  } as any
}
