import { Avatar } from "@mui/material"

import { generateColorFromString, getInitials } from "lib/utils"

import { User } from "types"

interface Props {
  member: User
}

const MemberAvatar = ({ member }: Props) => {
  const name = `${member.first_name} ${member.last_name}`
  const color = generateColorFromString(name)

  return (
    <Avatar alt={name} sx={{ bgcolor: color }}>
      {getInitials(name)}
    </Avatar>
  )
}

export default MemberAvatar
