import { Badge } from "@mui/material"

interface Props {
  label: string
  badge?: number
  caption?: string
}

const CategoryHeader = ({ label, badge, caption }: Props) => {
  return (
    <div className="category-header">
      <div className="content-container">
        <p className="label">{label}</p>
        {badge && <Badge badgeContent={badge} color="secondary" />}
      </div>

      {caption && (
        <div className="content-container">
          <p className="caption">{caption}</p>
        </div>
      )}
    </div>
  )
}

export default CategoryHeader
