import { ModalProps } from "components/modals/Modal/Modal"

import OrganizationInfoModal from "./OrganizationInfoModal"

interface Props extends Pick<ModalProps, "isOpen" | "onClose"> {}

const CreateOrganizationModal = ({ ...props }: Props) => {
  return (
    <OrganizationInfoModal
      operatingData={{
        mode: "create",
      }}
      {...props}
    />
  )
}

export default CreateOrganizationModal
