import { useEffect, useState } from "react"

import { useBuilding } from "hooks/api/useBuilding"
import { useSeamQuery } from "hooks/useSeamQuery"

import { usePanels } from "providers/PanelsProvider"

import SelectLinkedAccountModal from "components/modals/AddDevicesModal/SelectLinkedAccountModal"
import LinkedAccountModal from "components/modals/LinkedAccountModal"
import { ModalProps } from "components/modals/Modal/Modal"

import { LinkedAccount } from "types"

type Props = Pick<ModalProps, "onClose" | "isOpen">

const AddDevicesModal = (props: Props) => {
  const { data: linked_accounts } = useSeamQuery(
    ["linked_accounts", "list"],
    (seam) => seam.linked_accounts.list({})
  )
  const panels = usePanels()
  const showing = panels.get("addDevicesModal")
  const [showingLinkAccountModal, setShowingLinkAccountModal] = useState(false)
  const [selectedLinkAccount, setSelectedLinkAccount] =
    useState<LinkedAccount | null>(null)

  const { building } = useBuilding()

  // Reset on showing modal. This is required because modals are global
  // components that live forever.
  useEffect(() => {
    if (showing) {
      setShowingLinkAccountModal(false)
      setSelectedLinkAccount(null)
    }
  }, [showing])

  if (!linked_accounts || !building) {
    return null
  }

  // If the user has selected a previously linked account, we'll
  // pass it onto <LinkedAccountModal/> to skip to the
  // device selection.
  if (selectedLinkAccount) {
    return (
      <LinkedAccountModal
        {...props}
        building={building}
        linkedAccount={selectedLinkAccount}
      />
    )
  }

  // If the user has any linked accounts we'll let the them choose one
  const hasLinkedAccount = linked_accounts.length > 0
  if (hasLinkedAccount && !showingLinkAccountModal) {
    return (
      <SelectLinkedAccountModal
        {...props}
        onLinkNewAccount={() => setShowingLinkAccountModal(true)}
        linkedAccounts={linked_accounts}
        onSelectLinkedAccount={setSelectedLinkAccount}
      />
    )
  }

  // Link a new account
  return <LinkedAccountModal {...props} building={building} />
}

export default AddDevicesModal
