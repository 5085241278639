import { createButton } from "./button"

import { icons } from "lib/constants/icons"

export const createCenterControl = () => {
  // (map: google.maps.Map) => {
  const container = document.createElement("div")

  container.style.display = "flex"
  container.style.flexDirection = "column"
  container.style.margin = "8px"
  container.style.backgroundColor = "white"
  container.style.boxShadow = "0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
  container.style.borderRadius = "0.5rem"
  container.style.overflow = "hidden"

  const button = createButton(icons.fullScreen)

  button.addEventListener("click", () => {
    // map.fitBounds(map.getBounds())
  })

  container.appendChild(button)

  return container
}
