import DynamicLink from "components/interactive/DynamicLink"

import { Building } from "types"

interface Props {
  numMapped: number
  building: Building
}

const NumMappedDescription = ({ numMapped, building }: Props) => {
  const description =
    numMapped > 1
      ? `All ${numMapped} devices were added to `
      : `One device was added to `

  return (
    <p className="mt-3">
      {description}
      <DynamicLink href={`/building/${building.building_id}`}>
        {building.name}
      </DynamicLink>
    </p>
  )
}

export default NumMappedDescription
