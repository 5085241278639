import { useRouter } from "next/router"

type QueryParams = Record<string, string | undefined>

export function useQueryParams() {
  const { query } = useRouter()

  const result: QueryParams = {}

  for (const [key, val] of Object.entries(query)) {
    if (!val) {
      continue
    }

    // Next.js' router accepts arrays as queries, but safe to say
    // those are not url params, so we'll ignore them here.
    if (Array.isArray(val)) {
      continue
    }

    result[key] = val
  }

  return result
}
