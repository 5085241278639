import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query"

import { useSeamClient } from "./useSeamClient"

import { SeamSpaceClient } from "lib/space-api-client/space-api-client"

export const useSeamQueryWithoutOrg = <T extends Promise<Awaited<T>>>(
  queryKey: QueryKey,
  cb: (seam: SeamSpaceClient) => T,
  options: UseQueryOptions = {}
) => {
  const seam = useSeamClient()

  return useQuery<Awaited<T>>({
    queryKey,
    queryFn: () => cb(seam),
    ...(options as any),
  })
}
