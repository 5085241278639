import useMyOrganization from "hooks/auth/useMyOrganization"
import { useSeamQuery } from "hooks/useSeamQuery"
import { useSeamQueryWithoutOrg } from "hooks/useSeamQueryWithoutOrg"

import Popover from "components/presentational/Popover"
import Content from "components/shell/Navigation/menus/OrganizationMenu/Content"

import ChevronDown from "assets/images/chevron-down.svg"
import DotRed from "assets/images/dot-red.svg"

const OrganizationMenu = () => {
  const organization = useMyOrganization()
  const { data: incidents } = useSeamQuery(["incidents", "list"], (seam) =>
    seam.incidents.list({})
  )

  const { data: organizations } = useSeamQueryWithoutOrg(
    ["organizations", "list"],
    (seam) => seam.organizations.list({})
  )

  const deviceIncidents = (incidents ?? []).filter((i) => i.device_id)
  const hasIncidents = deviceIncidents.length > 0

  if (!organization || !organizations) {
    return null
  }

  return (
    <Popover
      anchor={
        <button className="org-menu-button">
          {hasIncidents && (
            <div className="issues-icon">
              <DotRed />
            </div>
          )}
          {organization.name}{" "}
          <span className="chevron-icon">
            <ChevronDown />
          </span>
        </button>
      }
      anchorOriginVertical="bottom"
      anchorOriginHorizontal="right"
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        className: "nav-menu org-menu",
      }}
      content={({ onClose }) => <Content onClose={onClose} />}
    />
  )
}

export default OrganizationMenu
