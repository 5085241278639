import MuiInputLabel, { InputLabelProps } from "@mui/material/InputLabel"
import { styled } from "@mui/material/styles"

export interface Props extends InputLabelProps {
  altClassName?: string
}

const StyledInputLabel = styled(MuiInputLabel)(
  () => `
  font-size: 0.875rem;
  color: var(--fg);
`
)

const InputLabel = ({ altClassName, ...props }: Props) => (
  <StyledInputLabel className={altClassName ?? "mb-2"} {...props} />
)

export default InputLabel
