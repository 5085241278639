import { useEffect, useMemo, useState } from "react"
import { useRouter } from "next/router"
import { Box, Button, Typography } from "@mui/material"
import classNames from "classnames"

import { useSeamQuery } from "hooks/useSeamQuery"

import ChipSelect from "components/form/ChipSelect"
import Image from "components/media/Image"
import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import { setModalMinHeight } from "components/modals/Modal/set-min-height"
import Screen from "components/modals/StackModal/fragments/Screen"

import {
  brands,
  convertDeviceTypeToBrand,
  fetchBrandImageDetails,
} from "lib/brands"
import { colorDefaults } from "lib/mui"
import { generateMods } from "lib/utils"
import { getDeviceProductModel } from "lib/utils/devices"

import { Device } from "types"

const DeviceListScreen = () => {
  const context = useAccessPassModalContext()
  const {
    state: { device },
  } = context
  const { query } = useRouter()

  const building_id = `${
    Array.isArray(query.building_id) ? query.building_id[0] : query.building_id
  }`

  const { data: devices } = useSeamQuery(
    ["devices", "list", { building_id }],
    (seam) => seam.devices.list({ building_id }),
    { enabled: !!building_id }
  )

  // const devices = _devices
  //   ? _devices.map((d) => ({
  //       ...d,
  //       id: d.device_id,
  //     }))
  //   : []

  const [value, setValue] = useState("")
  const [selected, setSelected] = useState<Device[]>([])

  useEffect(() => {
    context.setShowBackButton(false)
  }, [])

  // If a device has already been selected, we'll skip this screen.
  useEffect(() => {
    if (device) {
      context.next()
    }
  }, [device])

  useEffect(() => {
    // If we're pre-selecting a device from the device page, we'll automatically
    // set it as the selected device here
    const targets = device
      ? [device]
      : devices?.filter((device) =>
          selected.some((d) => d.device_id === device.device_id)
        )

    context.setState({
      ...context.state,
      devices: targets?.map((device) => ({
        ...device,
        device_model: getDeviceProductModel(device),
      })),
    })
  }, [selected, devices, device])

  const select = (device: Device) => {
    setSelected([...selected, device])
    setValue("")
  }

  const deselect = (device: Device) => {
    setSelected((selected) =>
      selected.filter((i) => i.device_id !== device.device_id)
    )
  }

  const toggleSelected = (device: Device) => {
    if (selected.some((d) => d.device_id === device.device_id)) {
      deselect(device)
    } else {
      select(device)
    }
  }

  const deviceSelectionContext = {
    value,
    setValue,
    selected: selected.map((d) => ({
      ...d,
      id: d.device_id,
    })),
    select,
    deselect,
    toggleSelected,
  }

  const filteredDevices = useMemo(() => {
    if (!devices) return []

    if (value === "") {
      return devices
    }

    return devices.filter((device) => {
      return (
        device.name.toLowerCase().includes(value.toLowerCase()) ||
        device.device_type.toLowerCase().includes(value.toLowerCase())
      )
    })
  }, [devices, value])

  // Set modal min-height if content changes...
  const numDevices = filteredDevices.length
  useEffect(setModalMinHeight, [numDevices])

  return (
    <Screen>
      <p className="text text-c m-0 mb-4">
        Select the device(s) you&apos;d like to give access to.
      </p>
      <ChipSelect<
        Device & {
          id: string
        }
      >
        context={deviceSelectionContext}
        renderItemName={(device) => device.name}
      />
      <div className="device-box mt-4 scrollable-content">
        {filteredDevices.length > 0 ? (
          filteredDevices.map((device, index) => {
            const img = fetchBrandImageDetails(
              device.device_type,
              getDeviceProductModel(device)
            )

            const brand = convertDeviceTypeToBrand(device.device_type)
            const readableBrandName =
              typeof brand === "string" ? brands[brand].readableName : "Unknown"

            return (
              <div
                key={`${device}${index}`}
                className={classNames(
                  "device-row",
                  generateMods({
                    selected: selected.some(
                      (d) => d.device_id === device.device_id
                    ),
                  })
                )}
                onClick={() => {
                  toggleSelected(device)
                }}
              >
                <div className="wfull SBStack">
                  <div className="HStack">
                    <div className="image-wrap">
                      <Image src={img.src} alt={img.alt} />
                    </div>
                    <div className="VStack">
                      <p className="device-title">{device.name}</p>
                      <p className="device-model">{readableBrandName}</p>
                    </div>
                  </div>
                  <div className="CStack">
                    <Image
                      src="/icons/add-circle.svg"
                      alt="Add"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              minHeight: 340,
            }}
          >
            <Typography variant="caption" color={colorDefaults.text.textGray1}>
              No matching devices
            </Typography>
          </Box>
        )}
      </div>

      <div className="buttons">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => context.onClose()}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={context.next}
          disabled={selected.length === 0}
        >
          Next
        </Button>
      </div>
    </Screen>
  )
}

export default DeviceListScreen
