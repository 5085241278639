import { Box } from "@mui/system"

import Incident, {
  INCIDENT_TYPE_TO_DISPLAY_NAME,
} from "components/data/Incidents/Incident"

import Title from "./Title"

import { ApiResponseByClientMethod } from "types"

interface Props {
  title: string
  incidents: ApiResponseByClientMethod<"incidents.list">
}

const Incidents = ({ title, incidents }: Props) => {
  return (
    <Box sx={{}}>
      <Title>{title}</Title>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {incidents
          .filter(
            (incident) =>
              incident.incident_type in INCIDENT_TYPE_TO_DISPLAY_NAME
          )
          .map((incident) => (
            <Incident key={incident.incident_id} {...incident} />
          ))}
      </Box>
    </Box>
  )
}

export default Incidents
