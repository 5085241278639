import { useSeamQuery } from "hooks/useSeamQuery"

import { LinkedAccount } from "types"

export function useDevices(
  linkedAccount: LinkedAccount | null,
  options: {
    has_no_building?: boolean
  } = {}
) {
  const linked_account_id = linkedAccount
    ? linkedAccount.linked_account_id
    : null

  return useSeamQuery(
    ["devices", "list", { linked_account_id }, options],
    async (seam) =>
      seam.devices.list({
        ...options,
        linked_account_id: linked_account_id!,
      }),
    { enabled: Boolean(linked_account_id) }
  )
}
