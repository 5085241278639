import { PropsWithChildren } from "react"
import { Box } from "@mui/material"

import ShareBlockLabel from "components/modals/AccessPassCreateModal/fragments/share/ShareBlockLabel"

import { colorDefaults } from "lib/mui"

interface Props {
  label: string
}

const ShareBlock = ({ label, children }: PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "0.75rem 1.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "row",
        border: `1px solid ${colorDefaults.text.textGray3}`,
        borderRadius: "0.75rem",
        gap: "1.5rem",
      }}
    >
      <ShareBlockLabel>{label}</ShareBlockLabel>
      {children}
    </Box>
  )
}

export default ShareBlock
