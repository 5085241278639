/**
 * When shrinking, how many % do we want to allow the scrollable
 * div to shrink before hitting min-height.
 */
const SCROLLABLE_PERCENT = 20

/**
 * How small we want to allow the content to shrink to before
 * the clipping starts.
 */
const MIN_BODY_HEIGHT = 250

export const setModalMinHeight = () => {
  // ------------------------------------------------------------------------
  // Min height = Header + % of Body + Footer
  // ------------------------------------------------------------------------

  const containerEl = document.querySelector(
    ".modal .inner"
  ) as HTMLDivElement | null
  if (!containerEl) {
    return
  }

  // ------------------------------------------------------------------------
  // Header
  // ------------------------------------------------------------------------

  const headerEl = containerEl.querySelector(
    ".modal-header"
  ) as HTMLDivElement | null
  const headerHeight = headerEl ? headerEl.offsetHeight : 0

  // ------------------------------------------------------------------------
  // Footer / Buttons
  // ------------------------------------------------------------------------

  const actionsEl = containerEl.querySelector(
    ".modal .buttons"
  ) as HTMLDivElement | null

  const actionsHeight = actionsEl ? actionsEl.offsetHeight : 0

  // ------------------------------------------------------------------------
  // Body
  // ------------------------------------------------------------------------

  // If there's a nested scrollable, we'll reference that, if not we'll fallback
  // to .content which is also scrollable.

  const scrollableEl = containerEl.querySelector(
    ".scrollable-content"
  ) as HTMLDivElement | null

  const contentEl = containerEl.querySelector(
    ".content"
  ) as HTMLDivElement | null

  const bodyEl = scrollableEl || contentEl

  // Use scrollHeight to get actual content height
  const contentHeight = bodyEl ? bodyEl.scrollHeight : 0

  // Because .buttons is nested inside .content, we need to subtract the height
  // here, or else it would be counted twice.
  const contentHeightWithoutButtons = Boolean(scrollableEl)
    ? 0
    : contentHeight - actionsHeight

  // If the content is SMALLER than the minimum, we'll calculate it's preferred
  // height based on the amount we want to allow it to scroll by.
  const preferredBodyHeight =
    (contentHeightWithoutButtons * (100 - SCROLLABLE_PERCENT)) / 100

  // If the content LARGER than the minimum, we'll just use the minimum.
  const bodyHeight = Math.min(preferredBodyHeight, MIN_BODY_HEIGHT)

  const total = headerHeight + bodyHeight + actionsHeight
  containerEl.style.minHeight = `${total}px`
}
