import RoleMenu from "components/menus/RoleMenu"
import { RoleMenuProps } from "components/menus/RoleMenu/RoleMenu"

interface Props extends Omit<RoleMenuProps, "options"> {}

const RoleInviteSelect = (props: Props) => {
  return (
    <RoleMenu
      topContent={
        <div>
          <div className="mt-2 mb-4 mx-4">
            <p className="role-menu-description">
              Individual roles can be changed later from the members page.
            </p>
          </div>
          <div className="separator"></div>
        </div>
      }
      {...props}
    />
  )
}

export default RoleInviteSelect
