import { useState } from "react"
import { Box, Button, DialogActions } from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"

import { useSeamMutation } from "hooks/useSeamMutation"

import Loader from "components/display/Loader"
import BuildingPicker from "components/modals/LinkedAccountModal/MapDeviceStep/BuildingPicker"
import DevicePicker from "components/modals/LinkedAccountModal/MapDeviceStep/DeviceList"
import { useShowSnackbar } from "components/presentational/Snackbar"

import { fetchBrandLogoAndName } from "lib/brands"
import { LinkedAccountType } from "lib/constants"

import { Building, Device, LinkedAccount } from "types"

interface Props {
  devices: Device[] | undefined
  preSelectDevice?: Device | null
  buildings: Building[] | undefined
  building: Building | null
  onSelectBuilding: (building: Building | null) => void
  linkedAccount: LinkedAccount | null
  onCancel: () => void
  onMap: (numDevicesMapped: number) => void
}

const MapDeviceStep = ({
  devices,
  buildings,
  linkedAccount,
  onCancel,
  onMap,
  onSelectBuilding,
  preSelectDevice,
  building,
}: Props) => {
  const [selectedDevices, setSelectedDevices] = useState<string[]>(
    preSelectDevice ? [preSelectDevice.device_id] : []
  )
  const showSnackbar = useShowSnackbar()

  const canSubmit = selectedDevices.length > 0 && Boolean(building)

  const mapDevices = useMapDevices({
    deviceIds: selectedDevices,
    building_id: building?.building_id,
    enabled: canSubmit,
  })

  if (!devices || !buildings) {
    return (
      <div className="screen">
        <Box
          sx={{
            height: "32rem",
            paddingTop: "10rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Loader width={40} height={40} />
          <p>Loading devices...</p>
        </Box>
      </div>
    )
  }

  return (
    <div className="screen">
      <BuildingPicker
        value={building}
        onChange={onSelectBuilding}
        buildings={buildings}
      />
      <p className="mb-6">{getDescription({ linkedAccount, building })}</p>
      <DevicePicker
        onSelectAll={() => setSelectedDevices(devices.map((d) => d.device_id))}
        onUnselectAll={() => setSelectedDevices([])}
        value={selectedDevices}
        onChange={setSelectedDevices}
        devices={devices}
      />
      <div className="buttons">
        <Button color="secondary" variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            mapDevices(undefined)
              .then(() => {
                onMap(selectedDevices.length)
              })
              .catch((error) => {
                showSnackbar({
                  message:
                    error.message ??
                    "Something went wrong assigning devices. Please try again.",
                  variant: "error",
                })

                throw error
              })
          }}
          disabled={!canSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

function useMapDevices(params: {
  deviceIds: string[]
  building_id?: string
  enabled: boolean
}) {
  const { deviceIds, building_id, enabled } = params
  const qc = useQueryClient()

  const { mutateAsync: mapDevices } = useSeamMutation(
    async (seam) => {
      await seam.devices.update({
        device_ids: deviceIds,
        building_id: building_id!,
      })

      // Update all potentially affected queries
      qc.invalidateQueries(["linked_accounts"])
      qc.invalidateQueries(["devices"])
      qc.invalidateQueries([
        "buildings",
        "get_count_summary",
        { building_id: building_id! },
      ])
    },
    {
      enabled,
    }
  )

  return mapDevices
}

function getDescription(params: {
  linkedAccount: LinkedAccount | null
  building: Building | null
}) {
  const { linkedAccount, building } = params

  if (!linkedAccount || !building) {
    return null
  }

  const brand = fetchBrandLogoAndName(
    linkedAccount.account_type as LinkedAccountType
  )

  if (!brand) {
    return `Choose the devices you'd like to add to ${building.name}`
  }

  return `Choose the ${brand.readableName} devices you'd like to add to ${building.name}`
}

export default MapDeviceStep
