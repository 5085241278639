import { useState } from "react"
import { Box } from "@mui/material"
import pluralize from "pluralize"

import { usePanels } from "providers/PanelsProvider"

import Card from "components/cards/Card"
import LinkButton from "components/interactive/LinkButton"
import MemberGroup from "components/pages/buildings/MemberGroup"
import MemberList from "components/pages/buildings/MemberList"
import MemberProfile from "components/pages/buildings/MemberProfile"

import { User } from "types"

interface BuildingMembersProps {
  members: User[]
}

const InvitationLink = () => {
  const panels = usePanels()

  return (
    <LinkButton onClick={() => panels.open("inviteMemberModal")}>
      Invite member
    </LinkButton>
  )
}

const BuildingMembers = ({ members }: BuildingMembersProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Card
      title="Members"
      badgeContent={members.length}
      badgeProps={{
        title: `${members.length} ${pluralize("member", members.length)}`,
      }}
      action={<InvitationLink />}
    >
      <Box
        sx={{
          pt: members.length === 0 ? 0 : 2,
        }}
      >
        {members.length === 1 && <MemberProfile member={members[0]} />}

        {isExpanded && <MemberList members={members} />}

        {members.length > 1 && !isExpanded && (
          <MemberGroup members={members} onClick={() => setIsExpanded(true)} />
        )}
      </Box>
    </Card>
  )
}

export default BuildingMembers
