import { useEffect, useState } from "react"
import { AnimatePresence } from "framer-motion"

import { useDevices } from "components/modals/LinkedAccountModal/useDevices"
import { useLinkedAccount } from "components/modals/LinkedAccountModal/useLinkedAccount"
import { useSeamQuery } from "hooks/useSeamQuery"

import LinkAccountStep from "components/modals/LinkedAccountModal/LinkAccountStep"
import MapDeviceStep from "components/modals/LinkedAccountModal/MapDeviceStep/MapDeviceStep"
import MappedAllDevicesSummary from "components/modals/LinkedAccountModal/summaries/MappedAllDevicesSummary"
import MoreDevicesAvailableSummary from "components/modals/LinkedAccountModal/summaries/MoreDevicesAvailableSummary"
import UnableToMapSummary from "components/modals/LinkedAccountModal/summaries/UnableToMapSummary"
import Modal, { ModalProps } from "components/modals/Modal/Modal"
import AnimatedPage from "components/pages/accessPasses/AnimatedPage"

import { Building, Device, LinkedAccount, LoginPortal } from "types"

interface Props extends Pick<ModalProps, "isOpen" | "onClose"> {
  building?: Building
  linkedAccount?: LinkedAccount | null
  device?: Device | null
}

const LinkedAccountModal = (props: Props) => {
  const { isOpen, onClose } = props

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="linked-account-modal-new"
      headerLabel=""
    >
      <Content {...props} />
    </Modal>
  )
}

function Content({
  onClose,
  building: preSelectedBuilding,
  linkedAccount: preSelectedLinkedAccount,
  device,
}: Props) {
  const [loginPortal, setLoginPortal] = useState<LoginPortal | null>(null)
  const [finishedLinkingAccount, setFinishedLinkingAccount] = useState(
    Boolean(preSelectedLinkedAccount)
  )
  const [building, setBuilding] = useState<Building | null>(
    preSelectedBuilding ?? null
  )
  const [numDevicesTotal, setNumDevicesTotal] = useState<number | null>(null)
  const [numDevicesMapped, setNumDevicesMapped] = useState<null | number>(null)
  const [numDevicesMappedTotal, setNumDevicesMappedTotal] = useState(0)

  const newLinkedAccount = useLinkedAccount({
    login_portal_id: loginPortal?.login_portal_id,
    enabled: !preSelectedLinkedAccount,
  })

  const linkedAccount = preSelectedLinkedAccount ?? newLinkedAccount

  const unmappedDevices = useDevices(linkedAccount, { has_no_building: true })
  const buildings = useBuildings()

  const isMissingDevices =
    unmappedDevices.data?.length === 0 && numDevicesMapped === null
  const isMissingBuildings = buildings.data?.length === 0

  useEffect(() => {
    if (numDevicesTotal !== null) {
      return
    }

    if (!unmappedDevices.data) {
      return
    }

    setNumDevicesTotal(unmappedDevices.data.length)
  }, [unmappedDevices])

  const addMappedDeviceCount = (value: number) => {
    setNumDevicesMapped(value)
    setNumDevicesMappedTotal((current) => current + value)
  }

  // We're determining the screen in a function here, so we can render children dynamically in <AnimatePresence/>
  // for screen transitions. If we returned components early, <AnimatePresence/> would think it was the same
  // child, and never animate it in/out.
  const getScreen = () => {
    if (!finishedLinkingAccount) {
      return {
        name: "link-account",
      } as const
    }
    if (isMissingDevices) {
      return {
        name: "missing-devices",
      } as const
    }

    if (isMissingBuildings) {
      return {
        name: "missing-buildings",
      } as const
    }

    // Haven't mapped any devices yet...
    if (numDevicesMapped == null || building == null) {
      return {
        name: "map-devices",
      } as const
    }

    const mappedAllDevices = numDevicesMappedTotal === numDevicesTotal
    if (mappedAllDevices) {
      return {
        name: "summary-mapped-all",
        numDevicesMapped,
        building,
      } as const
    }

    return {
      name: "summary-more-available",
      numDevicesMapped,
      building,
    } as const
  }

  const screen = getScreen()

  const isMappingMoreDevices =
    numDevicesMapped === null && numDevicesMappedTotal > 0

  return (
    <AnimatePresence mode="wait" initial={false}>
      {screen.name === "link-account" && (
        <AnimatedPage key={screen.name} direction="forwards">
          <LinkAccountStep
            onCreate={setLoginPortal}
            onCancel={onClose}
            onNext={() => setFinishedLinkingAccount(true)}
          />
        </AnimatedPage>
      )}
      {screen.name === "missing-devices" && (
        <AnimatedPage key={screen.name} direction="forwards">
          <UnableToMapSummary onDone={onClose}>
            <p>No devices were found in the account.</p>
          </UnableToMapSummary>
        </AnimatedPage>
      )}

      {screen.name === "missing-buildings" && (
        <AnimatedPage key={screen.name} direction="forwards">
          <UnableToMapSummary onDone={onClose}>
            <p>No buildings to add devices.</p>
          </UnableToMapSummary>
        </AnimatedPage>
      )}

      {screen.name === "map-devices" && (
        <AnimatedPage
          key={screen.name}
          direction={isMappingMoreDevices ? "backwards" : "forwards"}
        >
          <MapDeviceStep
            preSelectDevice={device}
            devices={unmappedDevices.data}
            buildings={buildings.data}
            linkedAccount={linkedAccount}
            onMap={addMappedDeviceCount}
            onCancel={onClose}
            building={building}
            onSelectBuilding={setBuilding}
          />
        </AnimatedPage>
      )}

      {screen.name === "summary-mapped-all" && (
        <AnimatedPage key={screen.name} direction="forwards">
          <MappedAllDevicesSummary
            numMapped={screen.numDevicesMapped}
            building={screen.building}
            onDone={onClose}
          />
        </AnimatedPage>
      )}

      {screen.name === "summary-more-available" && (
        <AnimatedPage key={screen.name} direction="forwards">
          <MoreDevicesAvailableSummary
            building={screen.building}
            numDevicesMapped={screen.numDevicesMapped}
            numDevicesTotal={numDevicesTotal ?? 0}
            onDone={onClose}
            onAddMore={() => setNumDevicesMapped(null)}
          />
        </AnimatedPage>
      )}
    </AnimatePresence>
  )
}

function useBuildings() {
  return useSeamQuery(
    ["buildings", "list"],
    (seam) => seam.buildings.list({}),
    {}
  )
}

export default LinkedAccountModal
