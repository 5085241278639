import { useQueryParams } from "hooks/url/useQueryParams"
import { useSeamQuery } from "hooks/useSeamQuery"

import { ModalProps } from "components/modals/Modal/Modal"

import OrganizationInfoModal from "./OrganizationInfoModal"

interface Props extends Pick<ModalProps, "isOpen" | "onClose"> {}

const EditOrganizationModal = ({ ...props }: Props) => {
  const { organization_id } = useQueryParams()

  const { data: organization } = useSeamQuery(
    ["organizations", "get", { organization_id }],
    (seam) =>
      seam.organizations.get({
        organization_id: organization_id! as string,
      }),
    { enabled: Boolean(organization_id) }
  )

  return (
    <OrganizationInfoModal
      operatingData={{
        organization,
        mode: "edit",
      }}
      {...props}
    />
  )
}

export default EditOrganizationModal
