import { createContext, Dispatch, SetStateAction, useContext } from "react"

import { contextDefaults } from "components/modals/StackModal/defaults"
import { StackOperatingContext } from "components/modals/StackModal/types"

export interface Image {
  url: string
  alt: string
}

export interface BuildingInfoModalState {
  building_id?: string
  name: string
  location: google.maps.places.PlaceResult | undefined
  images: Image[]
  selectedImage: Image | undefined
  mode: Mode
}

export interface BuildingInfoModalData {
  state: BuildingInfoModalState
  setState: Dispatch<SetStateAction<BuildingInfoModalState>>
}

type Mode = "create" | "edit"
export interface BuildingInfoModalOperatingData {
  building?: {
    building_id: string
    name: string
    location: {
      latitude: number
      longitude: number
      full_address_string: string
      ext_google_place_id: string | null
    }
    image_url: string | null
  }
  mode: Mode
}

export const defaultState: BuildingInfoModalState = {
  building_id: undefined,
  name: "",
  location: undefined,
  images: [],
  selectedImage: undefined,
  mode: "create",
}

export const BuildingInfoModalContext = createContext<
  StackOperatingContext<BuildingInfoModalData>
>({
  ...contextDefaults,
  state: defaultState,
  setState: () => {},
})

export const useBuildingInfoModalContext = () => {
  return useContext(BuildingInfoModalContext)
}
