import { ExampleBuildings } from "./seed-data"

import { ApiResponseByClientMethod } from "types"

export const time = (plusDays?: number) => {
  let date = new Date()
  if (plusDays != undefined) {
    date.setDate(date.getDate() + plusDays)
  }
  return date.toISOString()
}

export type FakeDatabase = {
  access_passes: ApiResponseByClientMethod<"access_passes.list">
  building_roles: ApiResponseByClientMethod<"users.get_building_roles">
}

export const getSeededDb = (): FakeDatabase => {
  return {
    access_passes: [
      {
        access_pass_id: "access-pass-1",
        created_at: time(),
        type: "timebound",
        starts_at: time(),
        ends_at: time(1),
        access_methods: [
          {
            access_method: "remote_unlock",
            device_id: "device-1",
          },
          {
            access_method: "access_code",
            device_id: "device-2",
            code: "1234",
          },
        ],
        ...({
          access_pass_name: "Access Pass 1",
          last_used_at: time(),
          recently_used_count: 1,
          used_count: 1,
          device_count: 1,
          url: "https://dashboard.seam.co/access",
        } as any),
      },
      {
        access_pass_id: "access-pass-2",
        created_at: time(),
        type: "ongoing",
        access_methods: [
          {
            access_method: "remote_unlock",
            device_id: "device-1",
          },
          {
            access_method: "access_code",
            device_id: "device-2",
            code: "1234",
          },
        ],
        ...({
          access_pass_name: "Access Pass 2",
          last_used_at: time(),
          starts_at: time(),
          recently_used_count: 1,
          used_count: 1,
          device_count: 3,
          url: "https://dashboard.seam.co/access",
        } as any),
      },
    ],
    building_roles: ExampleBuildings.map((building, i) => ({
      building_id: building.building_id,
      building_name: building.name,
      building_roles: (i % 2 === 0
        ? ["building:operator"]
        : ["building:manager"]) as Array<
        "building:operator" | "building:manager"
      >,
      created_at: time(),
    })).slice(0, ExampleBuildings.length / 2),
  }
}
