import { Tooltip, Typography } from "@mui/material"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

import { colorDefaults } from "lib/mui"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

interface Props {
  time: Date | string
  timezone?: string
}

const IncidentTime = ({ time, timezone }: Props) => {
  const incidentTime = dayjs(time).format("h:mm A")
  const incidentTzTime = dayjs(time).tz(timezone).format("h:mm A z")

  return (
    <Tooltip
      enterDelay={300}
      // After first hover MUI will show the tooltip instantly if user quickly hover again. enterNextDelay adds a delay to this
      enterNextDelay={300}
      title={incidentTzTime}
      placement="top"
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: "0.875rem",
          display: "inline",
          color: "#6E7179",
          cursor: "default",
          padding: "0.2rem",
          transition: "0.1s ease-in",

          "&:hover": {
            borderRadius: "0.25rem",
            //1A is 10% opacity. Setting opacity css changes text color as well
            backgroundColor: colorDefaults.text.hyperlink + "1A",
            transitionDelay: "300ms",
          },
        }}
      >
        {incidentTime}
      </Typography>
    </Tooltip>
  )
}

export default IncidentTime
