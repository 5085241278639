import { useMutation } from "@tanstack/react-query"

import { useSeamClient } from "./useSeamClient"

import { type SeamSpaceClient } from "lib/space-api-client/space-api-client"

export const useSeamMutation = <
  Fn extends (seam: SeamSpaceClient, event: any) => Promise<any>,
  Ev extends Parameters<Fn>[1] = Parameters<Fn>[1],
  R extends Awaited<ReturnType<Fn>> = Awaited<ReturnType<Fn>>
>(
  cb: Fn,
  options?: {
    enabled?: boolean
  }
) => {
  const seam = useSeamClient()
  return useMutation<R, unknown, Ev>({
    mutationFn: (event) => cb(seam, event),
    ...options,
  })
}
