import NextLink, { LinkProps as NextLinkProps } from "next/link"
import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material"

import { Children } from "types"

interface Props extends Children, MuiLinkProps {
  href: string
  nextLinkProps?: Partial<Omit<NextLinkProps, "passHref">>
}

const InternalLink = ({
  href,
  children,
  nextLinkProps,
  sx,
  ...props
}: Props) => {
  return (
    <NextLink href={href} passHref legacyBehavior {...nextLinkProps}>
      <MuiLink component="a" sx={{ textDecoration: "none", ...sx }} {...props}>
        {children}
      </MuiLink>
    </NextLink>
  )
}

export default InternalLink
