import { createPortal } from "react-dom"
import { Box, BoxProps } from "@mui/material"

import { Children } from "types"

interface Props extends Children {
  anchorEl: Element | null
  isOpen: boolean
  onClose: () => void
  verticalOffset?: number
  horizontalOffset?: number

  containerProps?: Partial<BoxProps>
  containerSx?: BoxProps["sx"]
  menuProps?: Partial<BoxProps>
  menuSx?: BoxProps["sx"]
}

const PortalMenu = ({
  anchorEl,
  isOpen,
  onClose,
  verticalOffset = 0,
  horizontalOffset = 0,
  containerProps,
  containerSx,
  menuProps,
  menuSx,
  children,
}: Props) => {
  if (!isOpen) return null

  const getRectValue = (key: "top" | "left") => {
    if (!anchorEl) return 0
    return anchorEl.getBoundingClientRect()[key]
  }

  return createPortal(
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "fixed",
        inset: 0,
        zIndex: 999999,
        ...containerSx,
      }}
      onClick={onClose}
      {...containerProps}
    >
      <Box
        sx={{
          position: "absolute",
          top: getRectValue("top") + verticalOffset,
          left: getRectValue("left") + horizontalOffset,
          ...menuSx,
        }}
        {...menuProps}
      >
        {children}
      </Box>
    </Box>,
    document.body
  )
}

export default PortalMenu
