import { useOwnUser } from "hooks/api/useUser"

import MenuSeparator from "components/menus/MenuSeparator"
import Popover from "components/presentational/Popover"
import MenuLink from "components/shell/Navigation/menus/MenuLink"

import Trigger from "./Trigger"

import LogoutIcon from "assets/images/logout.svg"
import UserIcon from "assets/images/user.svg"

const Desktop = () => {
  const { user } = useOwnUser()

  return (
    <div className="desktop-menu-wrapper">
      <Popover
        anchor={<Trigger />}
        anchorOriginVertical="bottom"
        anchorOriginHorizontal="right"
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          className: "nav-menu account-menu",
        }}
        content={({ onClose }) => (
          <>
            <div className="user-info">
              <h5 className="name">{user.full_name}</h5>
              <span>{user.email}</span>
            </div>
            <MenuSeparator />
            <MenuLink href="/account" onClick={onClose}>
              <UserIcon />
              My Account
            </MenuLink>
            <MenuLink href="/logout" onClick={onClose}>
              <LogoutIcon />
              Sign Out
            </MenuLink>
          </>
        )}
      />
    </div>
  )
}

export default Desktop
