import {
  FocusEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { Button } from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"

import { useBuilding } from "hooks/api/useBuilding"
import { useSeamMutation } from "hooks/useSeamMutation"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import TimingBlock from "components/modals/AccessPassCreateModal/fragments/TimingBlock"
import Screen from "components/modals/StackModal/fragments/Screen"
import { useShowSnackbar } from "components/presentational/Snackbar"

import Accordion from "../fragments/Accordion"

import { generateMods } from "lib/utils"

const DetailsScreen = () => {
  const context = useAccessPassModalContext()

  const nameInputRef = useRef<HTMLInputElement>(null)
  const [showNameError, setShowNameError] = useState(false)

  const handleNameChange: FocusEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      context.setState((data) => ({
        ...data,
        name: (ev.target as HTMLInputElement).value,
      }))
    },
    []
  )

  const { building } = useBuilding()

  const queryClient = useQueryClient()
  const showSnackbar = useShowSnackbar()

  const { isLoading: isSaving, mutateAsync: saveAccessPass } = useSeamMutation(
    async (seam, event: typeof context.state) => {
      const { devices, name, start, end } = event

      if (!devices) throw new Error("Can't create access pass without devices!")
      if (!name) throw new Error("Can't create access pass without name!")
      if (!building)
        throw new Error("Can't create access pass without building!")

      const starts_at = start?.date
        ?.hour(start?.time.hour())
        ?.minute(start?.time.minute())
        ?.toISOString()

      const ends_at = end?.date
        ?.hour(end?.time.hour())
        ?.minute(end?.time.minute())
        ?.toISOString()

      const access_pass = await seam.access_passes.create({
        devices: devices.map((d) => ({
          device_id: d.device_id,
          can_use_access_code: d.can_use_access_code,
          can_use_remote_unlock: d.can_use_remote_unlock,
        })),
        starts_at,
        ends_at,
        name,
      })
      context.setState((state) => ({
        ...state,
        access_pass,
      }))
      queryClient.invalidateQueries(["access_passes"])
    },
    {}
  )

  useEffect(() => {
    context.setHeaderLabel("Access Pass Details")
    context.setShowBackButton(true)
  }, [])

  if (!building) {
    return <Screen />
  }

  return (
    <Screen>
      <div className="wfull HStack justify-c align-s">
        <p className="text text-c m-0 mr-4">Access pass name</p>
        <div className="VStack">
          <input
            ref={nameInputRef}
            name="name"
            id="name"
            type="text"
            className={classNames(
              "s-input lg",
              generateMods({
                error:
                  showNameError && (context.state.name || "").trim() === "",
              })
            )}
            placeholder="Guest: John Doe, Electrician, etc."
            defaultValue={context.state.name}
            onBlur={handleNameChange}
          />
          {showNameError && (context.state.name || "").trim() === "" && (
            <p className="caption fs--2 text-red-500 mt-1 mb-0">
              Access pass name is required.
            </p>
          )}
        </div>
      </div>

      <div className="wfull VStack">
        <div className="detail-label-wrap">
          <h3>Devices</h3>
        </div>
        <Accordion />
      </div>

      <div className="wfull VStack pb-16">
        <div className="detail-label-wrap">
          <h3>Timing</h3>
        </div>
        <TimingBlock timezone={building.timezone} />
      </div>

      <div className="buttons">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => context.onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if ((context.state.name || "").trim() === "") {
              setShowNameError(true)
              nameInputRef.current?.querySelector("input")?.focus()
              return
            }

            saveAccessPass(context.state)
              .then(() => context.next())
              .catch((err) => {
                showSnackbar({
                  message:
                    err.message ??
                    "Something went wrong creating the access pass. Please try again.",
                  variant: "error",
                })
                throw err
              })
          }}
          disabled={
            isSaving ||
            (context.state.customTimeEnabled &&
              !context.state.isCustomTimeValid)
          }
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </div>
    </Screen>
  )
}

export default DetailsScreen
