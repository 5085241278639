import { Avatar as MUIAvatar, Box } from "@mui/material"

import type { Children } from "types"

export interface Props extends Children {
  color?: string
  notify?: boolean
}

const Avatar = ({ color, notify, children, ...props }: Props) => {
  return (
    <Box>
      <MUIAvatar
        sx={{
          bgcolor: color,
        }}
        {...props}
      >
        {children}
      </MUIAvatar>
      {notify && (
        <Box
          sx={{
            position: "absolute",
            top: "2px",
            right: "2px",
            padding: 0.25,
            borderRadius: "50%",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              backgroundColor: "#E36857",
            }}
          ></Box>
        </Box>
      )}
    </Box>
  )
}

export default Avatar
