import { Box, Typography } from "@mui/material"

import StatusIndicator from "components/data/StatusIndicator"

interface Props {
  status: "online" | "offline" | "issue"
}

const StatusPill = ({ status }: Props) => {
  return (
    <Box
      sx={{
        width: "92px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        backgroundColor: "white",
        padding: "0px 8px 0px 6px",
        gap: "4px",
        boxShadow: "0px 1px 8px rgba(3, 56, 88, 0.08)",
        borderRadius: "10px",
      }}
    >
      <StatusIndicator status={status} />
      <Typography
        sx={{
          fontSize: "0.875rem",
          lineHeight: "134%",
        }}
      >
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Typography>
    </Box>
  )
}

export default StatusPill
