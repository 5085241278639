import { useRef } from "react"
import { BsSearch, BsX } from "react-icons/bs"

interface Props<T> {
  context: MultiselectContext<T>
  renderItemName: (item: T) => string
}

interface MultiselectContext<T> {
  value: string
  setValue: (value: string) => void
  selected: T[]
  select: (item: T) => void
  deselect: (item: T) => void
  toggleSelected: (item: T) => void
}

const ChipSelect = <
  T extends {
    id: string
  }
>({
  context,
  renderItemName,
}: Props<T>) => {
  const ref = useRef<HTMLInputElement>(null)

  const focus = () => {
    if (ref.current) {
      ref.current.focus()
    }
  }

  return (
    <div className="chip-select-control" tabIndex={0} onClick={focus}>
      <div className="adornment">
        <i className="icon">
          <BsSearch />
        </i>
      </div>
      <div className="chips">
        {context.selected.map((item) => (
          <div
            key={item.id}
            className="chip"
            onClick={() => context.toggleSelected(item)}
          >
            <p>{renderItemName(item)}</p>
            <button>
              <i className="icon">
                <BsX />
              </i>
            </button>
          </div>
        ))}
        <div className="chip-select">
          <input
            ref={ref}
            type="text"
            placeholder="Search devices"
            value={context.value}
            onChange={(ev) => context.setValue(ev.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default ChipSelect
