import { Box, Typography } from "@mui/material"

import { colorDefaults } from "lib/mui"

interface Props {
  icon: React.ReactNode
  label: string
}

const ShareBlockSectionLabel = ({ icon, label }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        gap: "0.5rem",
        mb: "0.25rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
      <Typography
        sx={{
          fontSize: "0.875rem",
          fontWeight: "600",
          color: colorDefaults.text.textDefaultColor,
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}

export default ShareBlockSectionLabel
