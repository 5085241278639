import Icon from "components/media/Icon"

interface Props {
  onClose: () => void
}

const CloseButton = ({ onClose }: Props) => {
  return (
    <button onClick={onClose} className="close-button">
      <Icon icon="x" />
    </button>
  )
}

export default CloseButton
