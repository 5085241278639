import Image from "next/image"

import StatusIndicator from "components/data/StatusIndicator"

import { ImageDetails } from "lib/brands"

interface Props {
  incidentType: string
  label: string
  caption: string | React.ReactNode
  imageDetails: ImageDetails
  onClick: () => void
}

const Row = ({
  incidentType,
  label,
  caption,
  imageDetails,
  onClick,
}: Props) => {
  const getLabel = () => {
    switch (incidentType) {
      case "linked_account.disconnected":
        return "Account Disconnected"
      case "device.disconnected":
        return "Device Offline"
      case "device.low_battery":
        return "Low Battery"
      default:
        return "Issue"
    }
  }

  const getIcon = (): "online" | "offline" | "issue" | "low_battery" => {
    switch (incidentType) {
      // case "account_offline":
      //   return "offline"
      case "device.disconnected":
        return "offline"
      case "device.low_battery":
        return "low_battery"
      default:
        return "issue"
    }
  }

  const issueData = {
    status: getIcon(),
    label: getLabel(),
  }

  return (
    <button onClick={onClick} className="row">
      <div className="row-container">
        <div className="row-content">
          <div className="image-container">
            <Image src={imageDetails.src} alt={imageDetails.alt} height={48} />
          </div>
          <div className="text-container">
            <h3>{label}</h3>
            <p>{caption}</p>
          </div>
        </div>

        <div className="issue-container">
          <StatusIndicator
            status={issueData.status}
            // The x icon in the StatusIndicator is BsXLg, so we need to
            // account for the comparatively smaller size of the other
            // icons in the StatusIndicator.
            iconSize={issueData.status === "offline" ? "0.875rem" : "1.25rem"}
          />
          <p>{issueData.label}</p>
        </div>
      </div>
    </button>
  )
}

export default Row
