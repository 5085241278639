import { MouseEvent, RefObject, useEffect, useRef, useState } from "react"

interface PanelContext<ElementType> {
  ref: RefObject<ElementType>
  isOpen: boolean
  toggle: (ev?: MouseEvent) => void
  open: (ev?: MouseEvent) => void
  onClose: (ev?: globalThis.MouseEvent) => void
}

const useDynamicPanel = <
  ElementType extends Element
>(): PanelContext<ElementType> => {
  const ref = useRef<ElementType>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleWindowClick = (ev: globalThis.MouseEvent) => {
    if (
      ref.current &&
      typeof ref.current?.contains === "function" &&
      !ref.current.contains(ev.target as Node)
    ) {
      onClose(ev)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined" && ref.current && isOpen) {
      window.addEventListener("click", handleWindowClick)
    }

    return () => {
      // @ts-ignore
      window.removeEventListener("click", handleWindowClick)
    }
  }, [ref.current, isOpen])

  const open = (ev?: MouseEvent) => {
    if (ev) {
      ev.stopPropagation()
    }

    setIsOpen(true)
  }

  const onClose = (ev?: globalThis.MouseEvent) => {
    if (ev) {
      ev.stopPropagation()
    }

    setIsOpen(false)
  }

  const toggle = (ev?: MouseEvent) => {
    if (ev) {
      ev.stopPropagation()
    }

    setIsOpen((isOpen) => !isOpen)
  }

  return {
    ref,
    isOpen,
    open,
    onClose,
    toggle,
  }
}

export default useDynamicPanel
