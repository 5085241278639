import { useEffect, useState } from "react"
import { Box, Checkbox, Typography } from "@mui/material"

import { colorDefaults } from "lib/mui"

import { Children } from "types"

interface Props extends Children {
  label: string
  onChange?: (value: boolean) => void
}

const OptionalBlock = ({ label, onChange, children }: Props) => {
  const [enabled, setEnabled] = useState<boolean>(true)

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(enabled)
    }
  }, [enabled])

  return (
    <Box
      sx={{
        width: "100%",
        mb: enabled ? 4 : 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
          gap: "0.5rem",
          mb: 1,
        }}
      >
        <Checkbox
          color="primary"
          size="small"
          defaultChecked
          onChange={(ev) => {
            setEnabled(ev.target.checked)
          }}
        />
        <Typography
          sx={{
            fontSize: "0.875rem",
            fontWeight: "600",
            color: colorDefaults.text.textDefaultColor,
          }}
        >
          {label}
        </Typography>
      </Box>

      {enabled && children}
    </Box>
  )
}

export default OptionalBlock
