import { useEffect } from "react"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import SuccessScreen from "components/modals/AccessPassCreateModal/fragments/SuccessScreen"
import Screen from "components/modals/StackModal/fragments/Screen"

const PassCreatedScreen = () => {
  const context = useAccessPassModalContext()

  useEffect(() => {
    context.setShowBackButton(false)
  }, [])

  return (
    <Screen>
      <SuccessScreen content="Access pass created!" />
    </Screen>
  )
}

export default PassCreatedScreen
