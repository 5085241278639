import { useRouter } from "next/router"

import { useSeamMutation } from "hooks/useSeamMutation"
import { useSeamQuery } from "hooks/useSeamQuery"

import { ModalProps } from "components/modals/Modal/Modal"
import { useShowSnackbar } from "components/presentational/Snackbar"

import ConfirmationAlert from "./Alert/ConfirmationAlert"

interface Props extends Pick<ModalProps, "isOpen" | "onClose"> {}

const DeleteBuildingModal = ({ isOpen, onClose }: Props) => {
  const router = useRouter()
  const { building_id } = router.query

  const showSnackbar = useShowSnackbar()

  const { data: building } = useSeamQuery(
    ["buildings", "get", { building_id }],
    (seam) =>
      seam.buildings.get({
        building_id: building_id! as string,
      }),
    {
      enabled: !!building_id,
    }
  )

  const { mutateAsync: deleteBuilding, isLoading } = useSeamMutation(
    (seam, building_id) => seam.buildings.delete({ building_id })
  )

  return (
    <ConfirmationAlert
      isOpen={isOpen}
      onClose={onClose}
      title="Permanently delete this building?"
      severity="danger"
      confirmText="Delete"
      content={`${building?.name} will be permanently deleted along with its access passes and past activity. You will no longer be able to see or control who has access to this building.`}
      loading={isLoading}
      onConfirm={() => {
        deleteBuilding(building_id)
          .then(() => {
            onClose()
            showSnackbar({
              message: `The ${building?.name} building was deleted`,
              variant: "success",
            })
            router.push("/")
          })
          .catch((e) => {
            showSnackbar({
              message: `Failed to delete ${building?.name}.`,
              variant: "error",
            })
            console.error(e)
          })
      }}
    />
  )
}

export default DeleteBuildingModal
