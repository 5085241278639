import Image from "next/image"
import { Tooltip } from "@mui/material"

import { useClipboardTooltip } from "hooks/useClipboardTooltip"

interface Props {
  value: string
  disabled?: boolean
}

const CopyInput = ({ value, disabled }: Props) => {
  const { copyToClipboard, showTooltip, setShowTooltip } = useClipboardTooltip()

  return (
    <>
      <Tooltip
        title="Copied!"
        open={showTooltip}
        onClose={() => setShowTooltip(false)}
        placement="top"
        // onOpen={() => setShowTooltip(true)}
      >
        <button
          className="copy-input"
          onClick={() => copyToClipboard(value)}
          disabled={disabled}
        >
          <p>{value}</p>
          <i className="icon">
            <Image src="/icons/copy.svg" alt="Copy" width={24} height={24} />
          </i>
        </button>
      </Tooltip>
    </>
  )
}

export default CopyInput
