import classNames from "classnames"

import Image from "components/media/Image"

interface Props {
  children: JSX.Element | JSX.Element[]
  className?: string
}

const Header = ({ className, children }: Props) => {
  return (
    <div className={classNames("flex-c", className)}>
      <div className="wfull flex-c mb-4">
        <Image
          alt="check"
          src={"/ui/check_green_circle.svg"}
          width={42}
          height={42}
        />
      </div>
      <>{children}</>
    </div>
  )
}

export default Header
