import { CircularProgress } from "@mui/material"

import Alert, { AlertProps } from "components/modals/Alert/Alert"

import { icons } from "lib/constants/icons"

interface Props extends Omit<AlertProps, "icon" | "actions"> {
  onConfirm?: () => void
  severity?: "info" | "danger"
  confirmText?: string
  cancelText?: string
  hideConfirm?: boolean
  loading?: boolean
}

const ConfirmationAlert = ({
  onClose,
  onConfirm,
  severity = "info",
  confirmText = "Confirm",
  cancelText = "Cancel",
  loading,
  hideConfirm,
  ...alertProps
}: Props) => {
  const showingConfirmButton = !hideConfirm

  return (
    <Alert
      icon={icons.infoCircle}
      onClose={onClose}
      actions={
        <>
          <button onClick={onClose}>{cancelText}</button>
          {showingConfirmButton && (
            <button
              onClick={onConfirm}
              disabled={loading}
              className={severity === "info" ? "primary" : "danger"}
            >
              {loading ? (
                <CircularProgress size={24} color="secondary" />
              ) : (
                confirmText
              )}
            </button>
          )}
        </>
      }
      {...alertProps}
    />
  )
}

export default ConfirmationAlert
