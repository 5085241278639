import CategoryHeader from "./CategoryHeader"
import { Incident } from "./Incident"
import LinkedAccountRow from "./LinkedAccountRow"

interface Props {
  incidents: Incident[]
}

const LinkedAccountIssues = ({ incidents }: Props) => {
  return (
    <div className="modal-category">
      <CategoryHeader label="Linked Accounts" badge={incidents.length} />

      {incidents.map((incident) => (
        <LinkedAccountRow key={incident.incident_id} incident={incident} />
      ))}
    </div>
  )
}

export default LinkedAccountIssues
