// A "profile" component for displaying a single member.
// This is used in the member card when there is only
// one member in a building.

import { useRouter } from "next/router"
import { ButtonBase, Typography } from "@mui/material"

import useUserOrgRole from "hooks/api/useUserOrgRole"

import MemberAvatar from "components/data/MemberAvatar"

import { roleLabels } from "lib/constants/roles"
import { colorDefaults } from "lib/mui"

import { User } from "types"

interface Props {
  member: User
}

const MemberProfile = ({ member }: Props) => {
  const router = useRouter()
  const { userRole: userOrgRole } = useUserOrgRole(member.user_id)

  return (
    <ButtonBase
      onClick={() => router.push(`/member/${member.user_id}`)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "row",
        gap: 1,
        cursor: "pointer",

        "&:hover .member-name": {
          textDecoration: "underline",
        },
      }}
    >
      <MemberAvatar member={member} />
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: "400",
          textAlign: "left",
          maxWidth: "10rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        className="member-name"
      >
        {member.first_name} {member.last_name}
      </Typography>
      <Typography
        sx={{
          fontSize: "0.75rem",
          fontWeight: "400",
          color: colorDefaults.text.textGray2,
        }}
      >
        {roleLabels[userOrgRole]}
      </Typography>
    </ButtonBase>
  )
}

export default MemberProfile
